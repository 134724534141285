import React, { useState } from "react";
import gql from "graphql-tag";
import {
  RescuePlacementModel,
  rescuePlacementMainQuery,
  RedirectToRescuePlacementHome,
  transferringOwnershipTitle,
  AccountInformation
} from "./rescuePlacementUtils";
import { gqlTypes } from "../../types/gql";
import { useMutation } from "react-apollo";
import PetView from "./PetView";
import GoBackLink from "./GoBackLink";

const rescueTransferPetTemporarilyMutation = gql`
  mutation rescueTransferPetTemporarily(
    $input: RescueTransferPetTemporarilyInput!
  ) {
    rescueTransferPetTemporarily(input: $input) {
      accountCreated
      newAccountTemporaryPassword
    }
  }
`;

const firstNameLastNameRegex = /([^ ]*)\s*(.*)/;

function TemporaryPlacementSuccess({
  data,
  pet,
  email
}: {
  data: gqlTypes.rescueTransferPetTemporarily;
  pet: gqlTypes.petDetails;
  email: string;
}) {
  if (!data.rescueTransferPetTemporarily) {
    return null;
  }

  return (
    <div>
      <h2>Success!</h2>
      <p>
        Permission to access {pet.name} was granted to <strong>{email}</strong>.
      </p>
      {data && data.rescueTransferPetTemporarily && (
        <AccountInformation data={data.rescueTransferPetTemporarily} />
      )}
    </div>
  );
}

function TransferView({ pet }: { pet: gqlTypes.petDetails }) {
  const [transferPetTemporarily, { loading, error, data }] = useMutation<
    gqlTypes.rescueTransferPetTemporarily,
    gqlTypes.rescueTransferPetTemporarilyVariables
  >(rescueTransferPetTemporarilyMutation, {
    refetchQueries: [{ query: rescuePlacementMainQuery }]
  });

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const onSubmit = async () => {
    if (loading) {
      return;
    }

    const nameMatch = name.match(firstNameLastNameRegex);
    const firstName = (nameMatch && nameMatch[1]) || "";
    const lastName = (nameMatch && nameMatch[2]) || null;
    try {
      await transferPetTemporarily({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            petId: pet.id
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  if (data) {
    return <TemporaryPlacementSuccess data={data} pet={pet} email={email} />;
  }

  return (
    <div className="foster-transfer-form">
      <input
        type="text"
        value={email}
        onChange={e => setEmail(e.currentTarget.value)}
        placeholder="Email"
      />
      <input
        type="text"
        value={name}
        onChange={e => setName(e.currentTarget.value)}
        placeholder="Name"
      />
      <div className="button button--primary" onClick={onSubmit}>
        Transfer to a new foster parent
      </div>
      {error && <div className="text--danger">{error.message}</div>}
    </div>
  );
}

export default function PetTemporaryPlacement({
  model,
  petId
}: {
  model: RescuePlacementModel;
  petId: string;
}) {
  const pet = model.getPetById(petId);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  return (
    <div>
      <GoBackLink />
      <PetView pet={pet} title={transferringOwnershipTitle(pet)} />
      <p>
        Enter the information for the foster where {pet.name} is being placed.
      </p>
      <TransferView pet={pet} />
    </div>
  );
}
