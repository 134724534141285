import * as qs from "query-string";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import Session from "../components/Session";
import { IAppState } from "../types";

interface ISessionQueryParams {
  returnTo?: string;
}

interface ISessionContainerProps
  extends RouteComponentProps<ISessionQueryParams> {}

const mapStateToProps = (
  state: IAppState,
  ownProps: ISessionContainerProps
) => {
  const queryParams: ISessionQueryParams = qs.parse(
    ownProps.location.search[0] === "?"
      ? ownProps.location.search.substr(1)
      : ownProps.location.search
  );

  return {
    loggedIn: state.session ? true : false,
    returnTo: queryParams.returnTo,
    applePayError: !!(
      ownProps.location.state && ownProps.location.state.applePayError
    )
  };
};

export default connect(mapStateToProps)(Session);
