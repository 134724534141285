import React, { useState } from "react";

import logo from "../../assets/images/logo.svg";
import MainNavigation from "./MainNavigation";
import ShoppingBag from "./ShoppingBag";

interface IAppBarProps {
  shoppingCartItemCount: number;
}

export default function AppBar({ shoppingCartItemCount }: IAppBarProps) {
  const [mainNavigationHidden, setMainNavigationHidden] = useState(true);

  const dismissMobileMenu = () => {
    // Hide nav
    setMainNavigationHidden(true);
  };

  const toggleMainNavigation = () => {
    setMainNavigationHidden(!mainNavigationHidden);
  };

  return (
    <header className="app-bar">
      <a href="https://tryfi.com">
        <img src={logo} className="logo" alt="Fi logo" />
      </a>
      <MainNavigation
        hidden={mainNavigationHidden}
        dismissMobileMenu={dismissMobileMenu}
      />
      <ShoppingBag count={shoppingCartItemCount} />
      {/* eslint-disable-next-line */}
      <a href="#" className="hidden-sm-up" onClick={toggleMainNavigation}>
        <span className="navbar-toggler-icon" />
      </a>
    </header>
  );
}
