import React, { useMemo } from "react";
import classNames from "classnames";

import * as events from "../../lib/analytics/events";
import * as types from "../../types";
import LineItem from "./LineItem";
import CouponLineItem from "./CouponLineItem";
import useProductSKUMap from "../../hooks/useProductSKUMap";
import { ProductType, IGiftCardProduct } from "../../types";
import GiftCardLineItem from "./GiftCardLineItem";

interface ILineItemListProps {
  lineItems: { [productID: string]: types.ILineItem };
  freeTrialSet?: Set<string>;
  showFreeTrialPeriod?: boolean;
  coupons?: types.ICouponDetails[];
  removeLineItem?(id: types.LineItemID): void;
  removeCoupons?(): void;
}

function LineItemList({
  lineItems,
  freeTrialSet,
  showFreeTrialPeriod,
  coupons,
  removeCoupons,
  removeLineItem
}: ILineItemListProps) {
  const productSkuMap = useProductSKUMap();

  const folded = useMemo(() => {
    const result = new Map<
      string,
      Array<{
        lineItem: types.ILineItem;
        product?: types.IProduct;
        variant?: types.IVariant;
      }>
    >();

    // Group the line items by product type for rendering.
    Object.entries(lineItems).forEach(([_, lineItem]) => {
      const { product, variant } = productSkuMap.get(lineItem.sku) ?? {};
      const thisOne = {
        lineItem,
        product,
        variant
      };

      const filingType = product?.type ?? "other";

      let row = result.get(filingType);
      if (row === undefined) {
        row = [thisOne];
      } else {
        row.push(thisOne);
      }
      result.set(filingType, row);
    });

    return result;
  }, [lineItems, productSkuMap]);

  const renderOrder = [
    "other",
    ProductType.Addon,
    ProductType.Subscription,
    ProductType.GiftCard
  ];

  return (
    <div className="line-item-list">
      {renderOrder.map((renderGroup, rI) => {
        const products = folded.get(renderGroup);

        // NOTE(zack): Though logically you could do products?.length here,
        // the TS compiler isn't smart enough to figure out that means products
        // is defined. Need the explicit !products check to get the rest of
        // the file to treat it as defined.
        if (!products || (products.length ?? 0) < 1) {
          return null;
        }

        const groupClasses = classNames(
          "product-group",
          "group-" + renderGroup
        );

        return (
          <div className={groupClasses} key={`render-group-${rI}`}>
            {products.map(({ lineItem, product, variant }) => {
              const doRemoveLineItem = removeLineItem
                ? () => {
                    events.productRemoved({ lineItem });
                    if (removeLineItem) {
                      removeLineItem(lineItem.id);
                    }
                  }
                : undefined;

              if (
                product?.type === ProductType.GiftCard &&
                lineItem.giftCardDelivery
              ) {
                return (
                  <GiftCardLineItem
                    key={lineItem.id}
                    id={lineItem.id}
                    product={product as IGiftCardProduct}
                    variant={variant}
                    price={lineItem.price}
                    removeLineItem={doRemoveLineItem}
                    giftCardDelivery={lineItem.giftCardDelivery}
                  />
                );
              } else {
                return (
                  <LineItem
                    key={lineItem.id}
                    id={lineItem.id}
                    product={product}
                    variant={variant}
                    description={lineItem.description}
                    details={lineItem.details}
                    price={lineItem.price}
                    removeLineItem={doRemoveLineItem}
                    isFree={freeTrialSet?.has(lineItem.id) ?? false}
                    showFreeTrialPeriod={showFreeTrialPeriod}
                  />
                );
              }
            })}
          </div>
        );
      })}
      <div className="product-group group-coupon">
        {coupons?.map((coupon, i) => (
          <CouponLineItem
            key={`coupon-${i}`}
            coupon={coupon}
            removeCoupon={removeCoupons}
          />
        ))}
      </div>
    </div>
  );
}

export default LineItemList;
