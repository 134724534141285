import React from "react";
import { useLocation } from "react-router-dom";
import { ProductBackButton } from "../../../lib/productLinks";

export default function GiftCardPageHeader() {
  const location = useLocation();
  return (
    <div className="gift-card-header full-width-breakout">
      <div className="single-column-container gift-card-header-inner">
        <ProductBackButton
          className="gift-card-header-breadcrumb"
          from={location.state?.from}
        />
        <div className="gift-card-header-main">
          <img alt="A gift box" src="gift_box_black.svg" width="42" />
          <h2>Fi eGift Cards</h2>
        </div>
      </div>
    </div>
  );
}
