import { RouteComponentProps, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkReferralCode } from "../lib/pricing";
import React, { useEffect, useState } from "react";
import { cartActions } from "../reducers/cart";
import { logInternalError } from "../lib/util";
import Loading from "./Loading";
import * as events from "../lib/analytics/events";

export default function ReferralRedirect(
  props: RouteComponentProps<{ code: string }>
) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const code = props.match.params.code;

  useEffect(() => {
    async function applyReferralCode() {
      try {
        const result = await checkReferralCode(code);
        if (result.kind === "success") {
          dispatch(cartActions.addReferral(result.value));
        }
      } catch (err) {
        logInternalError(err);
      } finally {
        setLoading(false);
      }
    }
    events.referralRedirect.loaded(code);
    applyReferralCode();
  }, [code, dispatch]);

  return loading ? (
    <div className="column">
      <Loading />
    </div>
  ) : (
    <Redirect to={`/${props.location.search}`} />
  );
}
