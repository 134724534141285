import React, { useState, useCallback } from "react";
import { generateID } from "../lib/util";

interface IErrorProps {
  error?: string | null;
  errors?: string[];
  trackError?: (message: string) => void;
  errorID?: string;
}

export function useError() {
  const [error, setErrorInternal] = useState<string | undefined>(undefined);
  const [errorID, setErrorID] = useState<string | undefined>(undefined);

  const setError = useCallback(
    (message: string) => {
      setErrorInternal(message);
      setErrorID(generateID());
    },
    [setErrorInternal, setErrorID]
  );

  const clearError = useCallback(() => setErrorInternal(undefined), [
    setErrorInternal
  ]);

  return { error, errorID, setError, clearError };
}

export default class ErrorComponent extends React.PureComponent<IErrorProps> {
  public render() {
    const message = this.buildMessage();
    return message ? <div className="text--danger">{message}</div> : null;
  }

  public componentDidMount() {
    this.maybeTrackError(this.props);
  }

  public componentWillUpdate(nextProps: IErrorProps) {
    const shouldTrackError = this.props.errorID !== nextProps.errorID;
    if (shouldTrackError) {
      this.maybeTrackError(nextProps);
    }
  }

  private buildMessage = (): JSX.Element | null => {
    if (this.props.error) {
      return <div>An error occurred: {this.props.error}</div>;
    } else if (this.props.errors && this.props.errors.length > 0) {
      const errors = this.props.errors.map((e, i) => <div key={i}>{e}</div>);
      return <>{errors}</>;
    } else {
      return null;
    }
  };

  private maybeTrackError = (newProps: IErrorProps) => {
    if (!this.props.trackError) {
      return;
    }
    let message: string | undefined;
    if (newProps.error) {
      message = newProps.error;
    } else if (newProps.errors && newProps.errors.length) {
      message = newProps.errors.join(", ");
    }
    if (message) {
      this.props.trackError(message);
    }
  };
}
