import React, { useMemo, useReducer, useCallback, useEffect } from "react";
import * as types from "../../types";
import * as events from "../../lib/analytics/events";
import { cartHasAllNonPhysicalProducts, isKiosk } from "../../lib/util";

import CheckoutContext, {
  defaultReducer as CheckoutReducer,
  setRevisitSection,
  updateCart
} from "./CheckoutContext";

import {
  sectionForState,
  CheckoutSection
} from "../../models/CheckoutSections";

import Breadcrumbs from "./components/Breadcrumbs";
import ShippingAddressSection from "./components/ShippingAddressSection";
import ShippingOptionsSection from "./components/ShippingOptionsSection";
import AccordionSection, { AccordionPeek } from "./components/AccordionSection";
import BillingSection from "./components/BillingSection";
import ConfirmSection from "./components/ConfirmSection";
import ShippingPeek from "./components/ShippingPeek";
import ShippingOptionsPeek from "./components/ShippingOptionsPeek";
import BillingPeek from "./components/BillingPeek";

import { Redirect } from "react-router-dom";
import CheckoutPaths from "../../lib/CheckoutPaths";
import { defaultCheckoutState } from "../../models/CheckoutState";

interface ICheckoutProps {
  cart: types.ICart;
  products: types.IProduct[];
  session: types.ISession | undefined;
  shippingOptions: types.IShippingOption[];
}

export default function Checkout({
  cart,
  products,
  session,
  shippingOptions
}: ICheckoutProps) {
  const [checkoutState, checkoutDispatch] = useReducer(
    CheckoutReducer,
    defaultCheckoutState(cart)
  );

  const requiresShipment = useMemo(
    () => !isKiosk() && !cartHasAllNonPhysicalProducts(cart, products),
    [cart, products]
  );

  useEffect(() => {
    checkoutDispatch(updateCart(cart));
  }, [cart, checkoutDispatch]);

  const currentSection = useMemo(
    () =>
      sectionForState(checkoutState, {
        isLoggedIn: !!session,
        requiresShipment,
        hasShippingOptions: shippingOptions.length > 0
      }),
    [checkoutState, session, requiresShipment, shippingOptions]
  );

  const showShippingOptions = requiresShipment && shippingOptions.length > 0;

  const userSelectedSection = checkoutState.userOverridingViewToSection;

  const handleRevisitSection = (section: CheckoutSection) => {
    // If the user is trying to jump past where they are, abort.
    if (section > currentSection) {
      return;
    }

    if (section === userSelectedSection) {
      checkoutDispatch(setRevisitSection(undefined));
    } else {
      checkoutDispatch(setRevisitSection(section));
    }
  };

  // Intended to be a mapping of CheckoutSection -> ref
  const refsBySection = [
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>(),
    React.createRef<HTMLDivElement>()
  ];

  // When the user proceeds to a new section, log that impression and scroll
  useEffect(() => {
    switch (currentSection) {
      case CheckoutSection.confirm:
        events.confirmation.viewed();
        return;
      case CheckoutSection.payment:
        events.payment.viewed();
        return;
      case CheckoutSection.shippingAddress:
        events.shipping.viewed();
        return;
      case CheckoutSection.shippingOptions:
        // NOTE(zack): No tracking existed for this page in v1. so...
        return;
      default:
        return;
    }
  }, [currentSection]);

  useEffect(() => {
    const ref = refsBySection[currentSection];
    if (ref.current) {
      const offset =
        currentSection === CheckoutSection.shippingAddress
          ? 0
          : ref.current.offsetTop;
      console.debug(offset);

      setTimeout(() => {
        window.scrollTo({ top: offset, behavior: "smooth" });
      }, 500);
    }
  }, [refsBySection, currentSection]);

  const isSectionExpanded = useCallback(
    (section: CheckoutSection): boolean => {
      return section === (userSelectedSection ?? currentSection);
    },
    [userSelectedSection, currentSection]
  );

  const isSectionEnabled = useCallback(
    (section: CheckoutSection): boolean => {
      if (section === CheckoutSection.shippingOptions && !showShippingOptions) {
        return false;
      }

      return section <= currentSection;
    },
    [currentSection, showShippingOptions]
  );

  if (currentSection === CheckoutSection.thankYou) {
    return (
      <Redirect
        to={{
          pathname: CheckoutPaths.ThankYou,
          state: checkoutState
        }}
      />
    );
  }

  return (
    <CheckoutContext.Provider value={{ checkoutState, checkoutDispatch }}>
      <div className="checkout-container">
        <Breadcrumbs
          location={currentSection}
          onWouldRevisit={handleRevisitSection}
          isNonPhysicalCheckout={!requiresShipment}
        />

        <div className="checkout-accordion accordion-container">
          <AccordionSection
            title={requiresShipment ? "Shipping address" : "Create account"}
            expanded={isSectionExpanded(CheckoutSection.shippingAddress)}
            disabled={!isSectionEnabled(CheckoutSection.shippingAddress)}
            onUserSelectedHeader={() => {
              handleRevisitSection(CheckoutSection.shippingAddress);
            }}
            ref={refsBySection[CheckoutSection.shippingAddress]}
          >
            <AccordionPeek>
              <ShippingPeek />
            </AccordionPeek>
            <ShippingAddressSection
              loggedIn={!!session}
              nonPhysicalCheckout={!requiresShipment}
            />
          </AccordionSection>
          <AccordionSection
            title="Payment"
            expanded={isSectionExpanded(CheckoutSection.payment)}
            disabled={!isSectionEnabled(CheckoutSection.payment)}
            onUserSelectedHeader={() => {
              handleRevisitSection(CheckoutSection.payment);
            }}
            ref={refsBySection[CheckoutSection.payment]}
          >
            <AccordionPeek>
              <BillingPeek />
            </AccordionPeek>
            <BillingSection />
          </AccordionSection>
          {showShippingOptions && (
            <AccordionSection
              title="Shipping options"
              expanded={isSectionExpanded(CheckoutSection.shippingOptions)}
              disabled={!isSectionEnabled(CheckoutSection.shippingOptions)}
              onUserSelectedHeader={() => {
                handleRevisitSection(CheckoutSection.shippingOptions);
              }}
              ref={refsBySection[CheckoutSection.shippingOptions]}
            >
              <AccordionPeek>
                <ShippingOptionsPeek shippingOptions={shippingOptions} />
              </AccordionPeek>
              <ShippingOptionsSection shippingOptions={shippingOptions} />
            </AccordionSection>
          )}
          <AccordionSection
            title="Review order"
            expanded={isSectionExpanded(CheckoutSection.confirm)}
            disabled={!isSectionEnabled(CheckoutSection.confirm)}
            onUserSelectedHeader={() => {
              handleRevisitSection(CheckoutSection.confirm);
            }}
            ref={refsBySection[CheckoutSection.confirm]}
          >
            <ConfirmSection shippingOptions={shippingOptions} />
          </AccordionSection>
        </div>
      </div>
    </CheckoutContext.Provider>
  );
}
