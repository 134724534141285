import React, { useState } from "react";
import { impersonate } from "../lib/fi-api/authentication";
import { Redirect } from "react-router";
import { useDispatch } from "react-redux";
import { configActions } from "../reducers/config";

export default function Impersonate() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const email = event.target.email.value.trim();

    try {
      await impersonate(email);
      setSuccess(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  if (success) {
    // Reload config to include exchange-only items now that we're impersonating.
    dispatch(configActions.reloadConfig());
    return <Redirect to={"/"} />;
  }

  return (
    <div className="single-column-container login-form">
      <div className="single-column">
        <div className="login-body">
          <h3>Impersonate</h3>
          {errorMessage && <div className="text--danger">{errorMessage}</div>}
          <form onSubmit={handleSubmit} className="basic-form wide">
            <input type="text" name="email" placeholder="user@example.com" />
            <input type="submit" className="button button--epic" value="Go" />
          </form>
        </div>
      </div>
    </div>
  );
}
