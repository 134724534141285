import React from "react";

import { IGiftCardDelivery } from "../../types";
import { DateTime } from "luxon";
import { nl2br } from "../../lib/util";

export default function GiftCardInformation({
  delivery
}: {
  delivery: IGiftCardDelivery;
}) {
  let deliveryDate = "Right away";
  if (delivery.deliverAt) {
    deliveryDate = DateTime.fromISO(delivery.deliverAt).toLocaleString(
      DateTime.DATETIME_FULL
    );
  }
  return (
    <div className="line-item-gift-card-information">
      <div className="gift-line">
        <strong>To:</strong> {delivery.emailAddress}
      </div>
      <div className="gift-line">
        <strong>Deliver:</strong> {deliveryDate}
      </div>
      {delivery.personalMessage && (
        <div className="gift-line message">
          <strong>Message:</strong>
          <br />“{nl2br(delivery.personalMessage, false)}”
        </div>
      )}
    </div>
  );
}
