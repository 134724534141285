import React, { useMemo } from "react";
import { getRefereeRewardItemIfApplicable } from "../../../lib/util";
import { IProduct, LineItemID, ICart } from "../../../types";
import LineItemList from "../../../components/LineItemList";
import useCartStats from "../useCartStats";
import AccessoryCTA from "./AccessoryCTA";
import GPSUpsell from "./GPSUpsell";

interface IItemsProps {
  cart: ICart;
  products: IProduct[];
  removeLineItem(id: LineItemID): void;
  clearCoupon(): void;
  refereeRewardProductId: string | undefined;
}

export default function ItemsInCart({
  cart,
  products,
  removeLineItem,
  clearCoupon,
  refereeRewardProductId
}: IItemsProps) {
  const {
    subscriptionsRequired,
    subscriptionsInCart,
    giftCardLineItems,
    physicalLineItems,
    subscriptionLineItems,
    freeTrialLineItemIds
  } = useCartStats(cart, products);

  const haveAnySubscriptions = subscriptionsInCart > 0;
  const needMoreSubscriptions = subscriptionsInCart < subscriptionsRequired;

  const { showReferralCallout, refereeRewardSet } = useMemo(() => {
    const refereeRewardLineItem = getRefereeRewardItemIfApplicable(
      cart,
      refereeRewardProductId
    );

    // Show the referral callout if we have a referral and they haven't added the item yet.
    const showReferralCallout =
      !!cart.referralDetails && !refereeRewardLineItem;
    const refereeRewardSet = new Set(
      refereeRewardLineItem ? [refereeRewardLineItem.id] : []
    );

    return { showReferralCallout, refereeRewardSet };
  }, [cart, refereeRewardProductId]);

  const coupons = cart.couponDetails ? [cart.couponDetails] : [];

  const hasPhysicalItems = useMemo(
    () => Object.values(physicalLineItems).length > 0,
    [physicalLineItems]
  );
  const hasGiftCards = useMemo(
    () => Object.values(giftCardLineItems).length > 0,
    [giftCardLineItems]
  );

  return (
    <div className="wide">
      {hasGiftCards && (
        <div className="celled-section">
          <h3>Gift Cards</h3>
          <LineItemList
            lineItems={giftCardLineItems}
            removeLineItem={removeLineItem}
            coupons={hasPhysicalItems ? [] : coupons}
            removeCoupons={() => clearCoupon()}
          />
        </div>
      )}
      {hasPhysicalItems && (
        <div className="celled-section">
          <h3>Collars</h3>
          <LineItemList
            lineItems={physicalLineItems}
            freeTrialSet={refereeRewardSet}
            removeLineItem={removeLineItem}
            coupons={coupons}
            removeCoupons={() => clearCoupon()}
          />
          <AccessoryCTA
            refereeCalloutProductID={
              showReferralCallout ? refereeRewardProductId : undefined
            }
          />
        </div>
      )}
      {(haveAnySubscriptions || needMoreSubscriptions) && (
        <div className="celled-section">
          <h3>GPS Plans</h3>
          <div className="subscription-items">
            {haveAnySubscriptions && (
              <LineItemList
                lineItems={subscriptionLineItems}
                removeLineItem={removeLineItem}
                freeTrialSet={freeTrialLineItemIds}
                showFreeTrialPeriod={true}
              />
            )}
            {needMoreSubscriptions && (
              <GPSUpsell hasSubscription={haveAnySubscriptions} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
