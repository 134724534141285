import React from "react";
import { Redirect } from "react-router-dom";

import * as events from "../../lib/analytics/events";
import { IProduct, LineItemID, ICart } from "../../types";
import CartSummary from "./components/CartSummary";
import ItemsInCart from "./components/ItemsInCart";
import PromoCode from "../../components/PromoCode";
import OtherFeatures from "./components/OtherFeatures";
import useTracking from "./useTracking";
import useCartStats from "./useCartStats";
import GiftCardCTA from "./components/GiftCardCTA";

interface ICartProps {
  cart: ICart;
  products: IProduct[];
  showCoupons: boolean;
  removeLineItem(id: LineItemID): void;
  resetCart(): void;
  clearCoupon(): void;
  showShippingOptions: boolean;
  refereeRewardProductId: string | undefined;
}

export default function Cart(props: ICartProps) {
  const { cart, products } = props;

  useTracking(cart);

  const {
    applyingGiftCard,
    haveAnyGiftCardsInCart,
    onlyGiftCardsInCart
  } = useCartStats(cart, products);

  // Show "buy as gift card" upsell if you aren't using a gift card and
  // you haven't already added a gift card to your cart.
  const showGiftCardUpsell = !applyingGiftCard && !haveAnyGiftCardsInCart;

  if (Object.keys(cart.lineItems).length <= 0) {
    return <Redirect to="/" />;
  }

  return (
    <div className="split-container cart-container">
      <div className="items-in-cart">
        <ItemsInCart {...props} />
        {showGiftCardUpsell && <GiftCardCTA />}
      </div>
      <div className="cart-actions">
        <CartSummary
          total={cart.summary.total}
          giftCardDetails={cart.giftCardDetails}
          showMonthlyOption={false}
          /* TODO(zack): logic for showing paypal option should be:
          {
            !applyingGiftCard &&
            !haveAnyGiftCardsInCart &&
            parseFloat(cart.summary.total) >= 100
          }*/
          onCheckOut={() => {
            events.cartPage.checkout(cart.lineItems);
          }}
        />
        {!onlyGiftCardsInCart && <OtherFeatures />}
        <PromoCode
          haveAnyGiftCardsInCart={haveAnyGiftCardsInCart}
          showCoupons={props.showCoupons}
        />
      </div>
    </div>
  );
}
