import * as qs from "query-string";
import * as React from "react";

import { connect } from "react-redux";
import { getProductsBySku } from "../../lib/util";
import * as types from "../../types";
import { coalesceAddons } from "../../lib/pricing";

/* eslint-disable jsx-a11y/iframe-has-title */

interface ICJEventIFrameProps {
  cjevent?: string;
  orderedCart: types.ICart;
  orderId: string;
  products: types.IProduct[];
}

class CJEventIFrame extends React.Component<ICJEventIFrameProps> {
  public static containerParams = {
    containerTagId: "33287",
    TYPE: "414548",
    CID: "1555901"
  };

  public render() {
    return (
      <iframe
        height="1"
        width="1"
        frameBorder="0"
        scrolling="no"
        src={this.iframeUrl}
        name="cj_conversion"
        style={{ display: "none" }}
      />
    );
  }

  private getItemParams() {
    let itemParams = {};
    const lineItems = coalesceAddons(
      Object.values(this.props.orderedCart.lineItems),
      false
    );
    const productsBySku = getProductsBySku(this.props.products);
    let index = 0;
    while (lineItems.length > 0) {
      const item = lineItems.pop()!;
      const product = productsBySku.get(item.sku);
      if (product && !product.freeTrial) {
        index++;
        itemParams = {
          ...itemParams,
          [`ITEM${index}`]: item.sku,
          [`AMT${index}`]: item.price,
          [`QTY${index}`]: item.quantity
        };
      }
    }
    return itemParams;
  }

  private get iframeUrl() {
    const discountParams: any = {};
    const discountFloat = parseFloat(this.props.orderedCart.summary.discount);
    if (!isNaN(discountFloat) && discountFloat > 0) {
      discountParams.DISCOUNT = this.props.orderedCart.summary.discount;
    }
    const couponDetails = this.props.orderedCart.couponDetails;
    if (couponDetails) {
      discountParams.COUPON = couponDetails.code;
    }
    return `https://www.emjcd.com/tags/c?${qs.stringify({
      ...CJEventIFrame.containerParams,
      ...this.getItemParams(),
      CURRENCY: "USD",
      OID: this.props.orderId,
      CJEVENT: this.props.cjevent || undefined,
      ...discountParams
    })}`;
  }
}

const mapStateToProps = (state: types.IAppState) => ({
  cjevent: state.affiliateData.cjevent,
  products: state.config.products
});

export default connect(mapStateToProps)(CJEventIFrame);
