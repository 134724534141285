import * as React from "react";

import loadingImage from "../assets/images/jumpy_scribble.gif";

interface ILoadingState {
  display: boolean;
}

export default class Loading extends React.PureComponent<{}, ILoadingState> {
  private timer: NodeJS.Timeout | undefined;

  constructor(props: {}) {
    super(props);
    this.state = { display: false };
  }

  public componentDidMount() {
    this.timer = setTimeout(this.displayMessage, 50);
  }

  public componentWillUnmount() {
    clearTimeout(this.timer!);
  }

  public render() {
    if (!this.state.display) {
      return null;
    }

    return (
      <div className="loading">
        <p>Loading ...</p>
        <img
          src={loadingImage}
          alt="Loading Spinner"
          className="loading__image"
        />
      </div>
    );
  }

  private displayMessage = (): void => {
    this.setState({ display: true });
  };
}
