import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext
} from "react";
import classNames from "classnames";
import validator from "validator";

import * as events from "../../../../lib/analytics/events";
import ErrorMessages from "../../../../lib/errors";
import { signup } from "../../../../lib/fi-api/authentication";
import { generateID, validateEmail } from "../../../../lib/util";
import { IAddress } from "../../../../types";
import * as gqlTypes from "../../../../types/gql-op-types";
import ErrorComponent from "../../../../components/ErrorComponent";
import { AccountDetails, AddressDetails } from "./ShippingFormFields";
import { MutationFn } from "react-apollo-hooks";
import CheckoutContext from "../../CheckoutContext";
import { CheckoutSection } from "../../../../models/CheckoutSections";

interface IShippingAddressFormProps {
  loggedIn: boolean;
  loggedInUserName?: string;
  mutation: MutationFn<
    gqlTypes.updateShippingAddress,
    gqlTypes.updateShippingAddressVariables
  >;
  address: IAddress | null;
  onComplete(name: string | undefined, address: IAddress | undefined): void;
  nonPhysicalCheckout: boolean;
}

interface IFormFields {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface IShippingAddressFormState extends IFormFields {
  errors: string[];
  errorID?: string;
  loading: boolean;
}

export default function ShippingAddressForm({
  address: initialAddress,
  loggedIn,
  loggedInUserName,
  mutation,
  onComplete,
  nonPhysicalCheckout
}: IShippingAddressFormProps) {
  const { checkoutState } = useContext(CheckoutContext);
  const [errors, setErrors] = useState<string[]>([]);
  const [errorID, setErrorID] = useState<string | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);

  const initialFormValues = useMemo(
    () => ({
      line1: initialAddress?.line1 ?? "",
      line2: initialAddress?.line2 ?? "",
      city: initialAddress?.city ?? "",
      state: initialAddress?.state ?? "",
      zip: initialAddress?.zip ?? "",
      phone: initialAddress?.phone ?? "",
      country: initialAddress?.country ?? "US",

      email: "",
      password: "",
      firstName: "",
      lastName: ""
    }),
    [initialAddress]
  );

  const [formValues, setFormValues] = useState<IFormFields>(
    () => initialFormValues
  );

  // Reset form to empty on logout
  useEffect(() => {
    if (!loggedIn) {
      setFormValues(initialFormValues);
    }
  }, [loggedIn, initialFormValues]);

  // Reset form to initial address when the user reopens the section.
  useEffect(() => {
    if (
      checkoutState.userOverridingViewToSection ===
      CheckoutSection.shippingAddress
    ) {
      setFormValues(initialFormValues);
    }
  }, [checkoutState.userOverridingViewToSection, initialFormValues]);

  const requestAccountDetails = !loggedIn;
  const requestAddressDetails = !nonPhysicalCheckout;

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      overrideFieldName?: string
    ) => {
      const delta: Pick<IShippingAddressFormState, any> = {
        [overrideFieldName || event.target.name]: event.target.value
      };

      setFormValues({ ...formValues, ...delta });
    },
    [formValues]
  );

  const performChange = useCallback(
    (fieldName: string, value: string) => {
      setFormValues({
        ...formValues,
        [fieldName]: value
      });
    },
    [formValues]
  );

  const handleErrors = useCallback(
    (errors: string[]) => {
      if (errors.length > 0) {
        setErrors(errors);
        setErrorID(generateID());
        setIsLoading(false);
      }
    },
    [setErrorID, setErrors, setIsLoading]
  );

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (loading) {
        return;
      }

      setIsLoading(true);

      const errors = [];

      let email: string | undefined = undefined;
      let password: string | undefined = undefined;
      let firstName: string | undefined = undefined;
      let lastName: string | undefined = undefined;

      // Validate Account Details
      if (requestAccountDetails) {
        email = formValues.email.trim();
        password = formValues.password.trim();
        firstName = formValues.firstName.trim();
        lastName = formValues.lastName.trim();

        if (lastName!.length < 1) {
          errors.unshift("Please enter your last name.");
        }
        if (firstName!.length < 1) {
          errors.unshift("Please enter your first name.");
        }
        if (password!.length < 1) {
          errors.unshift("Please enter a password.");
        }
        if (!validateEmail(email!)) {
          errors.unshift("Please enter a valid email address.");
        }
      }

      let addressInput: gqlTypes.AddressInput | undefined;

      if (requestAddressDetails) {
        const phone = formValues.phone.trim();
        const line1 = formValues.line1.trim();
        const line2 = formValues.line2.trim();
        const city = formValues.city.trim();
        const state = formValues.state;
        const zip = formValues.zip.trim();

        if (!validator.isMobilePhone(phone, "any")) {
          errors.push("Please enter a valid phone number.");
        }
        if (line1.length < 3) {
          errors.push("Please enter a valid address.");
        }
        if (city.length < 2) {
          errors.push("Please enter a valid city.");
        }
        if (zip.length !== 5) {
          errors.push("Please enter your 5 digit zip code.");
        }
        if (state.length === 0) {
          errors.push("Please select a state.");
        }

        addressInput = {
          line1,
          line2,
          city,
          state,
          zip,
          country: "US",
          phone
        };
      }

      if (errors.length > 0) {
        handleErrors(errors);
        return;
      }

      // Validate Account Details
      if (requestAccountDetails) {
        try {
          await signup(email!, password!, firstName!, lastName!);
        } catch (error) {
          handleErrors([error.message]);
          return;
        }
      }

      try {
        if (addressInput) {
          await mutation({ variables: { address: addressInput } });
        }
        const customerName =
          loggedInUserName ?? [firstName ?? "", lastName ?? ""].join(" ");

        onComplete(customerName, addressInput);
      } catch (error) {
        handleErrors([ErrorMessages.INPUT_ERROR]);
      }
    },
    [
      loading,
      setIsLoading,
      requestAccountDetails,
      requestAddressDetails,
      handleErrors,
      mutation,
      onComplete,
      loggedInUserName,
      formValues
    ]
  );

  return (
    <div>
      <ErrorComponent
        errors={errors}
        trackError={message => events.shipping.continueError(message)}
        errorID={errorID}
      />
      <form className="basic-form" onSubmit={handleSubmit} autoComplete="on">
        {requestAccountDetails && (
          <AccountDetails
            onChange={handleChange}
            performChange={performChange}
            {...formValues}
          />
        )}
        {requestAddressDetails && (
          <AddressDetails
            onChange={handleChange}
            performChange={performChange}
            {...formValues}
          />
        )}
        <div className="action-container">
          <input
            className={classNames("flow-button--yellow", {
              "flow-button--disabled": loading
            })}
            type="submit"
            value="Next"
          />
        </div>
      </form>
    </div>
  );
}
