import React, { useState } from "react";

import * as events from "../lib/analytics/events";
import ErrorMessages from "../lib/errors";
import * as recurly from "../lib/RecurlyProvider";
import {
  generateID,
  logInternalError,
  getCustomerMessageFromApolloError,
  UserFacingError
} from "../lib/util";
import { IAddress, IBillingInfo } from "../types";
import * as gqlTypes from "../types/gql-op-types";
import ErrorComponent from "./ErrorComponent";
import StateSelector from "./StateSelector";

export type BillingInfoMutation = (options: {
  input: gqlTypes.BillingAccountInput;
  firstName: string;
  lastName: string | null;
}) => Promise<gqlTypes.updateBillingInfo["updateBillingAccount"]>;

interface IEditBillingInfoProps {
  shippingAddress?: IAddress;
  mutation: BillingInfoMutation;
  onComplete(billingInfo: IBillingInfo): void;
  actionText?: string;
}

interface IEditBillingInfoState {
  useShippingAddress: boolean;
  errors: string[];
  errorID?: string;
  submitting: boolean;
}

function StateSelectorWithRecurlyInput() {
  const [state, setState] = useState("");
  return (
    <>
      <StateSelector state={state} onChange={newState => setState(newState)} />
      <input type="hidden" data-recurly="state" value={state} />
    </>
  );
}

export default class EditBillingInfo extends React.Component<
  IEditBillingInfoProps,
  IEditBillingInfoState
> {
  private recurly: recurly.IRecurly;
  private formRef: React.RefObject<HTMLFormElement>;

  constructor(props: IEditBillingInfoProps) {
    super(props);
    this.state = {
      useShippingAddress: true,
      errors: [],
      submitting: false
    };
    this.recurly = new recurly.Provider().value;
    this.formRef = React.createRef();
  }

  public render() {
    const { shippingAddress } = this.props;
    const address =
      this.state.useShippingAddress && shippingAddress ? (
        <div key="shipping-address">
          <input
            type="hidden"
            data-recurly="address1"
            defaultValue={shippingAddress.line1}
          />
          <input
            type="hidden"
            data-recurly="address2"
            defaultValue={shippingAddress.line2 || ""}
          />
          <input
            type="hidden"
            data-recurly="city"
            defaultValue={shippingAddress.city}
          />
          <input
            type="hidden"
            data-recurly="state"
            defaultValue={shippingAddress.state}
          />
          <input
            type="hidden"
            data-recurly="postal_code"
            defaultValue={shippingAddress.zip}
          />
          <input type="hidden" value="US" data-recurly="country" />
        </div>
      ) : (
        <div key="new-billing-address" className="column">
          <input
            type="text"
            name="address1"
            data-recurly="address1"
            placeholder="Address"
            autoComplete="street-address"
          />
          <input
            type="text"
            name="address2"
            data-recurly="address2"
            placeholder="Apt, Unit"
            autoComplete="address-line2"
          />
          <div className="form-row">
            <input
              type="text"
              data-recurly="city"
              name="city"
              placeholder="City"
              autoComplete="address-level2"
            />
            <input
              type="text"
              name="zip"
              data-recurly="postal_code"
              placeholder="Zip Code"
              autoComplete="postal-code"
              className="zip-code"
            />
            <StateSelectorWithRecurlyInput />
          </div>
          <input type="hidden" value="US" data-recurly="country" />
        </div>
      );

    return (
      <>
        <form
          className="basic-form wide"
          onSubmit={this.handleAddPaymentDetails}
          ref={this.formRef}
        >
          <ErrorComponent
            errors={this.state.errors}
            errorID={this.state.errorID}
            trackError={message => events.payment.continueError(message)}
          />
          <input type="hidden" name="recurly-token" data-recurly="token" />
          <div className="form-row">
            <input
              type="text"
              data-recurly="first_name"
              placeholder="First name"
              autoComplete="cc-given-name"
            />
            <input
              type="text"
              data-recurly="last_name"
              placeholder="Last name"
              autoComplete="cc-family-name"
            />
          </div>
          <div data-recurly="card" />
          {shippingAddress && (
            <div className="form-row form-row--checkbox text--highlight">
              <input
                id="use_shipping_address"
                type="checkbox"
                checked={this.state.useShippingAddress}
                onChange={this.handleBillingAddressToggle}
              />
              <label htmlFor="use_shipping_address">
                Billing address is same as shipping
              </label>
            </div>
          )}
          {address}
          <div className="action-container">
            <button className="flow-button--yellow">
              {this.props.actionText || "Continue"}
            </button>
          </div>
        </form>
      </>
    );
  }

  public componentDidMount() {
    this.recurly.configure({});
  }

  private handleBillingAddressToggle: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    this.setState({ useShippingAddress: event.target.checked });
  };

  private handleAddPaymentDetails: React.FormEventHandler<
    HTMLFormElement
  > = event => {
    event.preventDefault();
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });
    this.recurly.token(
      event.target,
      async (
        err: recurly.IError | undefined,
        token: recurly.IToken | undefined
      ) => {
        if (err) {
          logInternalError(JSON.stringify(err)); // err is a rich object with validation details.
          let message = err.message;
          if (
            err.fields.includes("first_name") ||
            err.fields.includes("last_name")
          ) {
            message = `Please enter a valid first and last name.`;
          }
          this.setState({
            errors: [message],
            errorID: generateID(),
            submitting: false
          });
        } else {
          this.setState({ errors: [], errorID: generateID() });

          try {
            let firstName = "";
            let lastName: string | null = null;
            if (this.formRef.current) {
              const firstNameElement = this.formRef.current.querySelector(
                `[data-recurly="first_name"]`
              );
              firstName =
                (firstNameElement &&
                  (firstNameElement as HTMLInputElement).value) ||
                "";
              const lastNameElement = this.formRef.current.querySelector(
                `[data-recurly="last_name"]`
              );
              lastName =
                (lastNameElement &&
                  (lastNameElement as HTMLInputElement).value) ||
                null;
            }
            const result = await this.props.mutation({
              input: { billingInfo: { token: token!.id } },
              firstName,
              lastName
            });
            this.setState({ submitting: false });
            this.props.onComplete(result.billingInfo!);
          } catch (err) {
            const message =
              err instanceof UserFacingError
                ? err.message
                : getCustomerMessageFromApolloError(err) ||
                  ErrorMessages.INPUT_ERROR;
            this.setState({
              submitting: false,
              errors: [message],
              errorID: generateID()
            });
          }
        }
      }
    );
  };
}
