import { ILineItem, IProduct, IAppState } from "../types";
import { getProductsBySku } from "../lib/util";

export default class RichLineItem implements ILineItem {
  readonly underlying: ILineItem;
  readonly product: IProduct;

  constructor(underlying: ILineItem, product: IProduct) {
    this.underlying = underlying;
    this.product = product;
  }

  static arrayForState(state: IAppState) {
    const productsBySku = getProductsBySku(state.config.products);
    return Object.values(state.cart.lineItems).flatMap(lineItem => {
      const product = productsBySku.get(lineItem.sku);
      return product ? [new RichLineItem(lineItem, product)] : [];
    });
  }

  get id() {
    return this.underlying.id;
  }

  get productID() {
    return this.underlying.productID;
  }

  get sku() {
    return this.underlying.sku;
  }

  get type() {
    return this.underlying.type;
  }

  get description() {
    return this.underlying.description;
  }

  get details() {
    return this.underlying.details;
  }

  get price() {
    return this.underlying.price;
  }

  get quantity() {
    return this.underlying.quantity;
  }

  get subtotal() {
    return this.underlying.subtotal;
  }

  get giftCardDelivery() {
    return this.underlying.giftCardDelivery;
  }
}
