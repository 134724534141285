import { BaseOptionButton } from "./BaseOptionButton";
import * as types from "../../../../types";
import classNames from "classnames";

const CollarTypeOptionButton = BaseOptionButton<types.CollarTypeOption>({
  classForProps: props => {
    return classNames("rectangle-button", {
      selected: props.selected,
      unavailable: !props.available,
      blocked: props.blocked
    });
  },
  contentsForProps: props => {
    switch (props.value) {
      case types.CollarTypeOption.Standard:
        return "Standard";
      case types.CollarTypeOption.Martingale:
        return "Martingale";
    }
  }
});

export default CollarTypeOptionButton;
