import * as types from "../../types";
import { ProductLinkLoc } from "../../lib/productLinks";

/**
 * The union of complex and simple product objects
 */
export type ProductType = types.IComplexProduct | types.ISimpleProduct;

/**
 * @returns Whether a specified product is an IComplexProduct (i.e. has the variants array)
 */
export function productIsComplex(
  p: types.IProduct
): p is types.IComplexProduct {
  return p.kind === types.ProductKind.Complex;
}

/**
 * @returns Whether two variant.option objects are equal
 */
export function variantOptionsEqual(
  l: types.IVariantOptions,
  r: types.IVariantOptions
): boolean {
  return (
    l.color === r.color && l.size === r.size && l.collarType === r.collarType
  );
}

function partialVariantOptionsMatch(
  l: Partial<types.IVariantOptions>,
  r: types.IVariantOptions
) {
  return (
    (l.color === undefined || l.color === r.color) &&
    (l.size === undefined || l.size === r.size) &&
    (l.collarType === undefined || l.collarType === r.collarType)
  );
}

export function variantWithOptions(
  product: types.IComplexProduct,
  options: Partial<types.IVariantOptions>,
  preferences?: Partial<types.IVariantOptions>
): types.IVariant | undefined {
  let matches = product.variants.filter(v =>
    partialVariantOptionsMatch(options, v.options)
  );

  // If we got no matches or don't need to check preferences, just return
  if (matches.length === 0) {
    return undefined;
  } else if (preferences === undefined) {
    return matches[0];
  }

  // Otherwise, stack by preferences:
  const preference: Array<[number, types.IVariant | undefined]> = matches.map(
    v => {
      let result = 0;
      if (preferences.collarType === v.options.collarType) {
        result++;
      }
      if (preferences.color === v.options.color) {
        result++;
      }
      if (preferences.size === v.options.size) {
        result++;
      }
      return [result, v];
    }
  );

  const best = preference.reduce(
    (soFar, current) => {
      if (soFar === undefined) {
        return current;
      } else if (soFar[0] < current[0]) {
        return current;
      } else {
        return soFar;
      }
    },
    [-1, undefined]
  );

  return best[1];
}

export interface IProductDetailsProps<P extends ProductType> {
  product: P;
  addLineItem(lineItem: types.ILineItem): void;
  linkFrom?: ProductLinkLoc;
}
