import { useMemo } from "react";

import { ICart, ILineItem, IProduct, ProductType } from "../../types";
import { getProductsBySku } from "../../lib/util";

type CartItems = { [id: string]: ILineItem };

interface ICartStats {
  giftCardLineItems: CartItems;
  physicalLineItems: CartItems;
  subscriptionLineItems: CartItems;
  freeTrialLineItemIds: Set<string>;

  subscriptionsRequired: number;
  subscriptionsInCart: number;

  applyingGiftCard: boolean;
  haveAnyGiftCardsInCart: boolean;
  onlyGiftCardsInCart: boolean;
}

const useCartStats = (cart: ICart, products: IProduct[]): ICartStats => {
  const productsBySku = useMemo(() => getProductsBySku(products), [products]);

  return useMemo(() => {
    const lineItems = Object.values(cart.lineItems);

    let subscriptionsRequired = 0;
    let subscriptionsInCart = 0;

    const giftCardLineItems: CartItems = {};
    const physicalLineItems: CartItems = {};
    const subscriptionLineItems: CartItems = {};
    let freeTrialLineItemIds = new Set<string>();

    let haveAnyGiftCardsInCart = false;
    let onlyGiftCardsInCart = lineItems.length > 0;

    const applyingGiftCard = !!cart.giftCardDetails;

    for (const lineItem of lineItems) {
      const product = productsBySku.get(lineItem.sku);

      if (product) {
        if (product.subscriptionRequired) {
          subscriptionsRequired++;
        }

        if (product.type === ProductType.Subscription) {
          subscriptionsInCart++;
          onlyGiftCardsInCart = false;
          subscriptionLineItems[lineItem.id] = lineItem;
        } else if (product.type === ProductType.GiftCard) {
          haveAnyGiftCardsInCart = true;
          giftCardLineItems[lineItem.id] = lineItem;
        } else if (product.type === ProductType.Addon) {
          onlyGiftCardsInCart = false;
          physicalLineItems[lineItem.id] = lineItem;
        }

        // Free Trials don't get applied if you're getting it as a gift
        if (!applyingGiftCard && product.freeTrial) {
          freeTrialLineItemIds.add(lineItem.id);
        }
      }
    }

    return {
      applyingGiftCard,
      haveAnyGiftCardsInCart,
      onlyGiftCardsInCart,
      subscriptionsRequired,
      subscriptionsInCart,
      physicalLineItems,
      giftCardLineItems,
      subscriptionLineItems,
      freeTrialLineItemIds
    };
  }, [productsBySku, cart.giftCardDetails, cart.lineItems]);
};

export default useCartStats;
