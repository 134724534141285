import {
  FiEventName,
  IAddress,
  IBillingInfo,
  ICart,
  ILineItem,
  IProduct,
  IVariant,
  ProductKind,
  ProductType
} from "../../types";
import store from "../store";
import { getProductsBySku, expectUnreachable } from "../util";
import analytics from "./";
import * as postie from "./postie";
import { getCartInformation } from "./analyticsUtils";

export interface IEventDetails {
  eventName: string;
  eventProps?: any;
}

interface IPageLoad {
  kind: "PageLoad";
}

interface IVariantChanged {
  kind: "VariantChanged";
  previousVariant: IVariant;
}

interface IProductChanged {
  kind: "ProductChanged";
}

export type ProductViewReason = IPageLoad | IVariantChanged | IProductChanged;

type EComEventName =
  | "Product Viewed"
  | "Product Added"
  | "Cart Viewed"
  | "Checkout Started"
  | "Checkout Step Completed"
  | "Payment Info Entered"
  | "Product Removed"
  | "Order Completed";

interface ISegmentProduct {
  id: string;
  product_id: string;
  sku: string;
  name: string;
  variant?: string;
  price: number;
  quantity: number;
}

function fiProductToSegmentProduct(
  product: IProduct,
  variant: IVariant | undefined
): ISegmentProduct | undefined {
  let sku: string;
  if (product.kind === ProductKind.Complex) {
    if (variant) {
      sku = variant.sku;
    } else {
      return undefined;
    }
  } else {
    sku = product.sku;
  }

  return {
    id: sku,
    product_id: product.id,
    sku,
    name: product.name,
    variant: variant
      ? `${variant.options.size} ${variant.options.color}`
      : undefined,
    price: product.priceInCents / 100,
    quantity: 1
  };
}

export function productViewed({
  product,
  variant,
  reason
}: {
  product: IProduct;
  variant?: IVariant;
  reason: ProductViewReason;
}) {
  // Fi event
  let fiEventName: FiEventName | undefined;
  let fiEventProps: any = {};
  if (
    product.kind === ProductKind.Simple ||
    product.kind === ProductKind.GiftCard
  ) {
    if (product.analyticsEvents) {
      if (reason.kind === "PageLoad") {
        fiEventName = product.analyticsEvents.pageLoad;
      } else if (reason.kind === "ProductChanged") {
        fiEventName = product.analyticsEvents.productSwitch;
        fiEventProps = { plan: product.id };
      }
    }
  } else if (product.kind === ProductKind.Complex) {
    if (product.analyticsEvents) {
      if (reason.kind === "PageLoad") {
        fiEventName = product.analyticsEvents.pageLoad;
      } else if (reason.kind === "VariantChanged" && variant) {
        if (reason.previousVariant.options.color !== variant.options.color) {
          fiEventName = product.analyticsEvents.colorSwitch;
          fiEventProps = { new_color: variant.options.color };
        } else if (
          reason.previousVariant.options.size !== variant.options.size
        ) {
          fiEventName = product.analyticsEvents.sizeSwitch;
          fiEventProps = { new_size: variant.options.size };
        }
      }
    }
  } else {
    expectUnreachable(product);
  }

  if (fiEventName) {
    analytics.track(fiEventName, fiEventProps);
  }

  // Ecommerce event
  const segmentProduct = fiProductToSegmentProduct(product, variant);
  if (segmentProduct) {
    analytics.track("Product Viewed" as EComEventName, segmentProduct);
  }
}

export function addToBag({
  product,
  variant
}: {
  product: IProduct;
  variant?: IVariant;
}) {
  // Fi event
  if (product.analyticsEvents) {
    const fiEventName: FiEventName = product.analyticsEvents.addToBag;
    let fiEventProps: any = {};
    if (product.type === ProductType.Subscription) {
      fiEventProps = { plan: product.id };
    } else if (product.kind === ProductKind.Complex && variant) {
      fiEventProps = {
        color: variant.options.color,
        size: variant.options.size
      };
    }
    analytics.track(fiEventName, fiEventProps);
  }

  // ECom event
  const segmentProduct = fiProductToSegmentProduct(product, variant);
  if (segmentProduct) {
    analytics.track("Product Added" as EComEventName, segmentProduct);
  }
}

export function productRemoved({
  lineItem,
  suppressFiEvent
}: {
  lineItem: ILineItem;
  suppressFiEvent?: boolean;
}) {
  const productAndVariant = lineItemToProductAndVariant(
    lineItem,
    getProductsBySku(store.getState().config.products)
  );
  if (!productAndVariant) {
    return;
  }
  const { product, variant } = productAndVariant;
  // Fi event
  if (product.analyticsEvents && !suppressFiEvent) {
    analytics.track(product.analyticsEvents.removed, {});
  }

  // ECom event
  const segmentProduct = fiProductToSegmentProduct(product, variant);
  analytics.track("Product Removed" as EComEventName, segmentProduct);
}

export function orderCompleted(
  cart: ICart,
  orderId: string,
  shippingAddress: IAddress | undefined,
  billingInfo: IBillingInfo | undefined
) {
  const products = lineItemsToSegmentProducts(cart.lineItems);
  const couponDetails = cart.couponDetails;
  const cartInformation = getCartInformation(cart);
  const total =
    (cartInformation && cartInformation.revenueIncludingFreeTrials) ||
    undefined;
  // https://segment.com/docs/destinations/google-analytics/#required-steps
  // N.B. Order completed event requires an "orderId"
  analytics.track("Order Completed" as EComEventName, {
    orderId,
    products,
    total,
    code: couponDetails ? couponDetails.code : undefined
  });
  if (shippingAddress && billingInfo) {
    postie.trackPurchase(cart, orderId, shippingAddress, billingInfo);
  }
}

export const gpsPage = {
  getItLater() {
    analytics.track("GPS page > Get it later" as FiEventName, {});
  },

  selectFree() {
    analytics.track("GPS page > Select free" as FiEventName, {});
  }
};

function lineItemToProductAndVariant(
  lineItem: ILineItem,
  productsBySku: Map<string, IProduct>
) {
  const product = productsBySku.get(lineItem.sku);
  if (!product) {
    return undefined;
  }
  const variant =
    product.kind === ProductKind.Complex
      ? product.variants.find(v => v.sku === lineItem.sku)
      : undefined;
  return { product, variant };
}

function lineItemToSegmentProduct(
  lineItem: ILineItem,
  productsBySku: Map<string, IProduct>
) {
  const productAndVariant = lineItemToProductAndVariant(
    lineItem,
    productsBySku
  );
  if (!productAndVariant) {
    return undefined;
  }
  const { product, variant } = productAndVariant;
  return fiProductToSegmentProduct(product, variant);
}

function lineItemsToSegmentProducts(lineItems: { [id: string]: ILineItem }) {
  const segmentProducts: ISegmentProduct[] = [];
  const productsBySku = getProductsBySku(store.getState().config.products);
  for (const lineItem of Object.values(lineItems)) {
    const segmentProduct = lineItemToSegmentProduct(lineItem, productsBySku);
    if (segmentProduct) {
      segmentProducts.push(segmentProduct);
    }
  }
  return segmentProducts;
}

export const cartPage = {
  viewed(lineItems: { [id: string]: ILineItem }) {
    // Fi event
    analytics.track("Cart page" as FiEventName, {});

    // ECom event
    const products = lineItemsToSegmentProducts(lineItems);
    analytics.track("Cart Viewed" as EComEventName, { products });
  },

  addBand() {
    analytics.track("Cart page > Add band" as FiEventName, {});
  },

  addGiftCard() {
    analytics.track("Cart page > Add gift card", {});
  },

  continueShopping() {
    analytics.track("Cart page > Continue shopping" as FiEventName, {});
  },

  addSubscription() {
    analytics.track("Cart page > Add subscription" as FiEventName, {});
  },

  removeAll(lineItems: { [id: string]: ILineItem }) {
    analytics.track("Cart page > Remove all" as FiEventName, {});
    for (const lineItem of Object.values(lineItems)) {
      // Already sent the Fi remove all event.
      productRemoved({ lineItem, suppressFiEvent: true });
    }
  },

  checkout(lineItems: { [id: string]: ILineItem }) {
    analytics.track("Cart page > Checkout" as FiEventName, {});
    const products = lineItemsToSegmentProducts(lineItems);
    analytics.track("Checkout Started" as EComEventName, {
      products,
      value: parseFloat(store.getState().cart.summary.total) || undefined,
      currency: "USD"
    });
  },

  couponApplied(code: string) {
    analytics.track("Cart page > Coupon applied" as FiEventName, { code });
  },

  couponError(code: string, message: string) {
    analytics.track("Cart page > Coupon error" as FiEventName, {
      code,
      message
    });
  },

  referralCodeApplied(code: string) {
    // TODO: FiEventName?
    analytics.track("Cart page > Referral code applied", { code });
  },

  giftCardApplied(code: string) {
    analytics.track("Cart page > Gift card applied" as FiEventName, { code });
  },

  giftCardError(code: string, message: string) {
    analytics.track("Cart page > Gift card error" as FiEventName, {
      code,
      message
    });
  },

  couponRemoved(code: string) {
    analytics.track("Cart page > Coupon removed" as FiEventName, { code });
  },

  applePayBegin() {
    analytics.track("Cart page > Apple pay begin" as FiEventName, {});
  },

  applePaySuccess() {
    analytics.track("Cart page > Apple pay success" as FiEventName, {});
  },

  applePayError(message: string) {
    analytics.track("Cart page > Apple pay error" as FiEventName, { message });
  },

  applePayCancel() {
    analytics.track("Cart page > Apple pay cancel" as FiEventName, {});
  }
};

function checkoutStepCompleted(cart: ICart) {
  const cartInformation = getCartInformation(cart);
  analytics.track(
    "Checkout Step Completed" as EComEventName,
    cartInformation
      ? { value: cartInformation.revenueIncludingFreeTrials, currency: "USD" }
      : {}
  );
}

export const shipping = {
  viewed() {
    analytics.track("Shipping page" as FiEventName, {});
  },

  continue(cart: ICart) {
    analytics.track("Shipping page > Continue" as FiEventName, {});
    checkoutStepCompleted(cart);
  },

  continueError(message?: string) {
    analytics.track("Shipping page > Continue Error" as FiEventName, {
      message
    });
  },

  applePayBegin() {
    analytics.track("Shipping page > Apple pay begin" as FiEventName, {});
  },

  applePaySuccess() {
    analytics.track("Shipping page > Apple pay success" as FiEventName, {});
  },

  applePayError(message: string) {
    analytics.track("Shipping page > Apple pay error" as FiEventName, {
      message
    });
  },

  applePayCancel() {
    analytics.track("Shipping page > Apple pay cancel" as FiEventName, {});
  }
};

export const payment = {
  viewed() {
    analytics.track("Payment page" as FiEventName, {});
  },

  continue() {
    analytics.track("Payment page > Payment Success" as FiEventName, {});
    analytics.track("Payment Info Entered" as EComEventName, {});
  },

  continueError(message?: string) {
    analytics.track("Payment page > Payment Error" as FiEventName, { message });
  },

  applePayBegin() {
    analytics.track("Payment page > Apple pay begin" as FiEventName, {});
  },

  applePaySuccess() {
    analytics.track("Payment page > Apple pay success" as FiEventName, {});
  },

  applePayError(message: string) {
    analytics.track("Payment page > Apple pay error" as FiEventName, {
      message
    });
  },

  applePayCancel() {
    analytics.track("Payment page > Apple pay cancel" as FiEventName, {});
  }
};

export const confirmation = {
  viewed() {
    analytics.track("Confirmation Page" as FiEventName, {});
  },

  finalConfirm(cart: ICart) {
    analytics.track("Confirmation Page > Final Confirm" as FiEventName, {});
    checkoutStepCompleted(cart);
  },

  error(message?: string) {
    analytics.track("Confirmation Page > Error" as FiEventName, { message });
  }
};

export const thankYou = {
  viewed() {
    analytics.track("Thank you page" as FiEventName, {});
  },

  setPasswordSuccess() {
    analytics.track("Thank you page > Set password success" as FiEventName, {});
  },

  setPasswordFailure() {
    analytics.track("Thank you page > Set password failure" as FiEventName, {});
  },

  downloadLinkDetails(): IEventDetails {
    return { eventName: "Thank you page > Download clicked" };
  }
};

export const referrals = {
  codeCopied() {
    analytics.track("Referrals Page > Code Copied", {});
  },

  shareClicked(nativeShare: boolean) {
    analytics.track("Referrals Page > Share Clicked", { nativeShare });
  }
};

export const referralRedirect = {
  loaded(code: string) {
    analytics.track("Referral Redirect > Loaded", { code });
  }
};

export const collarPage = {
  smallSizeWarningShown() {
    analytics.track("Collar page > Small size warning shown", {});
  },

  smallSizeWarningLessSelected() {
    analytics.track("Collar page > Small size warning > Less selected", {});
  },

  smallSizeWarningMoreSelected() {
    analytics.track("Collar page > Small size warning > More selected", {});
  }
};
