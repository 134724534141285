import { useMemo } from "react";

import { useSelector } from "react-redux";
import {
  getProductsBySku,
  isSubscriptionProduct,
  getRefereeRewardItemIfApplicable
} from "../../../../lib/util";
import * as types from "../../../../types";

/**
 * Return a set of SKUs of items that have a free trial.
 */
export default function useFreeTrialSet(cart: types.ICart) {
  const products = useSelector(
    (state: types.IAppState) => state.config.products
  );
  const refereeRewardProductId = useSelector(
    (state: types.IAppState) => state.config.siteConfig.refereeRewardProductId
  );

  const freeTrialSet = useMemo(() => {
    const refereeRewardLineItem = getRefereeRewardItemIfApplicable(
      cart,
      refereeRewardProductId
    );
    const set = new Set(
      refereeRewardLineItem ? [refereeRewardLineItem.id] : []
    );
    if (cart.giftCardDetails) {
      return set;
    }

    const productsBySku = getProductsBySku(products);
    for (const lineItem of Object.values(cart.lineItems)) {
      const product = productsBySku.get(lineItem.sku);
      if (product && isSubscriptionProduct(product) && product.freeTrial) {
        set.add(lineItem.id);
      }
    }
    return set;
  }, [cart, products, refereeRewardProductId]);

  return freeTrialSet;
}
