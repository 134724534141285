import { IWindow } from "../Window";
import { getActiveExperiments } from "./experiments";

export interface IAnalyticsBackend {
  identify(userId: string, email: string | undefined): void;
  page(): void;
  track(name: string, properties: any): void;
  ready(callback: () => void): void;
  trackLink(element: HTMLElement, name: string, properties?: any): void;

  pause(): void;
  unpause(): void;
}

interface ISegmentAnalyticsSource {
  analytics: SegmentAnalytics.AnalyticsJS;
}

function augmentProperties(properties: any) {
  let timezone: string | undefined;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // Noop
  }
  return {
    ...properties,
    experiments: getActiveExperiments(),
    timezone
  };
}

export class QueuedAnalytics implements IAnalyticsBackend {
  private readonly backend: IAnalyticsBackend;
  private readonly queue: Array<{ name: keyof IAnalyticsBackend; args: any[] }>;
  private pauseCount: number;

  constructor(backend: IAnalyticsBackend) {
    this.backend = backend;
    this.queue = [];
    this.pauseCount = 0;
  }

  public identify(userId: string, email: string | undefined) {
    this.queueOrExecute("identify", userId, email);
  }

  public page(): void {
    this.queueOrExecute("page");
  }

  public track(name: string, properties: any): void {
    this.queueOrExecute("track", name, properties);
  }

  public ready(callback: () => void) {
    this.backend.ready(callback);
  }

  public trackLink(element: HTMLElement, name: string, properties?: any) {
    // No queueing necessary
    this.backend.trackLink(element, name, properties);
  }

  public pause() {
    this.pauseCount++;
  }

  public unpause() {
    this.pauseCount--;
    if (this.pauseCount === 0) {
      this.flushQueue();
    }
  }

  private executeCall({
    name,
    args
  }: {
    name: keyof IAnalyticsBackend;
    args: any[];
  }) {
    (this.backend[name] as (...args: any) => void).apply(this.backend, args);
  }

  private queueOrExecute<K extends keyof IAnalyticsBackend>(
    name: K,
    ...args: Parameters<IAnalyticsBackend[K]>
  ) {
    const obj = { name, args };
    if (this.pauseCount === 0) {
      this.executeCall(obj);
    } else {
      this.queue.push(obj);
    }
  }

  private flushQueue() {
    while (this.queue.length > 0) {
      this.executeCall(this.queue.shift()!);
    }
  }
}

class SegmentAnalytics implements IAnalyticsBackend {
  private source: ISegmentAnalyticsSource;

  constructor(source: ISegmentAnalyticsSource) {
    this.source = source;
  }

  public identify(userId: string, email: string | undefined) {
    this.source.analytics.identify(userId, {
      email
    });
  }

  public page(): void {
    this.source.analytics.page(augmentProperties({}));
  }

  public track(name: string, properties: any): void {
    this.source.analytics.track(name, augmentProperties(properties));
  }

  public trackLink(element: HTMLElement, name: string, properties: any = {}) {
    this.source.analytics.trackLink(element, name, properties);
  }

  public ready(callback: () => void) {
    this.source.analytics.ready(callback);
  }

  public pause() {
    // Noop
  }

  public unpause() {
    // Noop
  }
}

// tslint:disable:no-console
class LogAnalytics implements IAnalyticsBackend {
  public identify(userId: string, email: string | undefined) {
    console.log("Identifying user as: ", userId, email);
  }

  public page(): void {
    console.log("Analytics: page");
  }

  public track(name: string, properties: any): void {
    console.log(
      "Analytics: track - name: ",
      name,
      ", properties: ",
      augmentProperties(properties)
    );
  }

  public ready(callback: () => void) {
    setTimeout(() => {
      callback();
    }, 0);
  }

  public trackLink(element: HTMLElement, name: string, properties?: any) {
    // Noop
  }

  public pause() {
    // Noop
  }

  public unpause() {
    // Noop
  }
}
// tslint:enable:no-console

const extendedWindow: IWindow = window;
const analytics = extendedWindow.analytics
  ? new QueuedAnalytics(
      new SegmentAnalytics(extendedWindow as ISegmentAnalyticsSource)
    )
  : new LogAnalytics();

export default analytics;
