import {
  IAddress,
  IBillingInfo,
  ICart,
  IProduct,
  ProductKind
} from "../../types";
import store from "../store";
import { centsToDollars, getProductsBySku } from "../util";
import { IWindow } from "../Window";

function variantDescription(sku: string, product: IProduct) {
  if (product.kind === ProductKind.Complex) {
    const variant = product.variants.find(v => v.sku === sku);
    if (variant) {
      return `${variant.options.size} ${variant.options.color}`;
    }
  }
  return "";
}

export function trackEmailSignup(email: string) {
  const extendedWindow: IWindow = window;
  if (!extendedWindow.letterpress) {
    return;
  }

  extendedWindow.letterpress("trackUnstructEvent", {
    schema: "iglu:com.getletterpress/email/jsonschema/1-0-0",
    data: {
      email
    }
  });
}

export function trackPurchase(
  cart: ICart,
  orderId: string,
  shippingAddress: IAddress,
  billingInfo: IBillingInfo
) {
  const extendedWindow: IWindow = window;
  if (!extendedWindow.letterpress) {
    return;
  }

  const session = store.getState().session;
  extendedWindow.letterpress(
    "addTrans",
    orderId, // order ID
    "Fi", // affiliation or store name
    cart.summary.total, // grand total
    cart.summary.tax, // tax amount
    "0", // shipping amount
    shippingAddress.city, // city
    shippingAddress.state, // state or province
    shippingAddress.country, // country
    "USD", // currency
    [
      {
        schema: "iglu:com.getletterpress/address/jsonschema/1-0-0",
        data: {
          name: `${billingInfo.firstName} ${billingInfo.lastName}`,
          street: shippingAddress.line1,
          street_2: shippingAddress.line2 || "",
          zip: shippingAddress.zip,
          city: shippingAddress.city,
          state: shippingAddress.state,
          country: shippingAddress.country,
          email: (session && session.email) || ""
        }
      }
    ]
  );

  const productsBySku = getProductsBySku(store.getState().config.products);
  for (const lineItem of Object.values(cart.lineItems)) {
    const product = productsBySku.get(lineItem.sku);
    if (!product) {
      continue;
    }
    extendedWindow.letterpress(
      "addItem",
      orderId, // order ID
      lineItem.sku, // SKU
      product.name, // product name
      variantDescription(lineItem.sku, product), // category or variation
      centsToDollars(product.priceInCents), // unit price
      "1", // quantity
      "USD" // currency
    );
  }

  extendedWindow.letterpress("trackTrans");
}
