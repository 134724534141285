import React from "react";
import { Redirect } from "react-router-dom";

import { ProductBackButton } from "../../lib/productLinks";
import { IProductDetailsProps } from "./ProductProps";
import * as types from "../../types";
import useTracking from "./useTracking";
import ProductNameImage from "./components/ProductNameImage";
import ProductDetailsColumn from "./components/ProductDetails";
import useAddToBag from "./useAddToBag";

const SimpleProductDetails = ({
  linkFrom,
  product,
  addLineItem
}: IProductDetailsProps<types.ISimpleProduct>) => {
  useTracking(product);

  const { addToBag, addedToBag } = useAddToBag(product, addLineItem);

  if (addedToBag) {
    return <Redirect to="/bag" />;
  }

  return (
    <>
      {linkFrom ? (
        <ProductBackButton from={linkFrom} />
      ) : (
        <div className="back-bar-spacer" />
      )}
      <div className="split-container product-container">
        <ProductNameImage product={product} />
        <ProductDetailsColumn
          product={product}
          allowPurchase={true}
          blockedSkus={[]}
          onAddToBag={addToBag}
        />
      </div>
    </>
  );
};

export default SimpleProductDetails;
