export class ApplePayCancelledError extends Error {
  constructor() {
    super(`Apple Pay cancelled`);
  }
}

export class AccountExistsError extends Error {
  constructor() {
    super(`Account already exists`);
  }
}
