import gql from "graphql-tag";

import { ICart } from "../types";
import { gqlTypes } from "../types/gql";

export const createPurchaseMutation = gql`
  mutation createPurchase($input: CreatePurchaseInput!) {
    createPurchase(input: $input) {
      id
      invoiceNumber
    }
  }
`;

export const previewPurchaseQuery = gql`
  query previewPurchase($input: CreatePurchaseInput!) {
    previewPurchase(input: $input) {
      taxInCents
      totalInCents
    }
  }
`;

export function mapCartToVariables(
  cart: ICart,
  shippingOptionCode: string | null
): gqlTypes.createPurchaseVariables {
  const lineItems = Object.values(cart.lineItems).map(
    (lineItem): gqlTypes.LineItemInput => ({
      id: lineItem.sku,
      quantity: lineItem.quantity,
      giftCardDelivery: lineItem.giftCardDelivery
    })
  );

  const couponDetails = cart.couponDetails;
  const coupons: gqlTypes.CouponInput[] = couponDetails
    ? [{ code: couponDetails.code }]
    : [];

  return {
    input: {
      lineItems,
      coupons,
      shippingOption: shippingOptionCode ? { code: shippingOptionCode } : null,
      redeemGiftCard: cart.giftCardDetails
        ? { redemptionCode: cart.giftCardDetails.redemptionCode }
        : null,
      referral: cart.referralDetails
        ? { referralCode: cart.referralDetails.referralCode }
        : null
    }
  };
}
