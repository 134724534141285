/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userSettings
// ====================================================

export interface userSettings_currentUser_settings {
  __typename: "UserSettings";
  showDebugMenu: boolean;
}

export interface userSettings_currentUser {
  __typename: "User";
  id: string;
  settings: userSettings_currentUser_settings;
}

export interface userSettings {
  currentUser: userSettings_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: billingAccount
// ====================================================

export interface billingAccount_currentUser_billingAccount_billingInfo_address {
  __typename: "Address";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface billingAccount_currentUser_billingAccount_billingInfo_cardInfo {
  __typename: "ObfuscatedCardInfo";
  type: string;
  lastFour: number;
  month: number;
  year: number;
}

export interface billingAccount_currentUser_billingAccount_billingInfo {
  __typename: "BillingInfo";
  firstName: string;
  lastName: string;
  address: billingAccount_currentUser_billingAccount_billingInfo_address;
  cardInfo: billingAccount_currentUser_billingAccount_billingInfo_cardInfo;
}

export interface billingAccount_currentUser_billingAccount_address {
  __typename: "Address";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string | null;
}

export interface billingAccount_currentUser_billingAccount {
  __typename: "BillingAccount";
  billingInfo: billingAccount_currentUser_billingAccount_billingInfo | null;
  address: billingAccount_currentUser_billingAccount_address | null;
}

export interface billingAccount_currentUser {
  __typename: "User";
  id: string;
  firstName: string;
  billingAccount: billingAccount_currentUser_billingAccount | null;
}

export interface billingAccount {
  currentUser: billingAccount_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBillingInfo
// ====================================================

export interface updateBillingInfo_updateBillingAccount_billingInfo_address {
  __typename: "Address";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface updateBillingInfo_updateBillingAccount_billingInfo_cardInfo {
  __typename: "ObfuscatedCardInfo";
  lastFour: number;
  type: string;
  month: number;
  year: number;
}

export interface updateBillingInfo_updateBillingAccount_billingInfo {
  __typename: "BillingInfo";
  firstName: string;
  lastName: string;
  address: updateBillingInfo_updateBillingAccount_billingInfo_address;
  cardInfo: updateBillingInfo_updateBillingAccount_billingInfo_cardInfo;
}

export interface updateBillingInfo_updateBillingAccount {
  __typename: "BillingAccount";
  billingInfo: updateBillingInfo_updateBillingAccount_billingInfo | null;
}

export interface updateBillingInfo {
  updateBillingAccount: updateBillingInfo_updateBillingAccount;
}

export interface updateBillingInfoVariables {
  input: BillingAccountInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateShippingAddress
// ====================================================

export interface updateShippingAddress_updateBillingAccount_address {
  __typename: "Address";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string | null;
}

export interface updateShippingAddress_updateBillingAccount {
  __typename: "BillingAccount";
  address: updateShippingAddress_updateBillingAccount_address | null;
}

export interface updateShippingAddress {
  updateBillingAccount: updateShippingAddress_updateBillingAccount;
}

export interface updateShippingAddressVariables {
  address: AddressInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: referrals
// ====================================================

export interface referrals_currentUser_referrals {
  __typename: "Referrals";
  referralCode: string;
  numConfirmedReferrals: number;
  numPendingReferrals: number;
  unlockedRewardTypes: ReferralRewardType[];
  codeSuspended: boolean;
}

export interface referrals_currentUser {
  __typename: "User";
  id: string;
  referrals: referrals_currentUser_referrals;
}

export interface referrals {
  currentUser: referrals_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: billingAccountExists
// ====================================================

export interface billingAccountExists_currentUser_billingAccount {
  __typename: "BillingAccount";
  code: string;
}

export interface billingAccountExists_currentUser {
  __typename: "User";
  id: string;
  billingAccount: billingAccountExists_currentUser_billingAccount | null;
}

export interface billingAccountExists {
  currentUser: billingAccountExists_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createBillingAccount
// ====================================================

export interface createBillingAccount_createBillingAccount {
  __typename: "BillingAccount";
  code: string;
}

export interface createBillingAccount {
  createBillingAccount: createBillingAccount_createBillingAccount;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createPurchase
// ====================================================

export interface createPurchase_createPurchase {
  __typename: "Invoice";
  id: string;
  invoiceNumber: string;
}

export interface createPurchase {
  createPurchase: createPurchase_createPurchase;
}

export interface createPurchaseVariables {
  input: CreatePurchaseInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: previewPurchase
// ====================================================

export interface previewPurchase_previewPurchase {
  __typename: "PreviewPurchaseResult";
  taxInCents: number;
  totalInCents: number;
}

export interface previewPurchase {
  previewPurchase: previewPurchase_previewPurchase;
}

export interface previewPurchaseVariables {
  input: CreatePurchaseInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rescueTransferPetPermanently
// ====================================================

export interface rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount_billingInfo_address {
  __typename: "Address";
  line1: string;
  line2: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount_billingInfo_cardInfo {
  __typename: "ObfuscatedCardInfo";
  lastFour: number;
  type: string;
  month: number;
  year: number;
}

export interface rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount_billingInfo {
  __typename: "BillingInfo";
  firstName: string;
  lastName: string;
  address: rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount_billingInfo_address;
  cardInfo: rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount_billingInfo_cardInfo;
}

export interface rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount {
  __typename: "BillingAccount";
  billingInfo: rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount_billingInfo | null;
}

export interface rescueTransferPetPermanently_rescueTransferPetPermanently {
  __typename: "RescueTransferPetPermanentlyResult";
  accountCreated: boolean;
  newAccountTemporaryPassword: string | null;
  billingAccount: rescueTransferPetPermanently_rescueTransferPetPermanently_billingAccount;
}

export interface rescueTransferPetPermanently {
  rescueTransferPetPermanently: rescueTransferPetPermanently_rescueTransferPetPermanently | null;
}

export interface rescueTransferPetPermanentlyVariables {
  input: RescueTransferPetPermanentlyInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rescueRecallPet
// ====================================================

export interface rescueRecallPet_rescueRecallPet {
  __typename: "RescueRecallPetResult";
  ok: boolean | null;
}

export interface rescueRecallPet {
  rescueRecallPet: rescueRecallPet_rescueRecallPet | null;
}

export interface rescueRecallPetVariables {
  input: RescueRecallPetInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: rescueTransferPetTemporarily
// ====================================================

export interface rescueTransferPetTemporarily_rescueTransferPetTemporarily {
  __typename: "RescueTransferPetTemporarilyResult";
  accountCreated: boolean;
  newAccountTemporaryPassword: string | null;
}

export interface rescueTransferPetTemporarily {
  /**
   * Mutations for rescue organizations
   */
  rescueTransferPetTemporarily: rescueTransferPetTemporarily_rescueTransferPetTemporarily | null;
}

export interface rescueTransferPetTemporarilyVariables {
  input: RescueTransferPetTemporarilyInput;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: rescuePlacementMain
// ====================================================

export interface rescuePlacementMain_currentUser_households_pets_avatarUrl {
  __typename: "Image";
  fullSize: string | null;
}

export interface rescuePlacementMain_currentUser_households_pets_household_users {
  __typename: "HouseholdUser";
  id: string;
  firstName: string;
  lastName: string | null;
  email: string;
}

export interface rescuePlacementMain_currentUser_households_pets_household {
  __typename: "Household";
  id: string;
  users: rescuePlacementMain_currentUser_households_pets_household_users[];
}

export interface rescuePlacementMain_currentUser_households_pets_device {
  __typename: "Device";
  id: string;
  moduleId: string;
}

export interface rescuePlacementMain_currentUser_households_pets {
  __typename: "Pet";
  id: string;
  name: string;
  avatarUrl: rescuePlacementMain_currentUser_households_pets_avatarUrl | null;
  household: rescuePlacementMain_currentUser_households_pets_household;
  device: rescuePlacementMain_currentUser_households_pets_device | null;
}

export interface rescuePlacementMain_currentUser_households {
  __typename: "Household";
  pets: rescuePlacementMain_currentUser_households_pets[];
  /**
   * Whether this is the first household that was created for this user.
   */
  firstCreated: boolean;
}

export interface rescuePlacementMain_currentUser {
  __typename: "User";
  id: string;
  households: rescuePlacementMain_currentUser_households[];
}

export interface rescuePlacementMain {
  currentUser: rescuePlacementMain_currentUser;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: referralsFragment
// ====================================================

export interface referralsFragment_referrals {
  __typename: "Referrals";
  referralCode: string;
  numConfirmedReferrals: number;
  numPendingReferrals: number;
  unlockedRewardTypes: ReferralRewardType[];
  codeSuspended: boolean;
}

export interface referralsFragment {
  __typename: "User";
  referrals: referralsFragment_referrals;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: petDetails
// ====================================================

export interface petDetails_avatarUrl {
  __typename: "Image";
  fullSize: string | null;
}

export interface petDetails_household_users {
  __typename: "HouseholdUser";
  id: string;
  firstName: string;
  lastName: string | null;
  email: string;
}

export interface petDetails_household {
  __typename: "Household";
  id: string;
  users: petDetails_household_users[];
}

export interface petDetails_device {
  __typename: "Device";
  id: string;
  moduleId: string;
}

export interface petDetails {
  __typename: "Pet";
  id: string;
  name: string;
  avatarUrl: petDetails_avatarUrl | null;
  household: petDetails_household;
  device: petDetails_device | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ReferralRewardType {
  FIRST_FREE_BAND = "FIRST_FREE_BAND",
  ONE_YEAR_EXTENSION = "ONE_YEAR_EXTENSION",
  SECOND_FREE_BAND = "SECOND_FREE_BAND",
  THREE_YEAR_EXTENSION = "THREE_YEAR_EXTENSION",
  TWO_YEAR_EXTENSION = "TWO_YEAR_EXTENSION",
}

export interface AddressInput {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone?: string | null;
}

export interface BillingAccountInput {
  billingInfo?: BillingInfoInput | null;
  address?: AddressInput | null;
}

export interface BillingInfoInput {
  token: string;
}

export interface CouponInput {
  code: string;
}

export interface CreatePurchaseInput {
  lineItems: LineItemInput[];
  coupons: CouponInput[];
  shippingOption?: ShippingOptionInput | null;
  redeemGiftCard?: RedeemGiftCardInput | null;
  referral?: ReferralInput | null;
}

export interface GiftCardDeliveryInput {
  deliverAt?: DateTime | null;
  emailAddress: string;
  firstName?: string | null;
  lastName?: string | null;
  gifterName?: string | null;
  personalMessage?: string | null;
}

export interface LineItemInput {
  id: string;
  quantity: number;
  giftCardDelivery?: GiftCardDeliveryInput | null;
}

export interface RedeemGiftCardInput {
  redemptionCode: string;
}

export interface ReferralInput {
  referralCode: string;
}

export interface RescueRecallPetInput {
  petId: string;
}

export interface RescueTransferPetPermanentlyInput {
  petId: string;
  email: string;
  firstName: string;
  lastName?: string | null;
  billingAccountInput: BillingAccountInput;
  giftCardRedemptionCode: string;
}

export interface RescueTransferPetTemporarilyInput {
  petId: string;
  firstName: string;
  lastName?: string | null;
  email: string;
}

export interface ShippingOptionInput {
  code: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
