import * as React from "react";

import * as events from "../../../lib/analytics/events";
import { Link } from "react-router-dom";

interface IAccessoryCTAProps {
  refereeCalloutProductID?: string;
}

export default function AccessoryCTA({
  refereeCalloutProductID
}: IAccessoryCTAProps) {
  return (
    <>
      {/* If they have to pick a band still as a referree, show that upsell.
      Otherwise, just show them the "add accessories" upsell. */}
      {refereeCalloutProductID !== undefined && (
        <div className="cart-callout">
          <Link
            to={{
              pathname: `/products/${refereeCalloutProductID}`
            }}
            className="cart-callouts-button referee-reward left-aligned"
          >
            <div>
              <h5>Choose a band</h5>
              <p>
                Congrats, you get a free <br />
                band with your order!
              </p>
            </div>
          </Link>
        </div>
      )}
      <div className="cart-callout">
        <div className="callout-image">
          <img src="/bag_collar_callout.png" alt="Another collar" />
        </div>
        <Link
          to={{ pathname: "/", state: { from: "cart" } }}
          className="cart-callouts-button add-collar"
          onClick={() => events.cartPage.addBand()}
        >
          + Add collar
        </Link>
      </div>
      <div className="cart-callout">
        <div className="callout-image">
          <img src="/bag_accessories_callout.png" alt="An extra collar strap" />
        </div>
        <Link
          to={{ pathname: "/accessories", state: { from: "cart" } }}
          className="cart-callouts-button add-accessories"
          onClick={() => events.cartPage.addBand()}
        >
          + Add accessories
        </Link>
      </div>
    </>
  );
}
