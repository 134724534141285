import React from "react";
import gql from "graphql-tag";
import { gqlTypes } from "../../types/gql";
import { useHistory, Redirect } from "react-router-dom";

export const rescuePlacementPath = "/rescue/placement";

export type RescuePlacementNavigationState =
  | {
      placementView: "petSelector";
    }
  | {
      placementView: "petPlacement";
      petId: string;
    }
  | {
      placementView: "petTemporaryPlacement";
      petId: string;
    }
  | {
      placementView: "petPermanentPlacement";
      petId: string;
    }
  | {
      placementView: "petRecall";
      petId: string;
    };

export const defaultRescuePlacementNavigationState: RescuePlacementNavigationState = {
  placementView: "petSelector"
};

export function RedirectToRescuePlacementHome() {
  return (
    <Redirect
      to={{
        pathname: rescuePlacementPath,
        state: defaultRescuePlacementNavigationState
      }}
    />
  );
}

export const rescuePlacementMainQuery = gql`
  query rescuePlacementMain {
    currentUser {
      id
      households {
        pets {
          ...petDetails
        }
        firstCreated
      }
    }
  }

  fragment petDetails on Pet {
    id
    name
    avatarUrl {
      fullSize
    }
    household {
      id
      users {
        id
        firstName
        lastName
        email
      }
    }
    device {
      id
      moduleId
    }
  }
`;

export interface RescuePlacementNavigator {
  go(state: RescuePlacementNavigationState): void;
}

export function useRescuePlacementNavigator(): RescuePlacementNavigator {
  const history = useHistory();
  return {
    go(state) {
      history.push(rescuePlacementPath, state);
    }
  };
}

export class RescuePlacementModel {
  readonly data: gqlTypes.rescuePlacementMain;
  readonly petMap: Map<string, gqlTypes.petDetails>;

  constructor(data: gqlTypes.rescuePlacementMain) {
    this.data = data;
    this.petMap = new Map(
      this.data.currentUser.households
        .flatMap(household => household.pets)
        .map(pet => [pet.id, pet])
    );
  }

  getPetById(id: string) {
    return this.petMap.get(id);
  }

  associatedUserForPet(pet: gqlTypes.petDetails) {
    const associatedUsers = pet.household.users.filter(
      u => u.id !== this.data.currentUser.id
    );
    return associatedUsers.length > 0 ? associatedUsers[0] : undefined;
  }

  get petsToBePlaced(): gqlTypes.petDetails[] {
    const firstHousehold = this.data.currentUser.households.find(
      h => h.firstCreated
    );
    return firstHousehold ? firstHousehold.pets : [];
  }

  get petsToBeRecalled(): gqlTypes.petDetails[] {
    return this.data.currentUser.households
      .filter(h => !h.firstCreated)
      .flatMap(h => h.pets);
  }
}

export function transferringOwnershipTitle(pet: gqlTypes.petDetails) {
  return `Transferring ${pet.name}’s Ownership`;
}

export function AccountInformation({
  data
}: {
  data: {
    accountCreated: boolean;
    newAccountTemporaryPassword?: string | null;
  };
}) {
  return data.accountCreated ? (
    <p>
      An account was created for this user with the temporary password{" "}
      <strong>{data.newAccountTemporaryPassword}</strong>
      .<br />
      They can log in using this password, or click "Forgot Password" in the
      app.
    </p>
  ) : (
    <p>
      This user already has an account so they should use their existing
      credentials to log in.
    </p>
  );
}
