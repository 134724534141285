import React, {
  useContext,
  useState,
  useEffect,
  ChangeEventHandler
} from "react";
import GiftCardContext, {
  FormErrors,
  clearError,
  updateGiftCardDelivery
} from "../../GiftCardContext";
import { IGiftCardDelivery } from "../../../../types";
import SimpleError from "./SimpleError";

interface IBaseInputProps {
  placeholder?: string;
  inputType?: string;
  disabled?: boolean;
}

interface ISimpleBoundInputProps extends IBaseInputProps {
  propertyName: keyof IGiftCardDelivery & keyof FormErrors;
  nonNull?: boolean;
}

function GiftCardInput({
  placeholder,
  inputType,
  disabled,
  inputName,
  onChange,
  onBlur,
  error,
  value
}: IBaseInputProps & {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: VoidFunction;
  inputName?: string;
  error?: string;
  value: string;
}) {
  return (
    <>
      <input
        type={inputType ?? "text"}
        name={inputName}
        disabled={disabled}
        placeholder={placeholder}
        className="wide data-hj-whitelist"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <SimpleError error={error} />
    </>
  );
}

export function SimpleBoundInput(props: ISimpleBoundInputProps) {
  const { propertyName, nonNull } = props;

  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);
  const value: string = giftCardState.delivery[propertyName] || "";
  const setValue = (newValue: string) =>
    giftCardDispatch(
      updateGiftCardDelivery({
        [propertyName]: nonNull ? newValue : newValue || null
      })
    );

  const error = giftCardState.errors[propertyName];

  return (
    <GiftCardInput
      {...props}
      value={value}
      inputName={propertyName}
      error={error}
      onChange={e => setValue(e.currentTarget.value)}
      onBlur={() => giftCardDispatch(clearError(propertyName))}
    />
  );
}

const SPLIT_NAME_REGEX = /([^ ]*) *(.*)/;

/**
 * Input for the Giftee's name. This has a bit of extra logic because we need to
 * split it into first/last before updating the GiftCardDelivery.
 */
export function GifteeNameInput(props: IBaseInputProps) {
  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);
  const [value, setValue] = useState("");
  const error = giftCardState.errors.gifteeName;

  useEffect(() => {
    const [, firstName, lastName] = value.match(SPLIT_NAME_REGEX)!;
    giftCardDispatch(
      updateGiftCardDelivery({
        firstName: firstName || null,
        lastName: lastName || null
      })
    );
  }, [value, giftCardDispatch]);

  return (
    <GiftCardInput
      {...props}
      value={value}
      error={error}
      placeholder="Name"
      inputName="to"
      onChange={e => setValue(e.currentTarget.value)}
      onBlur={() => giftCardDispatch(clearError("gifteeName"))}
    />
  );
}

// https://dev.recurly.com/v2.6/docs/create-gift-card
const MESSAGE_CHARACTER_LIMIT = 255;

/**
 * Textarea for the Gifter's message. Limits the number of characters.
 */
export function GiftMessageInput({ disabled }: { disabled?: boolean }) {
  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);
  const stageEnabled = !disabled;

  const value = giftCardState.delivery.personalMessage ?? "";
  const charactersRemaining = Math.max(
    0,
    MESSAGE_CHARACTER_LIMIT - value.length
  );

  const setValue = (newValue: string) =>
    giftCardDispatch(
      updateGiftCardDelivery({
        personalMessage: newValue
          ? newValue.substr(0, MESSAGE_CHARACTER_LIMIT)
          : null
      })
    );

  return (
    <>
      <div className="subheader-with-message">
        <h3>Message</h3>
        <span className="text--muted">
          {charactersRemaining} characters remaining
        </span>
      </div>
      <textarea
        className="wide data-hj-whitelist"
        value={value}
        placeholder="Start typing..."
        disabled={!stageEnabled}
        onChange={e => setValue(e.currentTarget.value)}
      />
    </>
  );
}
