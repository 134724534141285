import React, { useMemo } from "react";
import * as qs from "query-string";
import { useQuery } from "react-apollo-hooks";
import { gqlTypes } from "../types/gql";
import { referralsQuery } from "./ReferralsContainer.graphql";
import Referrals, { ReferralsData } from "../components/Referrals";
import { RouteComponentProps } from "react-router-dom";
import NotFound from "../components/NotFound";
import { useSelector } from "react-redux";
import { IAppState } from "../types";
import { useAxiosGet, isAxiosError } from "../lib/fi-api/apiUtils";
import { AxiosRequestConfig } from "axios";
import { getLoadingOrErrorElement } from "../lib/util";

function ReferralsLoggedInContainer() {
  const { loading, error, data } = useQuery<gqlTypes.referrals>(referralsQuery);

  let finalError: Error | undefined = error;
  if (data && data.currentUser.referrals.codeSuspended) {
    finalError = new Error(
      `That referral code has been suspended. Please contact support@tryfi.com for further information!`
    );
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, finalError);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }
  return <Referrals referralsData={data!.currentUser.referrals} />;
}

function ReferralsLoggedOutContainer({ userId }: { userId: string }) {
  const requestConfig = useMemo<AxiosRequestConfig>(
    () => ({ params: { userId } }),
    [userId]
  );
  const { loading, error, data } = useAxiosGet<ReferralsData>(
    "/api/ecommerce/referralinformation",
    requestConfig
  );
  if (error && isAxiosError(error) && error.response?.status === 404) {
    return <NotFound />;
  }

  let finalError = error;
  if (error && isAxiosError(error) && error.response?.data?.error?.message) {
    // Get error message from response data.
    finalError = new Error(error.response.data.error.message);
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, finalError);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }
  return <Referrals referralsData={data!} />;
}

export default function ReferralsContainer(props: RouteComponentProps) {
  const session = useSelector((state: IAppState) => state.session);
  const loggedIn = !!session;

  const queryParams = qs.parse(
    props.location.search[0] === "?"
      ? props.location.search.substr(1)
      : props.location.search
  );

  if (typeof queryParams.userId === "string" && !!queryParams.userId) {
    return <ReferralsLoggedOutContainer userId={queryParams.userId} />;
  } else if (loggedIn) {
    return <ReferralsLoggedInContainer />;
  } else {
    return <NotFound />;
  }
}
