/* tslint:disable:quotemark */

/*
 * Common product definitions for ecommerce.
 *
 * IMPORTANT NOTE: This file should be edited in the `web` repo only! Edit in
 * `web` and then run `yarn product:types` in the `ecommerce` repository to sync types.
 */

/**
 * The product kind affects the structure of the product; how it is displayed, the
 * options available, etc.
 */
export enum ProductKind {
  Simple = "simple",
  Complex = "complex",
  GiftCard = "giftCard"
}

/**
 * The product type affects the method by which a product is purchased through the Recurly
 * API.
 */
export enum ProductType {
  Subscription = "subscription",
  Addon = "addon",
  GiftCard = "giftCard"
}

export type ProductID = string;
export type IProduct = ISimpleProduct | IComplexProduct | IGiftCardProduct;

export interface IImage {
  url: string;
  retinaWidth: number;
  retinaHeight: number;
}

interface IBaseProduct {
  id: ProductID;
  type: ProductType;
  name: string;
  priceInCents: number;
  description: string;
  /**
   * Whether this product is shown as the front-page item.
   */
  featured?: boolean;
  /**
   * Whether a subscription is required for this item. If true, the user
   * will be sent to pick a subscription after adding the item to their cart.
   */
  subscriptionRequired?: boolean;
  freeTrial?: boolean;
  /**
   * If this is a string, it defines a coupon name prefix for coupons that
   * may be applied to this product.
   */
  couponEligible?: boolean | string;
  exchangeOnly?: boolean;
  nonPhysical?: boolean;
  giftable?: boolean;
  checkoutImage?: IImage;
  /**
   * Higher = higher priority
   */
  checkoutImagePriority?: number;
}

export type FiEventName =
  | "Collar page"
  | "Collar page > Color switch"
  | "Collar page > Size switch"
  | "Collar page > Add to bag"
  | "Band page"
  | "Band page > Color switch"
  | "Band page > Size switch"
  | "Band page > Add to bag"
  | "GPS page"
  | "GPS page > Change plan"
  | "GPS page > Add to bag"
  | "GPS page > Get it later"
  | "GPS page > Select free"
  | "Cart page"
  | "Cart page > Add band"
  | "Cart page > Continue shopping"
  | "Cart page > Add subscription"
  | "Cart page > Remove all"
  | "Cart page > Remove collar"
  | "Cart page > Remove band"
  | "Cart page > Remove subscription"
  | "Cart page > Checkout"
  | "Cart page > Coupon applied"
  | "Cart page > Coupon error"
  | "Cart page > Coupon removed"
  | "Cart page > Apple pay begin"
  | "Cart page > Apple pay success"
  | "Cart page > Apple pay error"
  | "Cart page > Apple pay cancel"
  | "Checkout page"
  | "Checkout page > Continue Error"
  | "Checkout page > Continue"
  | "Shipping page"
  | "Shipping page > Continue Error"
  | "Shipping page > Continue"
  | "Shipping page > Apple pay begin"
  | "Shipping page > Apple pay success"
  | "Shipping page > Apple pay error"
  | "Shipping page > Apple pay cancel"
  | "Payment page"
  | "Payment page > Payment Error"
  | "Payment page > Payment Success"
  | "Payment page > Apple pay begin"
  | "Payment page > Apple pay success"
  | "Payment page > Apple pay error"
  | "Payment page > Apple pay cancel"
  | "Confirmation Page"
  | "Confirmation Page > Final Confirm"
  | "Confirmation Page > Error"
  | "Thank you page"
  | "Thank you page > Set password success"
  | "Thank you page > Set password failure"
  | "Thank you page > Download clicked"
  | "Base page"
  | "Base page > Add to bag"
  | "Cart page > Remove base"
  | "Gift card page"
  | "Gift card page > Add to bag"
  | "Cart page > Remove gift card"
  | "Gift card page > Change package";

export interface IBaseAnalyticsEvents {
  pageLoad: FiEventName;
  addToBag: FiEventName;
  removed: FiEventName;
}

export interface ISimpleProductAnalyticsEvents extends IBaseAnalyticsEvents {
  productSwitch?: FiEventName;
}

export interface IComplexProductAnalyticsEvents extends IBaseAnalyticsEvents {
  colorSwitch: FiEventName;
  sizeSwitch: FiEventName;
}

export interface ISimpleProduct extends IBaseProduct {
  kind: ProductKind.Simple;
  priceInCents: number;
  sku: string;
  analyticsEvents?: ISimpleProductAnalyticsEvents;
  productImages?: IImage[];
}

export interface IComplexProduct extends IBaseProduct {
  kind: ProductKind.Complex;
  priceInCents: number;
  variants: IVariant[];
  analyticsEvents?: IComplexProductAnalyticsEvents;
}

export interface IGiftCardSubProduct {
  name: string;
  priceInCents: number;
  /**
   * A string depiction of the savings to be displayed, like "5% savings"
   */
  savings?: string;
}

export enum GiftCardCategory {
  fullKit = "fullKit",
  subscription = "subscription"
}

export interface IGiftCardProduct extends IBaseProduct {
  kind: ProductKind.GiftCard;
  type: ProductType.GiftCard;
  sku: string;
  subProducts: IGiftCardSubProduct[];
  analyticsEvents?: ISimpleProductAnalyticsEvents;
  mostPopular?: boolean;
  hasPlan?: boolean;
  nonPhysical: true;
  addSubscriptionSku?: string;
  /**
   * The name that will be used in the yellow banner that's displayed at the top
   * of the screen during the redemption code. Right now this just omits "(Gift)"
   * from the end since that looks weird.
   */
  nameForBanner: string;
  hidden?: boolean;
  /**
   * When trying to find gift card details by looking them up by their dollar
   * amount, also consider these alternative prices to be instances of this gift card.
   */
  alternativePricesInCents?: number[];
  giftCardCategory: GiftCardCategory;
}

export interface ISubscriptionProduct extends ISimpleProduct {
  displayedRate: string;
  displayedRateUnit: string;
  mostPopular: boolean;
  /**
   * A string depiction of the savings to be displayed, like "Save 16%"
   */
  savings?: string;
  /**
   * Renews after *n* years.
   */
  renewalYears?: number;
  /**
   * Free trial for *n* days.
   */
  freeTrialDays?: number;
}

export interface IVariant {
  sku: string;
  options: IVariantOptions;
  productImages?: IImage[];
  checkoutImage?: IImage;
  /**
   * Higher = higher priority
   */
  checkoutImagePriority?: number;
  sizeDescription?: string;
}

export enum ColorOption {
  Yellow = "yellow",
  Gray = "gray",
  Blue = "blue",
  Pink = "pink",
  Black = "black"
}

export enum SizeOption {
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xlarge"
}

export enum CollarTypeOption {
  Standard = "standard",
  Martingale = "martingale"
}

export interface IVariantOptions {
  color: ColorOption;
  size: SizeOption;
  collarType: CollarTypeOption;
}

export interface IECommerceSiteConfig {
  showCoupons?: boolean;
  showApplePay?: boolean;
  showShippingOptions?: boolean;
  enableGiftCards?: boolean;
  refereeRewardProductId?: string;
  blockedOutOfStockSkus?: string[];
  allowPaypalCredit?: boolean;
}

export type ShippingCode =
  | "so-free"
  | "so-2day"
  | "so-overnight"
  | "so-priority_mail";

export interface IShippingOption {
  code: ShippingCode;
  name: string;
  /**
   * Used as a subtitle in Apple Pay.
   *
   * E.g. "Arrives in 5 to 7 days".
   */
  detail: string;
  priceInCents: number;
  /**
   * Whether the shipping option is an expedited shipping option that needs to be refunded
   * in the event of a backorder situation.
   */
  isExpedited: boolean;
  exchangeOnly?: boolean;
  /**
   * An ISO date. If specified, we should display text that "the package will arrive on <day>"
   * in the checkout flow.
   *
   * This is dynamically calculated.
   */
  arrivesOn?: string;
}

export interface EcommerceApiErrorExtensions {
  customerMessage?: string;
}

export const giftCardCookieName = "gift_card_redemption_code";
export const fakeRefereeRewardForPricingCouponCode =
  "fake_referee_reward_for_pricing";
