import React, { FunctionComponent } from "react";
import { ReactElement } from "react";

export interface IOptionButtonProps<OptionType> {
  value: OptionType;
  selected: boolean;
  available: boolean;
  blocked?: boolean;
  onSelect(value: OptionType): void;
}

export interface IBaseOptionButtonOptions<OptionType> {
  classForProps?: (props: IOptionButtonProps<OptionType>) => string | undefined;
  contentsForProps?: (
    props: IOptionButtonProps<OptionType>
  ) => React.ReactNode | undefined;
}

export const BaseOptionButton = <OptionType extends unknown>(
  args: IBaseOptionButtonOptions<OptionType>
): FunctionComponent<IOptionButtonProps<OptionType>> => {
  return (props: IOptionButtonProps<OptionType>): ReactElement => {
    const classes = args.classForProps?.(props);
    const label = args.contentsForProps?.(props);
    const handleClick: React.MouseEventHandler<HTMLElement> = _event => {
      if (props.available && !props.selected) {
        props.onSelect(props.value);
      }
    };

    return (
      <div className={classes} onClick={handleClick}>
        {props.blocked ? <del>{label}</del> : label}
      </div>
    );
  };
};
