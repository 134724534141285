import React, { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

function InternalOrExternalLink({
  to,
  children,
  ...otherProps
}: { to: string } & React.HTMLAttributes<any>) {
  const isExternal = to.startsWith("http");
  if (isExternal) {
    return (
      <a href={to} {...otherProps}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} {...otherProps}>
        {children}
      </Link>
    );
  }
}

interface DropdownMenuItem {
  name: string;
  icon: string;
  to: string;
}

interface DropdownMenuProps {
  title: string;
  defaultTo: string;
  extraClass?: string;
  items: DropdownMenuItem[];
  dismissMobileMenu(): void;
}

export default function DropdownMenu({
  title,
  defaultTo,
  extraClass,
  items,
  dismissMobileMenu
}: DropdownMenuProps) {
  const [hover, setHover] = useState(false);
  const hideMenu = () => setHover(false);

  return (
    <span
      className={classNames({ dropdown_trigger: true, hover })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <InternalOrExternalLink
        className="nav__link nav__link--active"
        to={defaultTo}
        onClick={() => dismissMobileMenu()}
      >
        {title}
        <img
          className="drop_arrow hidden-sm-down"
          src="/dropdown_icon.svg"
          alt="Drop down arrow"
        />
      </InternalOrExternalLink>
      <div className={classNames("dropdown", "hidden-sm-down", extraClass)}>
        <hr />
        <div className="dropdown_inner">
          {items.map((item, i) => (
            <InternalOrExternalLink
              key={i}
              className="nav__link"
              to={item.to}
              onClick={() => hideMenu()}
            >
              <span className="dp_icon">
                <img src={item.icon} alt="Navigation icon" />
              </span>
              {item.name}
            </InternalOrExternalLink>
          ))}
        </div>
      </div>
    </span>
  );
}
