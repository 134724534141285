import { ICart } from "../../types";
import store from "../store";
import { getProductsBySku, isSubscriptionProduct } from "../util";

interface CartInformation {
  purchasedCollar: boolean;
  purchasedBand: boolean;
  revenueIncludingFreeTrials: number;
  /**
   * Maximum number of years of any subscription in the cart.
   */
  maxYears: number;
}

export function getCartInformation(cart: ICart): CartInformation | null {
  let maxYears = 0;
  let revenueIncludingFreeTrials = parseFloat(cart.summary.total);
  if (isNaN(revenueIncludingFreeTrials)) {
    return null;
  }
  const products = store.getState().config.products;
  const productsBySku = getProductsBySku(products);
  let purchasedCollar = false;
  let purchasedBand = false;
  for (const lineItem of Object.values(cart.lineItems)) {
    const product = productsBySku.get(lineItem.sku);
    if (product) {
      if (isSubscriptionProduct(product)) {
        if (product.renewalYears) {
          maxYears = Math.max(maxYears, product.renewalYears);
        }
        if (product.freeTrial) {
          revenueIncludingFreeTrials += product.priceInCents / 100;
        }
      }
      if (product.id === "smart-collar") {
        purchasedCollar = true;
      }
      if (product.id === "additional-band") {
        purchasedBand = true;
      }
    }
  }

  return {
    purchasedCollar,
    purchasedBand,
    revenueIncludingFreeTrials,
    maxYears
  };
}

// Variables for Google Tag Manager.
const ORDER_ID_VARIABLE_NAME = `fi__orderIdForConversionTracking`;
const ORDER_TOTAL_VARIABLE_NAME = `fi__orderTotalForConversionTracking`;

export function setGlobalVariablesForConversionTracking(
  orderId: string,
  cart: ICart
) {
  const cartInformation = getCartInformation(cart);
  if (cartInformation && cartInformation.revenueIncludingFreeTrials) {
    (window as any)[ORDER_ID_VARIABLE_NAME] = orderId;
    (window as any)[ORDER_TOTAL_VARIABLE_NAME] =
      cartInformation.revenueIncludingFreeTrials;
  }
}

export function clearGlobalVariablesForConversionTracking() {
  delete (window as any)[ORDER_ID_VARIABLE_NAME];
  delete (window as any)[ORDER_TOTAL_VARIABLE_NAME];
}
