import React, { useState, useContext, useEffect } from "react";

import * as events from "../../../../lib/analytics/events";
import { IBillingInfo } from "../../../../types";
import EditBillingInfo, {
  BillingInfoMutation
} from "../../../../components/EditBillingInfo";

import ViewBillingInfo from "./ViewBillingInfo";
import ErrorComponent from "../../../../components/ErrorComponent";
import CheckoutContext, { setBillingInfo } from "../../CheckoutContext";

interface IBillingInfoProps {
  mutation: BillingInfoMutation;
  billingInfo: IBillingInfo | null;
  error: string | undefined;
  errorID: string | undefined;
}

export default function BillingInfo({
  mutation,
  error,
  errorID,
  billingInfo: billingInfoFromAPI
}: IBillingInfoProps) {
  const { checkoutState, checkoutDispatch } = useContext(CheckoutContext);

  const onComplete = (newBillingInfo: IBillingInfo): void => {
    checkoutDispatch(setBillingInfo(newBillingInfo));
  };

  const billingInfo = billingInfoFromAPI ?? checkoutState.billingInfo ?? null;

  const [isEditing, setIsEditing] = useState(billingInfo === null);

  useEffect(() => {
    setIsEditing(billingInfo === null);
  }, [billingInfo]);

  const onEdit = () => setIsEditing(true);

  return (
    <div className="billing-section">
      {error && (
        <ErrorComponent
          error={error}
          trackError={message => events.payment.continueError(message)}
          errorID={errorID}
        />
      )}
      {!isEditing && billingInfo ? (
        <ViewBillingInfo
          billingInfo={billingInfo}
          onEdit={onEdit}
          onComplete={onComplete}
        />
      ) : (
        <EditBillingInfo
          shippingAddress={checkoutState.shippingAddress}
          mutation={mutation}
          onComplete={onComplete}
        />
      )}
    </div>
  );
}
