import * as types from "../types";
import { CheckoutSection } from "./CheckoutSections";

export default interface ICheckoutState {
  orderCart: types.ICart;
  shippingAddress: types.IAddress | undefined;
  billingInfo: types.IBillingInfo | undefined;
  orderID: string | undefined;
  shippingCode: string | undefined;
  customerName: string | undefined;
  userOverridingViewToSection?: CheckoutSection;
}

export function defaultCheckoutState(cart: types.ICart): ICheckoutState {
  return {
    shippingAddress: undefined,
    billingInfo: undefined,
    orderID: undefined,
    shippingCode: undefined,
    userOverridingViewToSection: undefined,
    orderCart: cart,
    customerName: undefined
  };
}
