import React, { useMemo, useState } from "react";

import * as types from "../../../../types";

import SizeOptionButton from "../OptionButtons/SizeOptionButton";
import ColorOptionButton from "../OptionButtons/ColorOptionButton";
import CollarTypeOptionButton from "../OptionButtons/CollarTypeOptionButton";
import { variantWithOptions } from "../../ProductProps";
import SizingGuide from "../SizingGuide";

export interface IProductOptionsProps {
  product: types.IComplexProduct;
  selectedVariant: types.IVariant;
  blockedSKUs?: string[];
  onOptionChange: (changed: Partial<types.IVariantOptions>) => void;
}

const ProductOptions = ({
  product,
  selectedVariant,
  onOptionChange,
  blockedSKUs
}: IProductOptionsProps) => {
  const [showSizingChart, setShowSizingChart] = useState(false);

  const { availableColors, availableSizes, availableStyles } = useMemo(() => {
    const allVariants = product.variants;

    const allStyles = new Set<types.CollarTypeOption>();
    allVariants.forEach(v => allStyles.add(v.options.collarType));

    const allSizes = new Set<types.SizeOption>();
    allVariants.forEach(v => allSizes.add(v.options.size));

    const availableSizes: Array<{
      size: types.SizeOption;
      isBlocked: boolean;
    }> = Array.from(allSizes).map(size => {
      const thisOption = {
        ...selectedVariant.options,
        size
      };

      const variantForSize = variantWithOptions(product, thisOption);

      return {
        size,
        isBlocked:
          !variantForSize || (blockedSKUs ?? []).includes(variantForSize.sku)
      };
    });

    const availableColors = allVariants.flatMap(v => {
      // Eliminate all colors not available in this selected size or type
      if (
        v.options.size !== selectedVariant.options.size ||
        v.options.collarType !== selectedVariant.options.collarType
      ) {
        return [];
      }

      return [v.options.color];
    });

    return {
      availableSizes,
      availableColors,
      availableStyles: Array.from(allStyles)
    };
  }, [product, selectedVariant, blockedSKUs]);

  const includeStyles = availableStyles.length > 1;

  const styleVariations = useMemo(
    () =>
      availableStyles.map(style => {
        const selected = style === selectedVariant.options.collarType;

        return (
          <CollarTypeOptionButton
            key={style}
            value={style}
            blocked={false}
            selected={selected}
            available={true}
            onSelect={collarType => onOptionChange({ collarType })}
          />
        );
      }),
    [availableStyles, onOptionChange, selectedVariant.options.collarType]
  );

  const sizeVariations = useMemo(
    () =>
      availableSizes.map(
        ({
          size,
          isBlocked
        }: {
          size: types.SizeOption;
          isBlocked: boolean;
        }) => {
          const selected = size === selectedVariant.options.size;

          return (
            <SizeOptionButton
              key={size}
              value={size}
              blocked={isBlocked}
              selected={selected}
              available={true}
              onSelect={size => onOptionChange({ size })}
            />
          );
        }
      ),
    [availableSizes, onOptionChange, selectedVariant.options.size]
  );

  const colorVariations = useMemo(
    () =>
      availableColors.map(color => {
        const selected = color === selectedVariant.options.color;

        return (
          <ColorOptionButton
            key={color}
            value={color}
            selected={selected}
            available={true}
            onSelect={color => onOptionChange({ color })}
          />
        );
      }),
    [availableColors, onOptionChange, selectedVariant.options.color]
  );

  return (
    <>
      {showSizingChart && (
        <SizingGuide
          onClose={() => {
            setShowSizingChart(false);
          }}
          onSelect={(size: types.SizeOption) => {
            onOptionChange({ size });
          }}
        />
      )}
      {includeStyles && (
        <div className="variant">
          <div className="variant__title">Collar Type</div>
          <div className="variant__type-container">
            <div className="variant__options">{styleVariations}</div>
          </div>
        </div>
      )}
      <div className="variant">
        <div className="variant__title">Select Size</div>
        <div className="variant__size-container">
          <div className="variant__options">{sizeVariations}</div>
          {selectedVariant.sizeDescription && (
            <div className="variant__size-description">
              {selectedVariant.sizeDescription}
            </div>
          )}
          <div className="variant__size-chart">
            <button
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "auto" });
                setShowSizingChart(true);
              }}
              className="button"
            >
              Unsure? See our sizing chart &rarr;
            </button>
          </div>
        </div>
      </div>
      <div className="variant">
        <div className="variant__title">
          Band Color: {selectedVariant.options.color}
        </div>
        <div className="variant__options">{colorVariations}</div>
      </div>
    </>
  );
};

export default ProductOptions;
