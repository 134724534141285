export default class ApplePayPaymentState {
  selectedShippingCode: string | null;
  country: string | null;
  postalCode: string | null;

  constructor() {
    this.selectedShippingCode = null;
    this.country = null;
    this.postalCode = null;
  }

  clear() {
    this.selectedShippingCode = null;
    this.country = null;
    this.postalCode = null;
  }

  updateFromShippingContactSelected(
    evt: ApplePayJS.ApplePayShippingContactSelectedEvent
  ) {
    this.country = evt.shippingContact.countryCode || null;
    this.postalCode = evt.shippingContact.postalCode || null;

    // Changing the shipping contact re-sets the shipping method (but doesn't call that callback).
    this.selectedShippingCode = null;
  }

  updateFromShippingMethodSelected(
    evt: ApplePayJS.ApplePayShippingMethodSelectedEvent
  ) {
    this.selectedShippingCode = evt.shippingMethod.identifier || null;
  }

  toAddress(): { country: string; postal_code: string } | null {
    return this.country && this.postalCode
      ? {
          country: this.country,
          postal_code: this.postalCode
        }
      : null;
  }
}
