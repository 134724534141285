import EventEmitter from "eventemitter3";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

import * as authentication from "../../lib/fi-api/authentication";
import client from "../../lib/fi-api/client";
import { createPurchaseMutation, mapCartToVariables } from "../../lib/purchase";
import RecurlyProvider, {
  IRecurlyApplePay,
  ShippingContactField
} from "../../lib/RecurlyProvider";
import {
  deepEquals,
  centsToDollars,
  isExpeditedShippingAvailable,
  getCustomerMessageFromApolloError,
  cartHasAllNonPhysicalProducts,
  UserFacingError
} from "../../lib/util";
import * as types from "../../types";
import { gqlTypes } from "../../types/gql";
import { IWindow } from "../../lib/Window";
import { applyCartToPricing, applyTaxesForCheckout } from "../../lib/pricing";
import { IApplePayEvents } from "./Events";
import FinishedRedirect from "./FinishedRedirect";
import ApplePayPaymentState from "./ApplePayPaymentState";
import { AccountExistsError, ApplePayCancelledError } from "./Errors";

interface IApplePayCheckoutProps extends RouteComponentProps {
  cart: types.ICart;
  events: IApplePayEvents;
  shippingOptions: types.IShippingOption[] | null;
  nonPhysicalCheckout: boolean;
}

interface IApplePayCheckoutState {
  loading: boolean;
  finished: boolean;
  accountExists: boolean;
  orderId?: string;
  orderedShippingCode?: string;
}

class ApplePayCheckout extends React.Component<
  IApplePayCheckoutProps,
  IApplePayCheckoutState
> {
  public static requiredBasicContactFields: ShippingContactField[] = [
    "email",
    "name"
  ];
  public static requiredContactFieldsForShipping: ShippingContactField[] = [
    ...ApplePayCheckout.requiredBasicContactFields,
    "phone",
    "postalAddress"
  ];
  public static applePayLabel = "Fi Collars";

  private applePay?: IRecurlyApplePay;
  private _isMounted: boolean;
  private pricing: Recurly.CheckoutPricing;
  private provider: RecurlyProvider;
  private eventBus: EventEmitter<"applePayPaymentAuthorized">;
  private paymentState: ApplePayPaymentState;

  constructor(props: IApplePayCheckoutProps) {
    super(props);
    this._isMounted = false;
    this.provider = new RecurlyProvider();
    this.pricing = this.provider.value.Pricing.Checkout();
    this.eventBus = new EventEmitter();
    // TODO: I think this should go in React state.
    this.paymentState = new ApplePayPaymentState();
    this.state = {
      loading: false,
      finished: false,
      accountExists: false
    };
  }

  get addressRequired() {
    return !this.props.nonPhysicalCheckout;
  }

  public componentWillReceiveProps(prevProps: IApplePayCheckoutProps) {
    const toCompareObject = (props: IApplePayCheckoutProps) => ({
      lineItems: props.cart.lineItems,
      couponDetails: props.cart.couponDetails
    });
    if (
      this._isMounted &&
      !deepEquals(toCompareObject(this.props), toCompareObject(prevProps))
    ) {
      // Update pricing when the cart is changed.
      this.updatePricing();
    }
  }

  public async componentDidMount() {
    this._isMounted = true;
    await this.updatePricing();
    this.createApplePay();
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    if (this.state.accountExists) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            search: "?returnTo=/bag",
            state: { applePayError: true }
          }}
        />
      );
    }

    return (
      <>
        <div
          className="apple-pay-button apple-pay-button-black"
          onClick={this.onSubmit}
        />
        {this.state.finished && (
          <FinishedRedirect
            orderedCart={this.props.cart}
            orderID={this.state.orderId}
            shippingCode={this.state.orderedShippingCode}
          />
        )}
      </>
    );
  }

  private async updatePricing() {
    await this.pricing.reset();
    await applyCartToPricing(this.props.cart, this.pricing, {
      address: this.paymentState.toAddress() || undefined,
      shippingCode: this.paymentState.selectedShippingCode || undefined
    });
    await this.pricing.reprice();
    await applyTaxesForCheckout(
      client,
      this.pricing,
      this.props.cart,
      this.paymentState.selectedShippingCode
    );
  }

  private getShippingMethods(): ApplePayJS.ApplePayShippingMethod[] {
    return this.props.shippingOptions !== null
      ? this.props.shippingOptions.map(so => ({
          amount: centsToDollars(so.priceInCents),
          label: so.name,
          identifier: so.code,
          detail: so.detail
        }))
      : [];
  }

  /**
   * Create and instrument the Recurly ApplePay object. This overrides some private
   * methods in that object since Recurly provides a somewhat limited API that
   * doesn't entirely suit our purposes.
   *
   * See https://github.com/recurly/recurly-js/blob/master/lib/recurly/apple-pay.js
   */
  private createApplePay() {
    const applePay = this.provider.value.ApplePay({
      country: "US",
      pricing: this.pricing,
      requiredShippingContactFields: this.addressRequired
        ? ApplePayCheckout.requiredContactFieldsForShipping
        : ApplePayCheckout.requiredBasicContactFields,
      currency: "USD",
      label: ApplePayCheckout.applePayLabel
    });

    if (
      applePay.onPaymentAuthorized &&
      applePay.onShippingContactSelected &&
      applePay.onShippingMethodSelected
    ) {
      // Required to get the shipping information from the apple pay result.
      const oldOnPaymentAuthorized = applePay.onPaymentAuthorized;
      applePay.onPaymentAuthorized = evt => {
        this.eventBus.emit("applePayPaymentAuthorized", evt);
        oldOnPaymentAuthorized.call(this.applePay, evt);
      };

      // Update pricing and shipping methods when the shipping contact changes.
      applePay.onShippingContactSelected = async evt => {
        // NOTE: We get redacted shipping information in this callback, not the full information.
        // From the docs: "The redacted information includes only the necessary data for completing
        // transaction tasks, such as calculating taxes or shipping costs."

        this.paymentState.updateFromShippingContactSelected(evt);
        await this.updatePricing();

        const newShippingMethods = this.getShippingMethods();
        const extendedWindow: IWindow = window;
        applePay.session.completeShippingContactSelection(
          extendedWindow.ApplePaySession!.STATUS_SUCCESS,
          newShippingMethods,
          applePay.finalTotalLineItem,
          applePay.lineItems
        );
      };

      applePay.onShippingMethodSelected = async evt => {
        this.paymentState.updateFromShippingMethodSelected(evt);
        await this.updatePricing();

        const extendedWindow: IWindow = window;
        // TODO: Once https://github.com/recurly/recurly-js/issues/528 is fixed,
        // we can delegate to the underlying Recurly JS onShippingMethodSelected().
        applePay.session.completeShippingMethodSelection(
          extendedWindow.ApplePaySession!.STATUS_SUCCESS,
          applePay.finalTotalLineItem,
          applePay.lineItems
        );
      };

      this.applePay = applePay;
    }
  }

  private onSubmit = async () => {
    if (this.state.loading || this.state.finished) {
      return;
    } else if (!this.applePay) {
      this.onError(new Error(`Apple Pay not initialized`));
    } else {
      this.setState({ loading: true });
      const applePay = this.applePay;
      const paymentAuthorizedEventPromise = new Promise<
        ApplePayJS.ApplePayPaymentAuthorizedEvent
      >(resolve => {
        this.eventBus.once("applePayPaymentAuthorized", evt => resolve(evt));
      });
      const tokenPromise = new Promise<{ id: string }>((resolve, reject) => {
        applePay.on("token", token => resolve(token));
        applePay.on("error", err => reject(err));
        applePay.on("cancel", () => reject(new ApplePayCancelledError()));
      });
      applePay.begin();
      this.props.events.applePayBegin();
      try {
        const [token, paymentAuthorizedEvent] = await Promise.all([
          tokenPromise,
          paymentAuthorizedEventPromise
        ]);

        const shippingContact = paymentAuthorizedEvent.payment.shippingContact;
        if (!shippingContact) {
          throw new Error(`A shipping address is required`);
        }
        const shippingCode = this.paymentState.selectedShippingCode;
        const expeditedShippingAvailable = isExpeditedShippingAvailable({
          line1:
            (shippingContact.addressLines && shippingContact.addressLines[0]) ||
            ""
        });
        const shippingOption = (this.props.shippingOptions || []).find(
          so => so.code === shippingCode
        );
        if (
          !expeditedShippingAvailable &&
          shippingOption &&
          shippingOption.isExpedited
        ) {
          throw new UserFacingError(
            `Sorry, expedited shipping is not available to P.O. Box addresses. Please try again with ground shipping or contact support@tryfi.com with any questions.`
          );
        }

        const wasLoggedIn = await authentication.applePay(
          token.id,
          shippingContact
        );
        if (!wasLoggedIn) {
          throw new AccountExistsError();
        }
        const createPurchaseVariables = mapCartToVariables(
          this.props.cart,
          shippingCode
        );
        const result = await client.mutate<gqlTypes.createPurchase>({
          mutation: createPurchaseMutation,
          variables: createPurchaseVariables
        });
        const data: gqlTypes.createPurchase = result.data!;
        const orderId = data.createPurchase.invoiceNumber;
        this.props.events.applePaySuccess();
        this.setState({
          finished: true,
          orderId,
          orderedShippingCode: shippingCode || undefined
        });
      } catch (e) {
        if (e instanceof AccountExistsError) {
          this.props.events.applePayError(`Account already exists`);
          this.setState({ accountExists: true });
        } else if (e instanceof ApplePayCancelledError) {
          this.props.events.applePayCancel();
        } else {
          this.onError(e);
        }
      } finally {
        this.setState({ loading: false });
        applePay.off();
        this.paymentState.clear();
        this.eventBus.off("applePayPaymentAuthorized");
      }
    }
  };

  private onError = (err: Error) => {
    this.props.events.applePayError(err.message);
    const customerMessage = getCustomerMessageFromApolloError(err);
    let message = `An unknown error occurred. Please try again, or contact support@tryfi.com if the problem persists.`;
    if (err instanceof UserFacingError) {
      message = err.message;
    } else if (customerMessage) {
      message = `Failed to process purchase: ${customerMessage}. Please try again, or contact support@tryfi.com if the problem persists.`;
    }
    // TODO: Update to use new modal from Moe instead of ugly window.alert.
    window.alert(message);
  };
}

const mapStateToProps = (state: types.IAppState) => {
  const nonPhysicalCheckout = cartHasAllNonPhysicalProducts(
    state.cart,
    state.config.products
  );
  return {
    cart: state.cart,
    shippingOptions: state.config.siteConfig.showShippingOptions
      ? state.config.shippingOptions
      : null,
    nonPhysicalCheckout
  };
};

export default withRouter(connect(mapStateToProps)(ApplePayCheckout));
