// THIS FILE AUTOMATICALLY GENERATED BY yarn download-schema. Do not modify.

export default {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "BasePet",
        possibleTypes: [
          {
            name: "Pet"
          },
          {
            name: "StrangerPet"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Activity",
        possibleTypes: [
          {
            name: "Walk"
          },
          {
            name: "Rest"
          },
          {
            name: "Travel"
          },
          {
            name: "MissingReports"
          },
          {
            name: "Play"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "OngoingActivity",
        possibleTypes: [
          {
            name: "OngoingRest"
          },
          {
            name: "OngoingWalk"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Card",
        possibleTypes: [
          {
            name: "SimpleCard"
          },
          {
            name: "WalkCard"
          },
          {
            name: "LabeledCard"
          }
        ]
      },
      {
        kind: "UNION",
        name: "AnyReport",
        possibleTypes: [
          {
            name: "LocationReport"
          },
          {
            name: "MobilityReport"
          },
          {
            name: "ActivityReport"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "TEvent",
        possibleTypes: [
          {
            name: "TLocationReportEvent"
          },
          {
            name: "TMobilityReportEvent"
          },
          {
            name: "TActivityReportEvent"
          },
          {
            name: "TOperationParamsChangeEvent"
          },
          {
            name: "TNotificationSendEvent"
          },
          {
            name: "TCollapsedEvents"
          },
          {
            name: "TModemRegistrationFailuresEvent"
          },
          {
            name: "TDeviceRestartEvent"
          },
          {
            name: "TStatsChangedEvent"
          },
          {
            name: "TDeviceInfoEvent"
          },
          {
            name: "TConnectionHistoryEvent"
          },
          {
            name: "TFirmwareUpdateEvent"
          },
          {
            name: "TLedEvent"
          },
          {
            name: "TBaseLogEvent"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "ConnectionState",
        possibleTypes: [
          {
            name: "ConnectedToUser"
          },
          {
            name: "ConnectedToBase"
          },
          {
            name: "ConnectedToCellular"
          },
          {
            name: "UnknownConnectivity"
          }
        ]
      },
      {
        kind: "UNION",
        name: "GPSTestResult",
        possibleTypes: [
          {
            name: "GPSTestCompleteResult"
          },
          {
            name: "GPSTestConnectionStolenResult"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "FeatureFlag",
        possibleTypes: [
          {
            name: "StringFeatureFlag"
          },
          {
            name: "NumberFeatureFlag"
          },
          {
            name: "BooleanFeatureFlag"
          }
        ]
      },
      {
        kind: "UNION",
        name: "BooleanSetting",
        possibleTypes: [
          {
            name: "TrueBooleanSetting"
          },
          {
            name: "FalseBooleanSetting"
          },
          {
            name: "GroupsBooleanSetting"
          },
          {
            name: "UserIdsBooleanSetting"
          },
          {
            name: "PercentageOfUsersBooleanSetting"
          },
          {
            name: "RandomPercentageBooleanSetting"
          }
        ]
      }
    ]
  }
};
