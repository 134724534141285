import React, { useMemo } from "react";
import { gqlTypes } from "../../types/gql";
import { useQuery } from "react-apollo";
import { getLoadingOrErrorElement, expectUnreachable } from "../../lib/util";
import { useLocation } from "react-router-dom";
import {
  RescuePlacementModel,
  rescuePlacementMainQuery,
  RescuePlacementNavigationState,
  defaultRescuePlacementNavigationState
} from "./rescuePlacementUtils";
import PetSelector from "./PetSelector";
import PetPlacement from "./PetPlacement";
import PetRecall from "./PetRecall";
import PetTemporaryPlacement from "./PetTemporaryPlacement";
import PetPermanentPlacement from "./PetPermanentPlacement";
import NotFound from "../../components/NotFound";

export default function RescuePlacement() {
  const { loading, error, data } = useQuery<gqlTypes.rescuePlacementMain>(
    rescuePlacementMainQuery
  );
  const location = useLocation();
  const model = useMemo(
    () => (data ? new RescuePlacementModel(data) : undefined),
    [data]
  );

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const navigationState: RescuePlacementNavigationState = {
    ...defaultRescuePlacementNavigationState,
    ...location.state
  };

  let body: JSX.Element | undefined;
  if (navigationState.placementView === "petSelector") {
    body = <PetSelector model={model!} />;
  } else if (navigationState.placementView === "petPlacement") {
    body = <PetPlacement model={model!} petId={navigationState.petId} />;
  } else if (navigationState.placementView === "petTemporaryPlacement") {
    body = (
      <PetTemporaryPlacement model={model!} petId={navigationState.petId} />
    );
  } else if (navigationState.placementView === "petPermanentPlacement") {
    body = (
      <PetPermanentPlacement model={model!} petId={navigationState.petId} />
    );
  } else if (navigationState.placementView === "petRecall") {
    body = <PetRecall model={model!} petId={navigationState.petId} />;
  } else {
    expectUnreachable(navigationState);
    body = <NotFound />;
  }

  return <div className="rescue-placement-container">{body}</div>;
}
