import React from "react";
import { gqlTypes } from "../../types/gql";
import classNames from "classnames";

interface PetViewProps {
  pet: gqlTypes.petDetails;
  title: string;
  onClick?(): void;
}
export default function PetView({ pet, onClick, title }: PetViewProps) {
  const avatarUrl =
    (pet.avatarUrl && pet.avatarUrl.fullSize) || `/dog_placeholder.png`;
  return (
    <div
      className={classNames("pet-view", onClick && "clickable")}
      onClick={onClick}
    >
      <img src={avatarUrl} width="40" alt="Pet avatar" />
      <h4 className="pet-title">{title}</h4>
      <h4 className="text--highlight pet-arrow">&gt;</h4>
    </div>
  );
}
