import React from "react";
import Popup from "reactjs-popup";

interface ISimpleDismissableModalProps {
  trigger: JSX.Element;
  children: string | JSX.Element;
  onOpen?(): void;
}

export function SimpleDismissableModal({
  trigger,
  children,
  onOpen
}: ISimpleDismissableModalProps) {
  return (
    <Popup
      trigger={trigger}
      onOpen={onOpen}
      modal
      contentStyle={{ padding: "0", maxWidth: "550px" }}
      closeOnDocumentClick={false}
    >
      {close => (
        <div>
          <div className="popup-body">{children}</div>
          <div className="popup-actions">
            <div onClick={close}>
              <span>Close</span>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}

interface IConfirmModalProps {
  trigger: JSX.Element;
  body: string | JSX.Element;
  onConfirm(): void;
}

/**
 * An "OK/Cancel" modal.
 */
export function ConfirmModal({ trigger, body, onConfirm }: IConfirmModalProps) {
  return (
    <Popup
      trigger={trigger}
      modal
      contentStyle={{ padding: "0", maxWidth: "550px" }}
      closeOnDocumentClick={false}
    >
      {close => (
        <div>
          <div className="popup-body">{body}</div>
          <div className="popup-actions">
            <div onClick={close}>
              <span>Cancel</span>
            </div>
            <div
              className="popup-primary-action"
              onClick={() => {
                close();
                onConfirm();
              }}
            >
              <span>OK</span>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}
