import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

interface IShoppingBagProps {
  count: number;
}

function ShoppingBagIcon({ count }: IShoppingBagProps) {
  return (
    <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 5l1 16H0L1 5h4a5 5 0 1 1 10 0h4zm-9-3C8.346 2 7 3.346 7 5h6c0-1.654-1.346-3-3-3z"
        fill="#FFFF01"
        fillRule="evenodd"
      />
      <text textAnchor="middle" x="10" y="18" fill="#000000" fontSize="14">
        {count}
      </text>
    </svg>
  );
}

function EmptyShoppingBagIcon() {
  return (
    <svg viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Bag-2/Empty" fill="#FFFFFF" fillRule="nonzero">
          <g id="Combined-Shape-2">
            <path
              d="M19,0 C24.1557333,0 28.3504936,4.01352293 28.4960913,9.01675446 L28.5,9.28571429 L36.1,9.28571429 L38,38 L0,38 L1.9,9.28571429 L9.5,9.28571429 C9.5,4.24810025 13.6043088,0.146198127 18.7247369,0.00382224876 L19,0 Z M19,3.5 C15.7440319,3.5 13.1216296,5.99126447 13.0041112,9.06999025 L13,9.28571429 L13,12.7857143 L5.176,12.785 L3.739,34.5 L34.26,34.5 L32.823,12.785 L25.0504951,12.7857143 L24.9975723,9.11856392 C24.9071966,6.01294596 22.266856,3.5 19,3.5 Z M9,9.3 L28,9.3 L28,12.8 L9,12.8 L9,9.3 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

function ShoppingBag({ count }: IShoppingBagProps) {
  const [shownCount, setShownCount] = useState(count);
  const [growing, setGrowing] = useState(false);
  const [shrinking, setShrinking] = useState(false);

  const iconDiv = React.createRef<HTMLDivElement>();

  // Called when either the growing or shrinking animations end.
  // Either way, makes sure we're showing the right number, that we're
  // shrinking if we were growing previously, and that we're not growing.
  const handleAnimationEnd = useCallback(() => {
    setShownCount(count);
    setShrinking(growing);
    setGrowing(false);
  }, [count, growing]);

  // Respond to a change in the count, possibly with an animation!
  useEffect(() => {
    // We added a thing to the bag
    if (count > shownCount) {
      setShrinking(false);
      setGrowing(true);
    } else if (count < shownCount) {
      setShownCount(count);
    }
  }, [count, shownCount]);

  // Attach a listener to the shopping bag icon to handle animation ends
  useEffect(() => {
    if (iconDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = iconDiv.current;
      element.addEventListener("animationend", eventListener);
      return () => {
        element.removeEventListener("animationend", eventListener);
      };
    }
  }, [iconDiv, growing, handleAnimationEnd]);

  const iconClasses = classnames([
    "shopping-bag__icon",
    "shopping-bag__icon",
    { growing, shrinking }
  ]);

  return (
    <Link className="shopping-bag nav__link" to="/bag">
      <div className={iconClasses} ref={iconDiv}>
        {shownCount <= 0 ? (
          <EmptyShoppingBagIcon />
        ) : (
          <ShoppingBagIcon count={shownCount} />
        )}
      </div>
      <div className="hidden-sm-down">Bag</div>
    </Link>
  );
}

export default ShoppingBag;
