import gql from "graphql-tag";

export const updateShippingAddress = gql`
  mutation updateShippingAddress($address: AddressInput!) {
    updateBillingAccount(input: { address: $address }, trackEvent: true) {
      address {
        line1
        line2
        city
        state
        zip
        country
        phone
      }
    }
  }
`;
