import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Checkout from "../views/Checkout";
import * as types from "../types";

interface ICheckoutContainerProps {
  session: types.ISession | undefined;
  cart: types.ICart;
  products: types.IProduct[];
  shippingOptions: types.IShippingOption[];
  showShippingOptions: boolean;
}

function CheckoutContainer({
  session,
  cart,
  products,
  shippingOptions,
  showShippingOptions
}: ICheckoutContainerProps) {
  if (Object.keys(cart.lineItems).length < 1) {
    return <Redirect to="/" />;
  }

  return (
    <Checkout
      cart={cart}
      products={products}
      session={session}
      shippingOptions={showShippingOptions ? shippingOptions : []}
    />
  );
}

const mapStateToProps = (state: types.IAppState): ICheckoutContainerProps => {
  return {
    session: state.session ?? undefined,
    cart: state.cart,
    products: state.config.products,
    shippingOptions: state.config.shippingOptions,
    showShippingOptions: !!state.config.siteConfig.showShippingOptions
  };
};

export default connect(mapStateToProps)(CheckoutContainer);
