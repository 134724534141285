import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

import {
  LineItemID,
  IGiftCardDelivery,
  IVariant,
  IGiftCardProduct
} from "../../types";
import RemoveButton from "../RemoveButton";
import GiftCardInformation from "./GiftCardInformation";
import { Image } from "../Image";

interface IGiftCardLineItemProps {
  id: LineItemID;
  product?: IGiftCardProduct;
  variant?: IVariant;
  price: string;
  giftCardDelivery: IGiftCardDelivery;
  removeLineItem?(id: LineItemID): void;
}

export default function GiftCardLineItem({
  id,
  product,
  variant,
  price,
  giftCardDelivery,
  removeLineItem
}: IGiftCardLineItemProps) {
  const handleRemoveLineItem = useCallback(() => {
    if (removeLineItem) {
      removeLineItem(id);
    }
  }, [removeLineItem, id]);

  const productImage = useMemo(() => {
    return product?.checkoutImage;
  }, [product]);

  return (
    <div
      className={classNames("split-container", "line-item", "gift-card-item")}
    >
      {productImage && (
        <div className="product-image">
          <Image image={productImage} desiredWidth={100} />
        </div>
      )}
      <div className="line-item-body">
        <div className="line-item-details">
          <div className="product-name">
            <h4>eGift Card</h4>
          </div>
          <div className="sku-details">{product?.nameForBanner}</div>
          <GiftCardInformation delivery={giftCardDelivery} />
        </div>

        <div className="product-pricing">
          <div className="line-item-price">
            <h4>${price}</h4>
          </div>
          {removeLineItem && <RemoveButton onClick={handleRemoveLineItem} />}
        </div>
      </div>
    </div>
  );
}
