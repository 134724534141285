import React from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../../reducers/cart";
import { ConfirmModal } from "../modals";

/**
 * State for when a coupon has been applied to the cart. Displays a CTA to
 * remove the coupon and enter a gift code instead.
 */
export default function PromoCodeCouponApplied({
  showGiftCard
}: {
  showGiftCard(): void;
}) {
  const dispatch = useDispatch();

  const handleConfirm = () => {
    // Remove coupon and switch to gift card mode.
    dispatch(cartActions.clearCoupon());
    showGiftCard();
  };

  return (
    <div>
      <img src="/gift_box_gray.svg" alt="A gift box" />{" "}
      <ConfirmModal
        trigger={
          <span className="link link--underlined">I have a gift card</span>
        }
        body={
          <>
            Coupons do not apply to Gift Card purchases, your coupon will be
            removed from your cart.
          </>
        }
        onConfirm={() => handleConfirm()}
      />
    </div>
  );
}
