import React, { useState } from "react";
import { applyCouponToCart, checkReferralCode } from "../../lib/pricing";
import { logInternalError } from "../../lib/util";
import * as events from "../../lib/analytics/events";
import { IAppState } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../reducers/cart";
import { useHistory } from "react-router-dom";
import PromoCodeForm from "./PromoCodeForm";
import setCouponCookie from "./setCouponCookie";

/**
 * Entry form for a coupon or a referral code.
 */
export default function PromoCodeCouponEntry() {
  const cart = useSelector((state: IAppState) => state.cart);
  const dispatch = useDispatch();
  const canApplyCoupon = !cart.giftCardDetails;
  const history = useHistory();

  const [error, setError] = useState<string | null>(null);

  const refereeRewardProductId = useSelector(
    (state: IAppState) => state.config.siteConfig.refereeRewardProductId
  );

  const handleApply = async (code: string) => {
    // Try to apply as a referral code first.
    const referralResult = await checkReferralCode(code);
    if (referralResult.kind === "success") {
      events.cartPage.referralCodeApplied(referralResult.value.referralCode);
      dispatch(cartActions.addReferral(referralResult.value));
      if (refereeRewardProductId) {
        const cartHasRefereeRewardProduct = Object.values(cart.lineItems).some(
          li => li.productID === refereeRewardProductId
        );
        if (!cartHasRefereeRewardProduct) {
          history.push(`/products/${refereeRewardProductId}`);
        }
      }
      return;
    } else {
      if (
        referralResult.err.type === "unknown" &&
        referralResult.err.underlying
      ) {
        logInternalError(referralResult.err.underlying);
      }
    }

    // Verify that we're allowed to apply a coupon
    if (!canApplyCoupon) {
      const message = "Unable to apply to coupon";
      setError(message);
      events.cartPage.couponError(code, message);
      return;
    }

    // Now try to apply as coupon.
    const result = await applyCouponToCart(cart, code);
    if (result.kind === "failure") {
      setError(result.err.message);
      events.cartPage.couponError(code, result.err.message);
    } else {
      dispatch(cartActions.addCoupon(result.value));
      events.cartPage.couponApplied(result.value.code);
      setCouponCookie(result.value.code);
    }
  };

  let placeholder: string;
  if (cart.couponDetails || !canApplyCoupon) {
    placeholder = `Apply referral code`;
  } else if (cart.referralDetails) {
    placeholder = `Apply discount code`;
  } else {
    placeholder = `Apply discount or referral code`;
  }

  return (
    <PromoCodeForm
      placeholder={placeholder}
      error={error}
      clearError={() => setError(null)}
      onApply={handleApply}
      actionLabel="Apply"
    />
  );
}
