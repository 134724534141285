import EventEmitter from "eventemitter3";
import sleep from "sleep-promise";

import analytics from ".";
import { experimentActions } from "../../reducers/experiment";
import store from "../store";
import * as optimize from "./optimize";

const OPTIMIZE_DOM_WAIT = 170;
const ANALYTICS_TIMEOUT = 3500;
const INITIAL_EXPERIMENT_WAIT = 1000;
const SUBSEQUENT_EXPERIMENT_WAIT = 500;

type AnalyticsState = "uninitialized" | "loading" | "ready" | "finished";

class AnalyticsCoordinator {
  private state: AnalyticsState;
  private readonly bus: EventEmitter<AnalyticsState>;

  constructor() {
    this.state = "uninitialized";
    this.bus = new EventEmitter();
  }

  public on(state: AnalyticsState, fn: () => void) {
    this.bus.on(state, fn);
    return this;
  }

  public async start() {
    analytics.pause();
    analytics.page();
    this.advance("loading");
    const readyPromise = new Promise(resolve => analytics.ready(resolve));
    const didLoad = await Promise.race([
      readyPromise.then(() => true),
      sleep(ANALYTICS_TIMEOUT).then(() => false)
    ]);
    this.advance("ready");
    if (didLoad) {
      // Activate Optimize which should start any necessary DOM modifications.
      optimize.activate();
      optimize.registerCallback(experiments => {
        store.dispatch(experimentActions.addToExperiments({ experiments }));
      });
      await sleep(OPTIMIZE_DOM_WAIT);
    }
    this.advance("finished");
    await sleep(INITIAL_EXPERIMENT_WAIT);
    analytics.unpause();
  }

  public beforePageChange() {
    analytics.pause();
  }

  public async pageChange() {
    optimize.activate();
    analytics.page();
    await sleep(SUBSEQUENT_EXPERIMENT_WAIT);
    analytics.unpause();
  }

  private advance(newState: AnalyticsState) {
    this.state = newState;
    this.bus.emit(this.state);
  }
}

export const analyticsCoordinator = new AnalyticsCoordinator();
