import classNames from "classnames";
import React, { useState } from "react";
import { useMountedRef } from "../../lib/util";

interface IPromoCodeFormProps {
  placeholder: string;
  /**
   * Label for the button used to apply the promo code.
   */
  actionLabel: string;
  onApply(code: string): Promise<void>;
  clearError(): void;
  error: string | null;
  epicButton?: boolean;
}

export default function PromoCodeForm({
  placeholder,
  actionLabel,
  onApply,
  clearError,
  error,
  epicButton
}: IPromoCodeFormProps) {
  const [pendingCodeValue, setPendingCodeValue] = useState("");
  const mountedRef = useMountedRef();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPendingCodeValue(evt.currentTarget.value.trim());
    clearError();
  };

  return (
    <form
      onSubmit={async evt => {
        evt.preventDefault();
        if (pendingCodeValue) {
          try {
            await onApply(pendingCodeValue);
          } finally {
            if (mountedRef.current) {
              setPendingCodeValue("");
            }
          }
        }
      }}
      className={classNames("promo-code-form", {
        "promo-code-form--error": !!error
      })}
    >
      <input
        name="code"
        type="text"
        placeholder={error || placeholder}
        value={error ? "" : pendingCodeValue}
        onChange={handleChange}
      />
      <button
        className={classNames(
          "promo-action",
          "flow-button",
          epicButton ? "flow-button--yellow" : "flow-button--gray"
        )}
      >
        {actionLabel}
      </button>
    </form>
  );
}
