import React, { useState } from "react";
import { IGiftCardProduct } from "../types";
import { useDispatch } from "react-redux";
import { cartActions } from "../reducers/cart";
import { generateID, centsToDollars } from "../lib/util";
import { Redirect } from "react-router-dom";
import { setCouponCookie } from "../components/PromoCode";

interface RescueProps {
  product: IGiftCardProduct;
}

const MAX_CARDS = 99; // Impose a sane limit

export function Rescue({ product }: RescueProps) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    return <Redirect to="/bag" />;
  }

  const handleSubmit = () => {
    const number = parseInt(inputValue);
    if (isNaN(number) || number <= 0 || number > MAX_CARDS) {
      window.alert(
        `Please enter a number greater than 0 and less than ${MAX_CARDS}`
      );
      return;
    }
    if (!emailValue) {
      window.alert(`Please enter an email address`);
      return;
    }
    for (let i = 0; i < number; i++) {
      const itemID = generateID();
      dispatch(
        cartActions.addLineItem({
          id: itemID,
          productID: product.id,
          sku: product.sku,
          type: product.type,
          description: product.name,
          price: centsToDollars(product.priceInCents),
          quantity: 1,
          giftCardDelivery: {
            deliverAt: null,
            emailAddress: emailValue,
            firstName: `Fi Customer`,
            gifterName: `Fi`,
            lastName: null,
            personalMessage: null
          }
        })
      );
      // Set a bogus coupon cookie so that Unbounce modals don't display in the cart.
      setCouponCookie("RESCUEGC");
    }
    setSubmitted(true);
  };

  // Also mark email_captured cookie so that Unbounce modals don't display
  const emailCaptured = (
    <img
      src="https://api.tryfi.com/api/ecommerce/emailcaptured.gif"
      alt=""
      style={{ display: "none" }}
    />
  );

  return (
    <div className="rescue-page">
      {emailCaptured}

      <div className="rescue-hero">
        <img src="/rescue_hero.jpg" alt="A dog" />
      </div>

      <div className="rescue-main split-container">
        <div className="rescue-left">
          <h5>Welcome</h5>
          <h2>Fi Rescues</h2>
          <div>
            <img
              src="/gift_cards_multi.png"
              width="92px"
              alt="Several Fi gift cards"
            />
            <p>
              {" "}
              You can use this page to add any number of gift cards for 1-year
              subscriptions to your cart.
            </p>
          </div>
        </div>
        <div className="rescue-right">
          <div className="rescue-form">
            <div>
              <p>How many cards would you like?</p>
              <input
                type="text"
                placeholder="Enter a number"
                value={inputValue}
                onChange={e => setInputValue(e.currentTarget.value)}
              />
            </div>
            <div>
              <p>Who should we send this to?</p>
              <input
                type="text"
                placeholder="Email to send them to"
                value={emailValue}
                onChange={e => setEmailValue(e.currentTarget.value)}
              />
            </div>
            <div>
              <div className="button button--primary" onClick={handleSubmit}>
                Add to Cart
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
