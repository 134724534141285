import * as React from "react";
import * as types from "../../types";
import { getCartInformation } from "./analyticsUtils";

/* eslint-disable jsx-a11y/iframe-has-title */

type SnippetFn = (props: { revenue: number; orderId: string }) => JSX.Element;

const bandOnlySnippet: SnippetFn = ({ revenue, orderId }) => (
  <iframe
    src={`https://9198923.fls.doubleclick.net/activityi;src=9198923;type=ecmsales;cat=band;qty=1;cost=${revenue};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=${orderId}?`}
    width="1"
    height="1"
    frameBorder="0"
    style={{ display: "none" }}
  />
);

const collarSnippetsBySubscriptionYears: {
  [numYears: string]: SnippetFn;
} = {
  "0": ({ revenue, orderId }) => (
    <iframe
      src={`https://9198923.fls.doubleclick.net/activityi;src=9198923;type=ecmsales;cat=collar;qty=1;cost=${revenue};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=${orderId}?`}
      width="1"
      height="1"
      frameBorder="0"
      style={{ display: "none" }}
    />
  ),
  "1": ({ revenue, orderId }) => (
    <iframe
      src={`https://9198923.fls.doubleclick.net/activityi;src=9198923;type=ecmsales;cat=collar1y;qty=1;cost=${revenue};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=${orderId}?`}
      width="1"
      height="1"
      frameBorder="0"
      style={{ display: "none" }}
    />
  ),
  "2": ({ revenue, orderId }) => (
    <iframe
      src={`https://9198923.fls.doubleclick.net/activityi;src=9198923;type=ecmsales;cat=collar2y;qty=1;cost=${revenue};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=${orderId}?`}
      width="1"
      height="1"
      frameBorder="0"
      style={{ display: "none" }}
    />
  ),
  "3": ({ revenue, orderId }) => (
    <iframe
      src={`https://9198923.fls.doubleclick.net/activityi;src=9198923;type=ecmsales;cat=collar3y;qty=1;cost=${revenue};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=${orderId}?`}
      width="1"
      height="1"
      frameBorder="0"
      style={{ display: "none" }}
    />
  )
};

interface FloodlightIFrameProps {
  cart: types.ICart;
  orderId: string;
}

export default function FloodlightIFrame({
  cart,
  orderId
}: FloodlightIFrameProps) {
  if (
    !(process.env.REACT_APP_ENABLE_TRACKING || "")
      .split(",")
      .includes("floodlight")
  ) {
    // Not including Floodlight in staging environments
    return null;
  }

  const cartInformation = getCartInformation(cart);
  if (!cartInformation) {
    return null;
  }
  const {
    maxYears,
    purchasedCollar,
    purchasedBand,
    revenueIncludingFreeTrials
  } = cartInformation;

  let snippetFn: SnippetFn | undefined;
  if (purchasedCollar) {
    snippetFn = collarSnippetsBySubscriptionYears[maxYears.toString()];
  } else if (purchasedBand) {
    snippetFn = bandOnlySnippet;
  }

  if (snippetFn) {
    return snippetFn({
      revenue: revenueIncludingFreeTrials,
      orderId
    });
  } else {
    return null;
  }
}
