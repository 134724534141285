import ICheckoutState from "./CheckoutState";

export enum CheckoutBreadcrumb {
  shipping = 0,
  payment = 1,
  review = 2
}

export enum CheckoutSection {
  shippingAddress = 0,
  payment = 1,
  shippingOptions = 2,
  confirm = 3,
  thankYou = 4
}

export function sectionForState(
  state: ICheckoutState,
  appState: {
    isLoggedIn: boolean;
    requiresShipment: boolean;
    hasShippingOptions: boolean;
  }
): CheckoutSection {
  if (
    !state.shippingAddress &&
    !(appState.isLoggedIn && !appState.requiresShipment)
  ) {
    return CheckoutSection.shippingAddress;
  } else if (!state.billingInfo) {
    return CheckoutSection.payment;
  } else if (
    !state.shippingCode &&
    appState.requiresShipment &&
    appState.hasShippingOptions
  ) {
    return CheckoutSection.shippingOptions;
  } else if (!state.orderID) {
    return CheckoutSection.confirm;
  } else {
    return CheckoutSection.thankYou;
  }
}

export function breadcrumbForCheckoutSection(
  section: CheckoutSection
): CheckoutBreadcrumb | undefined {
  switch (section) {
    case CheckoutSection.shippingAddress:
      return CheckoutBreadcrumb.shipping;

    case CheckoutSection.payment:
    case CheckoutSection.shippingOptions:
      return CheckoutBreadcrumb.payment;

    case CheckoutSection.confirm:
      return CheckoutBreadcrumb.review;

    default:
      return undefined;
  }
}
