import classNames from "classnames";
import React, { useCallback, useMemo } from "react";

import { LineItemID, IProduct, IVariant } from "../../types";
import RemoveButton from "../RemoveButton";
import { productIsComplex } from "../../views/ProductDetails/ProductProps";
import { Image } from "../Image";

interface ILineItemProps {
  id: LineItemID;
  product?: IProduct;
  variant?: IVariant;
  description: string;
  details?: string;
  price: string;
  isFree?: boolean;
  showFreeTrialPeriod?: boolean;
  removeLineItem?(id: LineItemID): void;
}

export default function LineItem({
  id,
  description,
  product,
  variant,
  details,
  price,
  isFree,
  showFreeTrialPeriod,
  removeLineItem
}: ILineItemProps) {
  const handleRemoveLineItem = useCallback(() => {
    if (removeLineItem) {
      removeLineItem(id);
    }
  }, [removeLineItem, id]);

  const productImage = useMemo(() => {
    if (!product || (productIsComplex(product) && !variant)) {
      return undefined;
    }

    return variant?.checkoutImage ?? product.checkoutImage;
  }, [product, variant]);

  return (
    <div
      className={classNames("split-container", "line-item", {
        "free-trial-item": isFree
      })}
    >
      {productImage && (
        <div className="product-image">
          <Image image={productImage} desiredWidth={100} />
        </div>
      )}
      <div className="line-item-body">
        <div className="line-item-details">
          <div className="product-name">
            <h4>{description}</h4>
          </div>
          <div className="sku-details">{details}</div>
        </div>

        <div className="product-pricing">
          <div className="line-item-price">
            {isFree && showFreeTrialPeriod && (
              <span className="free-trial-price-qualifier">
                Free for 30 days
              </span>
            )}
            <h4>{isFree ? <del>${price}</del> : <>${price}</>}</h4>
          </div>
          {removeLineItem && <RemoveButton onClick={handleRemoveLineItem} />}
        </div>
      </div>
    </div>
  );
}
