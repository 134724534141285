import { connect } from "react-redux";

import Home from "../components/Home";
import { Dispatch } from "../lib/util";
import { cartActions } from "../reducers/cart";
import * as types from "../types";

const mapStateToProps = (state: types.IAppState) => {
  let featuredProduct: types.IComplexProduct | undefined;
  const foundProduct = state.config.products.find(p => p.featured);
  if (foundProduct && foundProduct.kind === types.ProductKind.Complex) {
    featuredProduct = foundProduct;
  }
  return { featuredProduct };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addLineItem: (lineItem: types.ILineItem) => {
      dispatch(cartActions.addLineItem(lineItem));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
