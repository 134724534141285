import React from "react";
import classNames from "classnames";

import DropdownMenu from "./DropdownMenu";
import LoginLogoutButton from "./LoginLogoutButton";
import ImpersonateButton from "./ImpersonateButton";

interface IMainNavigationProps {
  hidden: boolean;
  dismissMobileMenu(): void;
}

function MainNavigation({ hidden, dismissMobileMenu }: IMainNavigationProps) {
  const navClasses = classNames("nav", {
    "hidden-nav": hidden
  });
  return (
    <div id="main-nav" className={navClasses}>
      <DropdownMenu
        title="How it works"
        defaultTo="https://tryfi.com/learn"
        dismissMobileMenu={dismissMobileMenu}
        extraClass="hiw"
        items={[
          {
            icon: "/collar_icon.svg",
            name: "The Collar",
            to: "https://tryfi.com/learn"
          },
          {
            icon: "/ld_icon.svg",
            name: "Lost Dog",
            to: "https://tryfi.com/lostdog"
          },
          {
            icon: "/tech_icon.svg",
            name: "Technology",
            to: "https://tryfi.com/tech"
          },
          {
            icon: "/collarlight_icon.svg",
            name: "Collar Light",
            to: "https://tryfi.com/light"
          }
        ]}
      />

      <a className="nav__link hidden-sm-up" href="https://tryfi.com/lostdog">
        Lost Dog
      </a>
      <a className="nav__link hidden-sm-up" href="https://tryfi.com/tech">
        Technology
      </a>
      <a className="nav__link hidden-sm-up" href="https://tryfi.com/light">
        Collar Light
      </a>
      <hr className="hidden-sm-up" />

      <DropdownMenu
        title="Store"
        defaultTo="/"
        dismissMobileMenu={dismissMobileMenu}
        extraClass="sto"
        items={[
          {
            icon: "/collar_icon.svg",
            name: "Collars",
            to: "/"
          },
          {
            icon: "/base_icon.svg",
            name: "Accessories",
            to: "/accessories"
          },
          {
            icon: "/gift_box_white.svg",
            name: "Gift Cards",
            to: "/gift"
          }
        ]}
      />

      <a className="nav__link hidden-sm-up" href="/">
        Collars
      </a>
      <a className="nav__link hidden-sm-up" href="/accessories">
        Accessories
      </a>
      <hr className="hidden-sm-up" />

      <a className="nav__link" href="https://tryfi.com/testimonial">
        Testimonials
      </a>
      <a className="nav__link" href="https://tryfi.com/about">
        About
      </a>

      <div className="right-nav">
        <a className="nav__link hidden-sm-up" href="https://blog.tryfi.com">
          Blog
        </a>
        <a className="nav__link" href="https://support.tryfi.com">
          Support
        </a>
        <ImpersonateButton />
        <LoginLogoutButton
          extraClasses={["nav__link", "nav__link--session"]}
          onClick={() => dismissMobileMenu()}
        />
      </div>
    </div>
  );
}

export default MainNavigation;
