import React, { useMemo, useContext } from "react";
import classNames from "classnames";

import CheckoutContext from "../CheckoutContext";

export default function BillingPeek() {
  const { checkoutState } = useContext(CheckoutContext);
  const { shippingAddress, billingInfo } = checkoutState;

  const billingIsSameAddress = useMemo(() => {
    const ba = billingInfo?.address;
    const sa = shippingAddress;
    return (
      ba?.city === sa?.city &&
      ba?.state === sa?.state &&
      ba?.zip === sa?.zip &&
      ba?.line1 === sa?.line1 &&
      (ba?.line2 ?? "") === (sa?.line2 ?? "")
    );
  }, [billingInfo, shippingAddress]);

  if (!billingInfo) {
    return null;
  }

  return (
    <div className="billing peek">
      <div className="card-info">
        {billingInfo.cardInfo.type}: **** **** ****{" "}
        {billingInfo.cardInfo.lastFour}
      </div>
      <div
        className={classNames("billing-address", {
          "is-same": billingIsSameAddress
        })}
      >
        {billingIsSameAddress ? (
          "Address same as shipping"
        ) : (
          <>
            {billingInfo.address.city}, {billingInfo.address.state}{" "}
            {billingInfo.address.zip}
          </>
        )}
      </div>
    </div>
  );
}
