import React from "react";
import StateSelector from "../../../../components/StateSelector";

interface IChangeHandler {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    overrideFieldName?: string
  ) => void;
  performChange(fieldName: string, value: string): void;
}

interface IAccountFields {
  email?: string;
  firstName?: string;
  lastName?: string;
}

interface IAddressFields {
  phone?: string;
  line1?: string;
  line2?: string;
  city?: string;
  zip?: string;
  state?: string;
}

export function AccountDetails({
  email,
  firstName,
  lastName,
  onChange
}: IAccountFields & IChangeHandler) {
  return (
    <>
      <input
        type="email"
        name="email"
        placeholder="Email address"
        value={email}
        onChange={onChange}
        autoComplete="email"
      />
      <input
        type="password"
        name="password"
        placeholder="Password"
        onChange={onChange}
        autoComplete="new-password"
      />
      <div className="form-row">
        <input
          type="text"
          name="firstName"
          placeholder="First name"
          value={firstName}
          onChange={onChange}
          autoComplete="given-name"
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last name"
          value={lastName}
          onChange={onChange}
          autoComplete="family-name"
        />
      </div>
    </>
  );
}

export function AddressDetails({
  onChange,
  performChange,
  phone,
  line1,
  line2,
  city,
  zip,
  state
}: IAddressFields & IChangeHandler) {
  return (
    <>
      <input
        type="tel"
        name="phone"
        placeholder="Phone number"
        value={phone}
        onChange={onChange}
        autoComplete="tel"
      />
      <input
        type="text"
        name="address1"
        placeholder="Street address"
        value={line1}
        onChange={e => onChange(e, "line1")}
        autoComplete="street-address"
      />
      <input
        type="text"
        name="address2"
        placeholder="Apt/suite/other (optional)"
        value={line2}
        onChange={e => onChange(e, "line2")}
        autoComplete="address-line-2"
      />
      <div className="form-row">
        <input
          type="text"
          name="city"
          placeholder="City"
          value={city}
          onChange={onChange}
          autoComplete="address-level2"
        />
        <input
          type="text"
          name="zip"
          placeholder="Zip Code"
          value={zip}
          onChange={onChange}
          className="zip-code"
          autoComplete="postal-code"
        />
        <StateSelector
          state={state}
          onChange={newState => performChange("state", newState)}
        />
      </div>
    </>
  );
}
