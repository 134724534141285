import "./polyfills";

import * as React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import client from "./lib/fi-api/client";
import store from "./lib/store";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root") as HTMLElement
);

unregisterServiceWorker();
