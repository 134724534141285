import React, { useState } from "react";
import gql from "graphql-tag";
import {
  RescuePlacementModel,
  RedirectToRescuePlacementHome,
  transferringOwnershipTitle,
  rescuePlacementMainQuery,
  AccountInformation
} from "./rescuePlacementUtils";
import { gqlTypes } from "../../types/gql";
import { useMutation } from "react-apollo";
import PetView from "./PetView";
import GoBackLink from "./GoBackLink";
import EditBillingInfo, {
  BillingInfoMutation
} from "../../components/EditBillingInfo";
import { UserFacingError } from "../../lib/util";

const rescueTransferPetPermanentlyMutation = gql`
  mutation rescueTransferPetPermanently(
    $input: RescueTransferPetPermanentlyInput!
  ) {
    rescueTransferPetPermanently(input: $input) {
      accountCreated
      newAccountTemporaryPassword
      billingAccount {
        billingInfo {
          firstName
          lastName
          address {
            line1
            line2
            city
            state
            zip
            country
          }
          cardInfo {
            lastFour
            type
            month
            year
          }
        }
      }
    }
  }
`;

function PermanentPlacementSuccess({
  data,
  pet,
  email
}: {
  data?: gqlTypes.rescueTransferPetPermanently;
  pet: gqlTypes.petDetails;
  email: string;
}) {
  return (
    <div>
      <h2>Success!</h2>
      <p>
        Ownership of {pet.name} was transferred to <strong>{email}</strong>.
      </p>
      {data && data.rescueTransferPetPermanently && (
        <AccountInformation data={data.rescueTransferPetPermanently} />
      )}
    </div>
  );
}

export default function PetPermanentPlacement({
  model,
  petId
}: {
  model: RescuePlacementModel;
  petId: string;
}) {
  const [transferPetPermanently, { data }] = useMutation<
    gqlTypes.rescueTransferPetPermanently,
    gqlTypes.rescueTransferPetPermanentlyVariables
  >(rescueTransferPetPermanentlyMutation, {
    refetchQueries: [{ query: rescuePlacementMainQuery }]
  });

  // Note: after the pet is transferred the current user won't have access to it,
  // so after the refetch query runs it will be gone from the model. In order to
  // properly display a "success" message, keep the pet in React state that will
  // remain unchanged.
  const [pet] = useState(() => model.getPetById(petId));

  const [email, setEmail] = useState("");
  const [giftCardRedemptionCode, setGiftCardRedemptionCode] = useState("");
  const [complete, setComplete] = useState(false);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  const mutation: BillingInfoMutation = async ({
    firstName,
    lastName,
    input: billingAccountInput
  }) => {
    if (!email) {
      throw new UserFacingError(`Please enter an email address above!`);
    }
    if (!giftCardRedemptionCode) {
      throw new UserFacingError(
        `Please enter a gift card redemption code above!`
      );
    }
    const result = await transferPetPermanently({
      variables: {
        input: {
          billingAccountInput,
          firstName,
          lastName,
          email,
          giftCardRedemptionCode,
          petId
        }
      }
    });
    return result.data!.rescueTransferPetPermanently!.billingAccount;
  };

  let body: JSX.Element;

  if (complete) {
    body = <PermanentPlacementSuccess data={data} email={email} pet={pet} />;
  } else {
    body = (
      <>
        <div>
          <h2>Transfer to a permanent parent</h2>
        </div>
        <div>
          <div className="basic-form wide">
            <h3>Email address</h3>
            <p>
              Enter the email address of the user you wish to transfer this pet
              to.
            </p>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>
        </div>
        <div>
          <div className="basic-form wide">
            <h3>Gift card code</h3>
            <p>
              Enter the code for one of the gift cards you have purchased. This
              will cover the first year of the GPS subscription.
            </p>
            <input
              type="text"
              placeholder="Gift card code"
              value={giftCardRedemptionCode}
              onChange={e => setGiftCardRedemptionCode(e.currentTarget.value)}
            />
          </div>
        </div>
        <div>
          <div className="wide">
            <h3>Billing</h3>
            <p>
              Enter the billing information for the new foster parent. This is
              necessary to apply the gift card to their Fi account. This payment
              information will be used to charge for their subscription renewal
              in one year.
            </p>
            <EditBillingInfo
              mutation={mutation}
              actionText="Transfer"
              onComplete={() => setComplete(true)}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <GoBackLink />
      <PetView pet={pet} title={transferringOwnershipTitle(pet)} />
      {body}
    </div>
  );
}
