import React, { useMemo } from "react";
import classNames from "classnames";
import {
  CheckoutSection,
  breadcrumbForCheckoutSection,
  CheckoutBreadcrumb
} from "../../../models/CheckoutSections";

interface IBreadcrumbsProps {
  location: CheckoutSection;
  isNonPhysicalCheckout: boolean;
  onWouldRevisit: (section: CheckoutSection) => void;
}

export default function Breadcrumbs({
  location,
  isNonPhysicalCheckout,
  onWouldRevisit
}: IBreadcrumbsProps) {
  const crumb = useMemo(() => breadcrumbForCheckoutSection(location), [
    location
  ]);

  if (crumb === undefined) {
    return null;
  }

  const shippingAddressClasses = classNames("button", "checkout-breadcrumb", {
    disabled: crumb <= CheckoutBreadcrumb.shipping,
    "text--bolder": crumb === CheckoutBreadcrumb.shipping,
    "text--lowlight": crumb !== CheckoutBreadcrumb.shipping
  });

  const paymentClasses = classNames("button", "checkout-breadcrumb", {
    disabled: crumb <= CheckoutBreadcrumb.payment,
    "text--bolder": crumb === CheckoutBreadcrumb.payment,
    "text--lowlight": crumb !== CheckoutBreadcrumb.payment
  });

  const reviewClasses = classNames("button", "checkout-breadcrumb", {
    disabled: crumb <= CheckoutBreadcrumb.review,
    "text--bolder": crumb === CheckoutBreadcrumb.review,
    "text--lowlight": crumb !== CheckoutBreadcrumb.review
  });

  return (
    <div className="breadcrumbs">
      <button
        className={shippingAddressClasses}
        onClick={() => {
          onWouldRevisit(CheckoutSection.shippingAddress);
        }}
      >
        1. {isNonPhysicalCheckout ? "Account" : "Shipping"}
      </button>
      <button
        className={paymentClasses}
        onClick={() => {
          onWouldRevisit(CheckoutSection.payment);
        }}
      >
        2. Payment
      </button>
      <button
        className={reviewClasses}
        onClick={() => {
          onWouldRevisit(CheckoutSection.confirm);
        }}
      >
        3. Review
      </button>
    </div>
  );
}
