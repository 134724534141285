import React from "react";
import * as types from "../types";

interface IGiftCardBannerProps {
  product: types.IGiftCardProduct;
}

export default function GiftCardBanner({ product }: IGiftCardBannerProps) {
  return (
    <div className="coupon-banner">
      <div className="coupon-banner--content">
        <span className="coupon-banner--gift-box" />
        You've been gifted a {product.nameForBanner}, enjoy!
      </div>
    </div>
  );
}
