import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as types from "../../types";
import { useApolloClient } from "react-apollo-hooks";
import gql from "graphql-tag";
import * as gqlTypes from "../../types/gql-op-types";
import Constants from "../../lib/constants";

const userSettingsQuery = gql`
  query userSettings {
    currentUser {
      id
      settings {
        showDebugMenu
      }
    }
  }
`;

export default function ImpersonateButton() {
  const session = useSelector((state: types.IAppState) => state.session);
  const client = useApolloClient();
  const [canImpersonate, setCanImpersonate] = useState(false);
  const sessionUserId = session?.userId;

  useEffect(() => {
    async function loadUserSettings() {
      if (!sessionUserId) {
        setCanImpersonate(false);
        return;
      }

      try {
        const response = await client.query<gqlTypes.userSettings>({
          query: userSettingsQuery,
          fetchPolicy: "network-only"
        });
        const showDebugMenu = response.data.currentUser.settings.showDebugMenu;
        setCanImpersonate(showDebugMenu);
      } catch (e) {
        setCanImpersonate(false);
      }
    }

    loadUserSettings();
  }, [sessionUserId, client, setCanImpersonate]);

  if (!canImpersonate) {
    return null;
  }

  return (
    <Link to={Constants.ImpersonatePath} className="nav__link">
      Impersonate
    </Link>
  );
}
