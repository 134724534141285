import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { useState, useEffect } from "react";

export const webApiClient = axios.create({
  baseURL: process.env.REACT_APP_WEB_API_URL,
  headers: { "x-api-key": process.env.REACT_APP_WEB_API_AUTH_KEY },
  withCredentials: true
});

export function useAxiosGet<T = any>(url: string, config?: AxiosRequestConfig) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function performRequest() {
      try {
        const response = await webApiClient.get(url, config);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    performRequest();
  }, [url, config]);

  return { loading, error, data };
}

export function isAxiosError(err: any): err is AxiosError {
  return !!err.isAxiosError;
}
