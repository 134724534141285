import React, { useMemo } from "react";
import { ProductKind, IImage } from "../../../types";
import { Image } from "../../../components/Image";
import { centsToDollars, expectUnreachable } from "../../../lib/util";
import { ProductLink, ProductLinkLoc } from "../../../lib/productLinks";

import { ModuleItem } from "../AccessoryTypes";

const ItemTile = ({ item }: { item: ModuleItem }) => {
  let image: IImage | undefined = useMemo(() => {
    if (item.kind === ProductKind.Simple) {
      return item.product.productImages![0];
    } else if (item.kind === ProductKind.Complex) {
      return item.variant.productImages![0];
    } else if (item.kind === "Custom") {
      return item.image;
    } else {
      expectUnreachable(item);
      return undefined;
    }
  }, [item]);

  const productName = useMemo(() => {
    if (item.nameOverride) {
      return item.nameOverride;
    }

    if (
      item.kind === ProductKind.Complex &&
      item.product.id === "additional-band"
    ) {
      // Bands get a custom "Color Band" name. But we have to capitalize the color.
      const color = item.variant.options.color;
      const colorName = color[0].toUpperCase() + color.substring(1);
      return `${colorName} Band`;
    }

    return item.product.name;
  }, [item]);

  if (!image) {
    return null;
  }

  return (
    <div className="accessories-module-item-container">
      <div className="accessories-module-item">
        <ProductLink
          productId={item.product.id}
          from={ProductLinkLoc.Accessories}
          variantOptions={
            item.kind === ProductKind.Complex ? item.variant.options : undefined
          }
        >
          <div className="accessories-module-item-image">
            {item.bareImage ? (
              <img src={image.url} alt="" />
            ) : (
              <Image image={image} desiredWidth={500} />
            )}
          </div>
          <div className="accessories-module-item-description">
            {item.kind === "Custom" && item.extraDescription}
            <h4>
              {productName}
              <small className="accessories-module-item-description-price">
                ${centsToDollars(item.product.priceInCents)}
              </small>
            </h4>
          </div>
        </ProductLink>
      </div>
    </div>
  );
};

export default ItemTile;
