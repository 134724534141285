import * as React from "react";
import * as types from "../types";

export const Image = ({
  image,
  desiredWidth,
  ...extraProps
}: {
  desiredWidth?: number | string;
  image: types.IImage;
} & React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  let width: number | string = image.retinaWidth / 2;
  let height: number | undefined = image.retinaHeight / 2;
  if (typeof desiredWidth === "number") {
    const aspectRatio = image.retinaWidth / image.retinaHeight;
    width = desiredWidth;
    height = Math.trunc(desiredWidth / aspectRatio);
  } else if (typeof desiredWidth === "string") {
    width = desiredWidth;
    height = undefined;
  }
  return (
    <img src={image.url} width={width} height={height} alt="" {...extraProps} />
  );
};
