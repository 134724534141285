import React from "react";
import classname from "classnames";

interface ISizingBlockProps {
  icon: string;
  dogList: string;
  sizeName: string;
  collarLength: string;
  className?: string;
  onClick?: VoidFunction;
}

export default function SizingBlock({
  icon,
  dogList,
  sizeName,
  collarLength,
  className,
  onClick
}: ISizingBlockProps) {
  let rootClasses = classname("sizing-block", className);

  return (
    <div className={rootClasses} onClick={onClick}>
      <div className="dog-icon">
        <img alt="A dog of this size" src={icon} />
      </div>
      <div className="sizing-details">
        <p>{dogList}</p>
        <div className="sizing-stats">
          <div>
            <h5>Size</h5>
            <span>{sizeName}</span>
          </div>
          <div className="length">
            <h5>Collar Length</h5>
            <span>{collarLength}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
