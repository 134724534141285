import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect
} from "react";
import { MutationFunction } from "react-apollo";

import * as events from "../../../../lib/analytics/events";
import client from "../../../../lib/fi-api/client";
import { mapCartToVariables } from "../../../../lib/purchase";
import {
  getCustomerMessageFromApolloError,
  centsToDollars,
  logInternalError
} from "../../../../lib/util";
import * as types from "../../../../types";
import { gqlTypes } from "../../../../types/gql";
import ErrorComponent, {
  useError
} from "../../../../components/ErrorComponent";
import LineItemList from "../../../../components/LineItemList";
import Loading from "../../../../components/Loading";
import GiftCardApplied from "../../../../components/GiftCardApplied";

import useFreeTrialSet from "./useFreeTrialSet";
import CheckoutContext, { setOrderID } from "../../CheckoutContext";
import { priceCartForCheckout } from "../../../../lib/pricing";
import ErrorMessages from "../../../../lib/errors";
import { setGlobalVariablesForConversionTracking } from "../../../../lib/analytics/analyticsUtils";

interface IConfirmProps {
  purchase: MutationFunction<
    gqlTypes.createPurchase,
    gqlTypes.createPurchaseVariables
  >;
  loading: boolean;
  shippingOptions: types.IShippingOption[];
}

export default function Confirm({
  purchase,
  loading: loadingProp,
  shippingOptions
}: IConfirmProps) {
  const { error, errorID, setError, clearError } = useError();
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState<types.ICartSummary | undefined>(
    undefined
  );

  const { checkoutState, checkoutDispatch } = useContext(CheckoutContext);

  const { orderCart: cart, shippingAddress, shippingCode } = checkoutState;

  const updatePricingAndState = useCallback(async () => {
    setLoading(true);

    try {
      const pricing = await priceCartForCheckout(
        client,
        cart,
        shippingCode ?? null
      );

      setSummary({
        discount: pricing.now.discount,
        subtotal: pricing.now.subtotal,
        tax: pricing.now.taxes,
        total: pricing.now.total
      });
      clearError();
    } catch (err) {
      logInternalError(err);
      setError(ErrorMessages.DEFAULT);
    } finally {
      setLoading(false);
    }
  }, [cart, shippingCode, setError, clearError]);

  useEffect(() => {
    updatePricingAndState();
  }, [updatePricingAndState]);

  const selectedShippingOption = useMemo(() => {
    return shippingOptions.find(so => so.code === shippingCode);
  }, [shippingOptions, shippingCode]);

  const freeTrialSet = useFreeTrialSet(cart);

  const handlePurchase = useCallback(
    async (event: any) => {
      event.preventDefault();

      const variables = mapCartToVariables(
        cart,
        selectedShippingOption?.code ?? null
      );

      try {
        const result = await purchase({ variables });

        if (!result?.data) {
          throw new Error(`Missing GraphQL data`);
        }

        events.confirmation.finalConfirm(cart);

        const newOrderId = result.data.createPurchase.invoiceNumber;
        events.orderCompleted(
          cart,
          newOrderId,
          shippingAddress,
          checkoutState.billingInfo
        );
        setGlobalVariablesForConversionTracking(newOrderId, cart);

        checkoutDispatch(setOrderID(newOrderId));
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        setError(
          `${customerMessage ||
            "Failed to process purchase"}. Please try again or contact support@tryfi.com if the problem persists.`
        );
      }
    },
    [
      cart,
      selectedShippingOption,
      purchase,
      shippingAddress,
      checkoutState.billingInfo,
      checkoutDispatch,
      setError
    ]
  );

  const displayPriceForShipping = useMemo(() => {
    if (selectedShippingOption) {
      if (selectedShippingOption.priceInCents === 0) {
        return "Free";
      } else if (selectedShippingOption.priceInCents % 100 === 0) {
        return `$${centsToDollars(selectedShippingOption.priceInCents)}.00`;
      } else {
        return `$${centsToDollars(selectedShippingOption.priceInCents)}`;
      }
    } else {
      return undefined;
    }
  }, [selectedShippingOption]);

  const coupon = cart.couponDetails;

  if (loading || loadingProp) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorComponent
        error={error}
        trackError={message => events.confirmation.error(message)}
        errorID={errorID}
      />
    );
  }

  return (
    <div className="purchase-container">
      <LineItemList
        showFreeTrialPeriod={true}
        lineItems={cart.lineItems}
        freeTrialSet={freeTrialSet}
        coupons={coupon ? [coupon] : []}
      />

      <div className="cart-subtotal row">
        <h4>Subtotal</h4>
        <h4>${summary?.subtotal ?? "-"}</h4>
      </div>

      <div className="cart-summary">
        <div className="row">
          <h4>Taxes</h4>
          <h4>${summary?.tax ?? "-"}</h4>
        </div>
        <div className="row">
          <h4>Shipping</h4>
          <h4>{displayPriceForShipping ?? "-"}</h4>
        </div>
        <div className="row cart-total">
          <h4>Total</h4>
          <h4>${summary?.total ?? "-"}</h4>
        </div>
        {cart.giftCardDetails && <GiftCardApplied />}
      </div>
      <div className="action-container">
        <button className="flow-button--yellow" onClick={handlePurchase}>
          Submit Order
        </button>
      </div>
      <div className="cart-summary-disclaimer">
        By clicking the Submit Order button you agree to our{" "}
        <a
          href="https://tryfi.com/privacypolicy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://tryfi.com/tos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        .
      </div>
    </div>
  );
}
