import { combineReducers, PayloadAction } from "@reduxjs/toolkit";

import * as types from "../types";
import {
  affiliateDataInitialState,
  affiliateDataReducer
} from "./affiliateData";
import { cartInitialState, cartReducer } from "./cart";
import { configInitialState, configReducer } from "./config";
import { experimentInitialState, experimentReducer } from "./experiment";
import { sessionInitialState, sessionReducer } from "./session";

export const initialAppState: types.IAppState = {
  ...affiliateDataInitialState,
  ...cartInitialState,
  ...configInitialState,
  ...experimentInitialState,
  ...sessionInitialState
};

const rootReducer = combineReducers<types.IAppState, PayloadAction<any>>({
  affiliateData: affiliateDataReducer,
  cart: cartReducer,
  config: configReducer,
  experiment: experimentReducer,
  session: sessionReducer
});

export default rootReducer;
