import React, { useMemo, useContext } from "react";

import * as types from "../../../types";
import CheckoutContext from "../CheckoutContext";
import { centsToDollars } from "../../../lib/util";

interface IPeekProps {
  shippingOptions: types.IShippingOption[];
}

export default function ShippingOptionsPeek({ shippingOptions }: IPeekProps) {
  const { checkoutState } = useContext(CheckoutContext);
  const { shippingCode } = checkoutState;

  const selectedOption = useMemo(
    () => shippingOptions.find(so => so.code === shippingCode),
    [shippingCode, shippingOptions]
  );

  const priceToShow = useMemo(() => {
    if (selectedOption === undefined) {
      return "";
    } else if (selectedOption.priceInCents === 0) {
      return "Free";
    } else {
      return "$" + centsToDollars(selectedOption.priceInCents);
    }
  }, [selectedOption]);

  if (!selectedOption) {
    return null;
  }

  return (
    <div className="shipping-option peek">
      {selectedOption.name} - {priceToShow}
    </div>
  );
}
