import * as types from "../../types";
import { webApiClient } from "./apiUtils";

interface IRemoteConfig {
  products: types.IProduct[];
  config: types.IECommerceSiteConfig;
  shippingOptions: types.IShippingOption[];
}

export async function getRemoteConfig(
  exchange: boolean
): Promise<IRemoteConfig> {
  try {
    const response = await webApiClient.get("/api/ecommerce/products", {
      params: exchange ? { exchange: "true" } : {}
    });
    const typedResponse: IRemoteConfig = response.data;
    const products = typedResponse.products;
    if (!products || products.length < 1) {
      throw new Error("No products found");
    }
    return typedResponse;
  } catch (error) {
    throw new Error("Unable to load products");
  }
}
