import Select from "react-select";
import React, { useMemo } from "react";

import states from "../lib/states";

interface IStateSelectorProps {
  state: string | undefined;
  onChange(newState: string): void;
}

export default function StateSelector({
  state,
  onChange
}: IStateSelectorProps) {
  const options = useMemo(
    () =>
      states.map(item => ({
        value: item.abbreviation,
        label: item.name
      })),
    []
  );

  const value = useMemo(
    () => (state ? options.find(({ value }) => value === state) : undefined),
    [options, state]
  );

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      inputId="state"
      options={options}
      value={value}
      placeholder="State"
      onChange={option => {
        if (option && "value" in option) {
          onChange(option.value);
        }
      }}
    />
  );
}
