import gql from "graphql-tag";

import { sessionActions } from "../../reducers/session";
import { ISession } from "../../types";
import * as gqlTypes from "../../types/gql-op-types";
import * as postie from "../analytics/postie";
import ErrorMessages from "../errors";
import store from "../store";
import client from "./client";
import { webApiClient } from "./apiUtils";

const billingAccountExistsQuery = gql`
  query billingAccountExists {
    currentUser {
      id
      billingAccount {
        code
      }
    }
  }
`;

const createBillingAccountMutation = gql`
  mutation createBillingAccount {
    createBillingAccount(input: {}) {
      code
    }
  }
`;

async function createBillingAccountAndSetSession(session: ISession) {
  try {
    await createBillingAccount();
  } catch (error) {
    throw error;
  } finally {
    if (session) {
      store.dispatch(sessionActions.createSession({ session }));
    }
  }
}

export async function login(
  email: string,
  password: string
): Promise<ISession> {
  if (!email || !password) {
    throw new Error("Please provide both an email and password");
  }

  let session: ISession;
  try {
    const response = await webApiClient.post("/auth/login", {
      email,
      password
    });

    session = {
      userId: response.data.userId,
      email: response.data.email
    };
  } catch (error) {
    if (error.response.status === 401) {
      throw new Error("Invalid email or password");
    } else {
      throw new Error("An unknown error occurred");
    }
  }

  // Make sure to wait for the billing account here so that it's available
  // when the dispatch updates component props
  await createBillingAccountAndSetSession(session);

  return session;
}

export async function createSessionFromExistingCredentials() {
  const response = await webApiClient.get("/auth/currentuser");
  const session: ISession = {
    userId: response.data.userId,
    email: response.data.email
  };
  await createBillingAccountAndSetSession(session);
}

export async function setPassword(newPassword: string) {
  await webApiClient.post("/auth/setpassword", { password: newPassword });
  store.dispatch(sessionActions.didSetPassword({}));
}

export async function logout() {
  try {
    await webApiClient.post("/auth/logout");
    store.dispatch(sessionActions.destroySession());
  } catch (error) {
    throw new Error("We were unable to log you out");
  }
}

export async function signup(
  email: string,
  password: string,
  firstName: string,
  lastName: string
): Promise<ISession> {
  if (!email || !password) {
    throw new Error("Please provide both an email and password");
  }
  if (!firstName || !lastName) {
    throw new Error("Please provide your first and last name");
  }

  let session: ISession;
  try {
    const response = await webApiClient.post("/auth/signup", {
      email,
      password,
      firstName,
      lastName
    });

    postie.trackEmailSignup(email);

    session = {
      userId: response.data.userId,
      email
    };
  } catch (error) {
    if (error.response.status === 400) {
      if (
        error.response.data.error &&
        error.response.data.error.code &&
        error.response.data.error.code === "already-exists"
      ) {
        throw new Error("That email is already in use, try logging in first");
      } else {
        if (error.response.data.error.message) {
          throw new Error(error.response.data.error.message);
        } else {
          throw new Error(ErrorMessages.INPUT_ERROR);
        }
      }
    } else {
      throw new Error("An unknown error occurred");
    }
  }

  // Make sure to wait for the billing account here so that it's available
  // when the dispatch updates component props
  try {
    await createBillingAccount();
  } catch (error) {
    throw new Error(ErrorMessages.INPUT_ERROR);
  } finally {
    if (session) {
      store.dispatch(sessionActions.createSession({ session }));
    }
  }

  return session;
}

export async function impersonate(email: string) {
  const response = await webApiClient.post("/auth/impersonate", { email });
  const session: ISession = {
    userId: response.data.userId,
    email,
    impersonating: true
  };
  try {
    await createBillingAccount();
  } finally {
    store.dispatch(sessionActions.createSession({ session }));
  }
}

export async function sendPasswordResetEmail(email: string) {
  await webApiClient.post("/auth/sendpasswordreset", { email });
}

async function createBillingAccount(): Promise<boolean> {
  try {
    const billingAccount = await client.query<gqlTypes.billingAccount>({
      query: billingAccountExistsQuery,
      fetchPolicy: "network-only"
    });

    if (!billingAccount.data.currentUser.billingAccount) {
      await client.mutate<gqlTypes.createBillingAccount>({
        mutation: createBillingAccountMutation
      });
    }
  } catch (error) {
    throw new Error("An unknown error occurred");
  }

  return true;
}

function applePayContactToParams(contact: ApplePayJS.ApplePayPaymentContact) {
  return {
    email: contact.emailAddress,
    firstName: contact.givenName,
    lastName: contact.familyName,
    address1: contact.addressLines && contact.addressLines[0],
    address2: contact.addressLines && contact.addressLines[1],
    city: contact.locality,
    state: contact.administrativeArea,
    phone: contact.phoneNumber,
    zip: contact.postalCode,
    country: (contact.countryCode && contact.countryCode.toUpperCase()) || "US"
  };
}

export async function applePay(
  token: string,
  shippingAddressDetails: ApplePayJS.ApplePayPaymentContact
): Promise<boolean> {
  try {
    const response = await webApiClient.post("/api/ecommerce/applepay", {
      token,
      ...applePayContactToParams(shippingAddressDetails)
    });
    const email = shippingAddressDetails.emailAddress;
    const session: ISession = {
      userId: response.data.userId,
      noPassword: !response.data.hasPassword,
      email
    };
    if (email) {
      postie.trackEmailSignup(email);
    }
    store.dispatch(sessionActions.createSession({ session }));
    return true;
  } catch (error) {
    if (error.response.status === 409) {
      return false;
    }
    throw new Error("An unknown error occurred");
  }
}
