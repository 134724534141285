import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import * as events from "../../../lib/analytics/events";

const GPSUpsell = ({ hasSubscription }: { hasSubscription: boolean }) => {
  const upsellCopy = useMemo(
    () => (hasSubscription ? "Add another GPS plan" : "Add GPS Plan"),
    [hasSubscription]
  );
  return (
    <h4 className="upsell gps-upsell cart-callout">
      <Link
        to={{
          pathname: `/subscription`,
          state: { fromUpsell: true }
        }}
        className="text--highlight cart-callouts-button"
        onClick={() => events.cartPage.addSubscription()}
      >
        <span className="plus">+</span>
        {upsellCopy}
      </Link>
      <span className="upsell-muted">
        If you want all collars on GPS/LTE, purchase one plan per collar.{" "}
        <a
          href="https://support.tryfi.com/hc/en-us/articles/360045663713"
          target="_blank"
          rel="noopener noreferrer"
        >
          Why?
        </a>
      </span>
    </h4>
  );
};

export default GPSUpsell;
