import React from "react";

import { Tiers, ReferralCode } from "../../components/Referrals";
import { useQuery } from "react-apollo";
import { referralsQuery } from "../../containers/ReferralsContainer.graphql";
import { gqlTypes } from "../../types/gql";

export default function ReferralsSection() {
  const { data } = useQuery<gqlTypes.referrals>(referralsQuery);

  const referralCode = data?.currentUser.referrals.referralCode;

  return (
    <>
      <div className="header">
        <h1>What's next?</h1>
      </div>
      <div className="thank-you-referrals split-container">
        <div className="thank-you-referrals-left">
          <div className="thank-you-referrals-details">
            <h4>Fi Referral Program</h4>
            <div className="thank-you-referrals-dog-container">
              <img src="/referrals/referral_dog.svg" alt="Graphic of a dog" />
            </div>
            <div>
              Know someone with a dog?
              <br />
              Refer a friend to Fi and earn rewards!
            </div>
          </div>
          <div className="thank-you-referrals-code-container">
            <div className="thank-you-referrals-code-header">
              Your invite code
            </div>
            <div>
              <ReferralCode code={referralCode ?? " "} source="thankyou" />
            </div>
            <div>
              Anytime someone uses your code they receive a free band with their
              order.
            </div>
          </div>
        </div>
        <div className="thank-you-referrals-right">
          <Tiers unlockedRewards={[]} className="thank-you-tiers" />
        </div>
      </div>
    </>
  );
}
