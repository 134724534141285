import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";

import { sessionActions } from "../../reducers/session";
import store from "../store";
import cache from "./cache";

async function customFetch(input: RequestInfo, init?: RequestInit) {
  const response = await fetch(input, init);
  if (response.status === 401) {
    store.dispatch(sessionActions.destroySession());
  }
  return response;
}

const link = createHttpLink({
  uri: `${process.env.REACT_APP_WEB_API_URL}/graphql`,
  credentials: "include",
  fetch: customFetch
});

const client = new ApolloClient({
  cache,
  link
});

export default client;
