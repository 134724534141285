import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import * as types from "../../types";

import { ProductBackButton } from "../../lib/productLinks";

import useTracking from "./useTracking";
import useAddToBag from "./useAddToBag";
import { IProductDetailsProps, variantWithOptions } from "./ProductProps";
import ProductNameImage from "./components/ProductNameImage";
import ProductDetailsColumn from "./components/ProductDetails";

import { Redirect } from "react-router-dom";
import useSubscriptionAware from "./useSubscriptionAware";

interface IComplexProductDetailsProps
  extends IProductDetailsProps<types.IComplexProduct> {
  initialColor?: types.ColorOption;
  initialSize?: types.SizeOption;
  initialType?: types.CollarTypeOption;
}

const ComplexProductDetails = ({
  initialColor,
  initialSize,
  initialType,
  addLineItem,
  linkFrom,
  product
}: IComplexProductDetailsProps) => {
  // Create state for the currently selected variant.
  // Initial value is based on the initialOptions prop, or else a default.
  const [selectedVariant, setSelectedVariant] = useState(() => {
    let defaultColor = types.ColorOption.Gray;
    switch (product.id) {
      case "smart-collar":
        defaultColor = types.ColorOption.Yellow;
        break;
      case "additional-band":
        defaultColor = types.ColorOption.Blue;
        break;
    }

    const defaultSize = types.SizeOption.Medium;
    const defaultType = types.CollarTypeOption.Standard;

    // Try to load the calculated variant
    const attemptedFromInitial = variantWithOptions(product, {
      color: initialColor ?? defaultColor,
      size: initialSize ?? defaultSize,
      collarType: initialType ?? defaultType
    });

    return attemptedFromInitial ?? product.variants[0];
  });

  // Given just a parial change to the selected options, find and set the new variant
  const changeOptions = useCallback(
    (o: Partial<types.IVariantOptions>) => {
      let newVariant = variantWithOptions(product, o, selectedVariant.options);

      if (newVariant) {
        setSelectedVariant(newVariant);
      }
    },
    [product, selectedVariant.options, setSelectedVariant]
  );

  // Set up analytics
  useTracking(product, selectedVariant);

  const { addToBag, addedToBag, productInBag } = useAddToBag(
    product,
    addLineItem
  );

  const {
    maybeAddSubscriptionFromGiftCard,
    subscriptionsInCart,
    subscriptionProductsInCart
  } = useSubscriptionAware(product, addLineItem);

  // Get the out of stock SKUs
  const blockedOutOfStockSkus = useSelector(
    (state: types.IAppState) =>
      state.config.siteConfig.blockedOutOfStockSkus || []
  );
  const isBlocked = useMemo(
    () => blockedOutOfStockSkus.includes(selectedVariant.sku),
    [blockedOutOfStockSkus, selectedVariant]
  );

  const productIsCollar = product.id === "smart-collar";

  const needsSmallSizeWarning =
    selectedVariant.options.size === types.SizeOption.Small;

  // For our add-to-bag loop, we want to also check if there is a subscription on
  // a gift card in the cart, to add it before we resume the flow.
  const addToBagCheckingGiftCard = (variant?: types.IVariant) => {
    addToBag(variant);
    maybeAddSubscriptionFromGiftCard();
  };

  const [continueTitle, continueClass, continueLocation] = useMemo(() => {
    if (!addedToBag && productInBag && productIsCollar) {
      // Cancel always cancels to the bag
      return ["Cancel", "flow-button--black", "/bag"];
    } else {
      // Continue to bag if we have the right number of subscriptions
      // and at least one...
      const continueLocation =
        subscriptionsInCart !== 0 &&
        subscriptionsInCart >= subscriptionProductsInCart
          ? "/bag"
          : "/subscription";
      return ["Continue", "flow-button--continue", continueLocation];
    }
  }, [
    productInBag,
    productIsCollar,
    addedToBag,
    subscriptionsInCart,
    subscriptionProductsInCart
  ]);

  // If the user added a non-collar accessory to their bag, immediately redirect to cart.
  if (addedToBag && !productIsCollar) {
    return <Redirect to="/bag" />;
  }

  return (
    <>
      {linkFrom ? (
        <ProductBackButton from={linkFrom} />
      ) : (
        <div className="back-bar-spacer" />
      )}
      <div className="split-container product-container">
        <ProductNameImage
          product={product}
          variant={selectedVariant}
          includeMonthlyPricing={false}
          /* TODO(zack): logic for showing monthly price should be:
           price >= $100 && featureIsEnabled
          */
        />
        <ProductDetailsColumn
          product={product}
          variant={selectedVariant}
          allowPurchase={!isBlocked}
          collarWarnSmallSize={needsSmallSizeWarning}
          blockedSkus={blockedOutOfStockSkus}
          onOptionChange={changeOptions}
          showContinueButton={addedToBag || (productInBag && productIsCollar)}
          continueTitle={continueTitle}
          continueClassName={continueClass}
          continueLocation={continueLocation}
          onAddToBag={addToBagCheckingGiftCard}
        />
      </div>
    </>
  );
};

export default ComplexProductDetails;
