import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { ISession } from "../types";
import { Dispatch } from "../lib/util";
import cache from "../lib/fi-api/cache";

export interface ICreateSession {
  session: ISession;
}

const USER_EMAIL_ADDRESS_VARIABLE_NAME = `fi__userEmailAddress`;

const name = "session" as const;

const removeSession = createAction<{}>("session/removeSession");

const { actions, reducer } = createSlice({
  name,
  initialState: null as ISession | null,
  reducers: {
    createSession(
      state: ISession | null,
      { payload }: PayloadAction<ICreateSession>
    ) {
      (window as any)[USER_EMAIL_ADDRESS_VARIABLE_NAME] =
        payload.session.email || undefined;
      return payload.session;
    },

    /**
     * Mark the user as having set their password.
     */
    didSetPassword(state: ISession | null, { payload }: PayloadAction<{}>) {
      if (!state) {
        return null;
      }
      const { noPassword, ...newState } = state;
      return newState;
    }
  },

  extraReducers: {
    [removeSession.type](state, { payload }: PayloadAction<{}>) {
      return null;
    }
  }
});

export const sessionActions = {
  ...actions,

  destroySession: () => async (dispatch: Dispatch) => {
    Cookies.remove("fi_session_id");
    cache.reset();
    (window as any)[USER_EMAIL_ADDRESS_VARIABLE_NAME] = undefined;
    dispatch(removeSession({}));
  }
};
export const sessionReducer = reducer;
export const sessionInitialState = { [name]: null as ISession | null };
