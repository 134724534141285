import React, { useState } from "react";
import gql from "graphql-tag";
import { gqlTypes } from "../../types/gql";
import {
  RescuePlacementModel,
  rescuePlacementMainQuery,
  rescuePlacementPath,
  defaultRescuePlacementNavigationState,
  RedirectToRescuePlacementHome
} from "./rescuePlacementUtils";
import { useMutation } from "react-apollo";
import GoBackLink from "./GoBackLink";
import PetView from "./PetView";
import { Redirect } from "react-router-dom";
import { fullName } from "../../lib/util";

const rescueRecallPetMutation = gql`
  mutation rescueRecallPet($input: RescueRecallPetInput!) {
    rescueRecallPet(input: $input) {
      ok
    }
  }
`;

function RecallPetView({ pet }: { pet: gqlTypes.petDetails }) {
  const [recallPet, { loading, error }] = useMutation<
    gqlTypes.rescueRecallPet,
    gqlTypes.rescueRecallPetVariables
  >(rescueRecallPetMutation, {
    refetchQueries: [{ query: rescuePlacementMainQuery }]
  });

  const [done, setDone] = useState(false);

  if (done) {
    return (
      <Redirect
        to={{
          pathname: rescuePlacementPath,
          state: defaultRescuePlacementNavigationState
        }}
      />
    );
  }

  const onSubmit = async () => {
    if (loading) {
      return;
    }

    try {
      await recallPet({
        variables: {
          input: {
            petId: pet.id
          }
        }
      });
      setDone(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div className="button button--outline" onClick={onSubmit}>
        Recall into foster organization
      </div>
      {error && <div className="text--danger">{error.message}</div>}
    </div>
  );
}

export default function PetRecall({
  model,
  petId
}: {
  model: RescuePlacementModel;
  petId: string;
}) {
  const pet = model.getPetById(petId);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  const associatedUser = model.associatedUserForPet(pet);

  return (
    <div>
      <GoBackLink />
      <PetView pet={pet} title={`Recalling ${pet.name}`} />
      {associatedUser && (
        <p>
          This pet is currently associated with{" "}
          <strong>
            {fullName(associatedUser)} ({associatedUser.email})
          </strong>
        </p>
      )}
      <RecallPetView pet={pet} />
    </div>
  );
}
