import React from "react";
import { useSelector } from "react-redux";
import { IAppState } from "../types";

export default function ImpersonationWarning() {
  const session = useSelector((state: IAppState) => state.session);

  if (!session || !session.impersonating) {
    return null;
  }

  return (
    <div className="impersonation-warning">
      You are impersonating {session.email}
    </div>
  );
}
