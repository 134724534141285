import React from "react";
import * as qs from "query-string";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import {
  ComplexProductDetails,
  SimpleProductDetails
} from "../views/ProductDetails";
import { Dispatch } from "../lib/util";
import { cartActions } from "../reducers/cart";
import * as types from "../types";
import NotFound from "../components/NotFound";
import { ProductLinkLoc } from "../lib/productLinks";

interface IProductDetailsPathParams {
  id: string;
}

interface IProductDetailsQueryParams {
  color?: types.ColorOption;
  size?: types.SizeOption;
  type?: types.CollarTypeOption;
}

interface IProductDetailsContainerProps
  extends RouteComponentProps<IProductDetailsPathParams> {
  product?: types.IProduct;
  initialColor?: types.ColorOption;
  initialSize?: types.SizeOption;
  initialCollarType?: types.CollarTypeOption;
  linkFrom?: ProductLinkLoc;
  addLineItem(lineItem: types.ILineItem): void;
  enableGiftCards: boolean;
}

function ProductDetailsContainer(props: IProductDetailsContainerProps) {
  if (!props.product) {
    return <NotFound />;
  }

  if (props.product.kind === types.ProductKind.Simple) {
    return <SimpleProductDetails {...props} product={props.product} />;
  } else if (props.product.kind === types.ProductKind.Complex) {
    return <ComplexProductDetails {...props} product={props.product} />;
  } else {
    return <NotFound />;
  }
}

const mapStateToProps = (
  state: types.IAppState,
  ownProps: RouteComponentProps<IProductDetailsPathParams>
) => {
  const queryParams: IProductDetailsQueryParams = qs.parse(
    ownProps.location.search[0] === "?"
      ? ownProps.location.search.substr(1)
      : ownProps.location.search
  );
  const product = state.config.products.find(
    p => p.id === ownProps.match.params.id && p.type === types.ProductType.Addon
  );
  const enableGiftCards =
    !!state.config.siteConfig.enableGiftCards && !state.cart.giftCardDetails;

  return {
    product: product,
    initialColor: queryParams.color,
    initialSize: queryParams.size,
    initialType: queryParams.type,
    linkFrom: (ownProps.location.state || {}).from,
    enableGiftCards
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addLineItem: (lineItem: types.ILineItem) => {
      dispatch(cartActions.addLineItem(lineItem));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailsContainer);
