import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "../lib/fi-api/authentication";
import ErrorComponent from "./ErrorComponent";

export interface IPasswordResetProps {
  haveItemsInCart: boolean;
}

interface IPasswordResetState {
  error?: string;
  emailSent: boolean;
  buttonEnabled: boolean;
}

export default class PasswordReset extends React.Component<
  IPasswordResetProps,
  IPasswordResetState
> {
  constructor(props: IPasswordResetProps) {
    super(props);
    this.state = {
      emailSent: false,
      buttonEnabled: true
    };
  }

  public render() {
    if (this.state.emailSent) {
      return (
        <div className="single-column-container login-form">
          <div className="single-column">
            <div className="login-body">
              <h3>Email Sent!</h3>
            </div>
            <div className="single-column-container wide">
              <Link to="/" className="button button--epic">
                Return to Store
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="single-column-container login-form">
        <div className="single-column">
          <div className="login-body">
            <h3>Forgot Password</h3>
            <ErrorComponent error={this.state.error} />
            <form onSubmit={this.handleSubmit} className="basic-form wide">
              <input type="text" name="email" placeholder="user@example.com" />
              <input
                type="submit"
                className={classNames({
                  button: true,
                  "button--epic": true,
                  "button--disabled": !this.state.buttonEnabled
                })}
                value="Send Email"
              />
              <div className="single-column-container wide">
                <Link to={"/login"} className="button button--plain">
                  Nevermind, take me back
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  private handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!this.state.buttonEnabled) {
      return;
    }
    this.setState({ buttonEnabled: false });
    const email = event.target.email.value.trim();
    try {
      await sendPasswordResetEmail(email);
      this.setState({ emailSent: true });
    } catch (error) {
      this.setState({ error: error.message });
    } finally {
      this.setState({ buttonEnabled: true });
    }
  };
}
