import React from "react";

import { Link } from "react-router-dom";

interface ILoginButtonProps {
  loggedIn: boolean;
  loggedInUserName?: string;
  loginPath: string;
  onLogout: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export default function LoginButton({
  loggedIn,
  loggedInUserName,
  onLogout,
  loginPath
}: ILoginButtonProps) {
  return !loggedIn ? (
    <small className="text--muted login-logout">
      Already have an account?{" "}
      <Link to={loginPath} className="text--highlight">
        Log in
      </Link>
    </small>
  ) : (
    <small className="text--muted login-logout">
      Logged in
      {loggedInUserName ? ` as ${loggedInUserName}` : null}.{" "}
      {/* eslint-disable-next-line */}
      <a href="#" className="text--highlight" onClick={onLogout}>
        Log out
      </a>
    </small>
  );
}
