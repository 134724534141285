import React, { useRef, useEffect } from "react";
import validator from "validator";
import Cookies from "js-cookie";
import { ApolloError, isApolloError } from "apollo-client";
import { Dispatch as ReduxDispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";

import * as types from "../types";
import analytics from "./analytics";
import { EcommerceApiErrorExtensions } from "../types";
import Loading from "../components/Loading";
import ErrorComponent from "../components/ErrorComponent";

export function centsToDollars(cents: number): string {
  return (Math.round(cents) / 100).toString();
}

export function generateID(leadingUnderscore = false): string {
  return (
    (leadingUnderscore ? "_" : "") +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

export const generateOrderID = () => generateID(false);

export function logInternalError(err: any) {
  console.error(err);
  const message = typeof err === "string" ? err : err.message;
  analytics.track("Internal Error", { message });
}

export function catchPromiseError(err: any): null {
  logInternalError(err);
  return null;
}

export function range(from: number, to: number) {
  const result = [];
  for (let i = from; i < to; i++) {
    result.push(i);
  }
  return result;
}

export function getProductsAndVariantsBySku(
  products: types.IProduct[]
): Map<string, { product: types.IProduct; variant?: types.IVariant }> {
  const result: Map<
    string,
    { product: types.IProduct; variant?: types.IVariant }
  > = new Map();

  for (const product of products) {
    if (
      product.kind === types.ProductKind.Simple ||
      product.kind === types.ProductKind.GiftCard
    ) {
      result.set(product.sku, { product });
    } else if (product.kind === types.ProductKind.Complex) {
      for (const variant of product.variants) {
        result.set(variant.sku, { product, variant });
      }
    } else {
      expectUnreachable(product);
    }
  }

  return result;
}

export function getProductsBySku(products: types.IProduct[]) {
  const productsBySku: Map<string, types.IProduct> = new Map();

  for (const product of products) {
    if (
      product.kind === types.ProductKind.Simple ||
      product.kind === types.ProductKind.GiftCard
    ) {
      productsBySku.set(product.sku, product);
    } else if (product.kind === types.ProductKind.Complex) {
      for (const variant of product.variants) {
        productsBySku.set(variant.sku, product);
      }
    } else {
      expectUnreachable(product);
    }
  }

  return productsBySku;
}

export function applyIf<T>(b: boolean, x: T, fn: (arg: T) => T) {
  return b ? fn(x) : x;
}

export function getCustomerMessageOrFallback(
  err: ApolloError,
  fallbackMessage: string
) {
  const customerMessage = getCustomerMessageFromApolloError(err);
  return customerMessage
    ? `${customerMessage}. If the problem persists, please contact support@tryfi.com.`
    : fallbackMessage;
}

function wrapArgumentsImpl() {
  return arguments;
}
/**
 * Convert arguments into an Arguments object (which is different from an array.)
 * This sounds crazy, but some dataLayer methods seem only to work on an arguments
 * object and just an array.
 *
 * In https://support.google.com/optimize/answer/9059383, they accomplish this
 * with the `gtag` wrapper.
 */
export const wrapArguments = wrapArgumentsImpl as (...args: any) => IArguments;

export function isSubscriptionProduct(
  product: types.IProduct
): product is types.ISubscriptionProduct {
  return (
    product.kind === types.ProductKind.Simple &&
    product.type === types.ProductType.Subscription
  );
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

let memoSupportsApplePay: boolean | null = null;

function supportsApplePay() {
  if (memoSupportsApplePay !== null) {
    return memoSupportsApplePay;
  }

  // https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
  // Memoizing this since it apparently takes up to ~500ms to compute
  // as of 6/18/16 (perf regression on Apple's end?)
  try {
    const applePaySession = (window as any).ApplePaySession;
    memoSupportsApplePay = !!(
      applePaySession && applePaySession.canMakePayments()
    );
  } catch (exception) {
    // If we run into an error while trying to show apple pay, log it and abort.
    logInternalError(exception);
    memoSupportsApplePay = false;
  }

  return memoSupportsApplePay;
}

// Can maybe convert this to a hook once more components are hookized.
export function shouldShowApplePay(state: types.IAppState): boolean {
  return !!(state.config.siteConfig.showApplePay && supportsApplePay());
}

export function deepEquals(x: any, y: any) {
  return JSON.stringify(x) === JSON.stringify(y);
}

export function isExpeditedShippingAvailable(
  shippingAddress: Pick<types.IAddress, "line1">
) {
  const upcaseLine1 = shippingAddress.line1.toUpperCase();
  return !upcaseLine1.startsWith("PO") && !upcaseLine1.startsWith("P.O.");
}

export function isKiosk(): boolean {
  return window.location.hostname.startsWith("kiosk.");
}

/**
 * Convert text with newlines to JSX with line breaks.
 * Cribbed from https://github.com/Stychen/React-Newline-to-Break/blob/master/src/index.js
 */
export function nl2br(text: string, trailingNewline = true) {
  const lines = text.split("\n");
  return (
    <>
      {lines.map((line, i) => (
        <span key={i}>
          {line}
          {(trailingNewline || i !== lines.length - 1) && <br />}
        </span>
      ))}
    </>
  );
}

export function expectUnreachable(x: never) {
  // Noop
}

export type Dispatch = ReduxDispatch &
  ThunkDispatch<types.IAppState, undefined, any>;

export function maxBy<A, B>(arr: A[], keyFn: (x: A) => B): A | null {
  if (arr.length === 1) {
    return arr[0]; // Don't bother invoking keyFn
  }
  let maxElem: A | null = null;
  let maxKey: B | null = null;
  for (const elem of arr) {
    const key = keyFn(elem);
    if (maxKey == null || key > maxKey) {
      maxElem = elem;
      maxKey = key;
    }
  }
  return maxElem;
}

export function getCustomerMessageFromApolloError(e: any): string | undefined {
  if (isApolloError(e) && e.graphQLErrors.length > 0) {
    const extensions = e.graphQLErrors[0].extensions as
      | EcommerceApiErrorExtensions
      | undefined;
    if (extensions) {
      return extensions.customerMessage;
    }
  }
  return undefined;
}

export function cartHasAllNonPhysicalProducts(
  cart: types.ICart,
  products: types.IProduct[]
) {
  const productMap = getProductsBySku(products);
  return Object.values(cart.lineItems).every(lineItem => {
    const product = productMap.get(lineItem.sku);
    return product && product.nonPhysical;
  });
}

export function findGiftCardProductFromDetails(
  details: types.IGiftCardDetails,
  products: types.IProduct[]
) {
  const product = products.find(
    p =>
      p.kind === types.ProductKind.GiftCard &&
      (p.priceInCents / 100 === details.unitAmount ||
        (p.alternativePricesInCents &&
          p.alternativePricesInCents.filter(
            cents => cents / 100 === details.unitAmount
          ).length > 0))
  );
  return product && product.kind === types.ProductKind.GiftCard
    ? product
    : undefined;
}

export function createSubscriptionLineItem(
  itemID: string,
  subscriptionProduct: types.ISubscriptionProduct
): types.ILineItem {
  let renewalYearString: string | undefined;
  const years = subscriptionProduct.renewalYears;
  if (years) {
    renewalYearString =
      years === 1
        ? `Renews after ${years} year`
        : `Renews after ${years} years`;
  }
  return {
    id: itemID,
    productID: subscriptionProduct.id,
    sku: subscriptionProduct.sku,
    type: subscriptionProduct.type,
    description: subscriptionProduct.name,
    price: centsToDollars(subscriptionProduct.priceInCents),
    quantity: 1,
    details: renewalYearString
  };
}

export type Result<S, F> =
  | { kind: "success"; value: S }
  | { kind: "failure"; err: F };

export interface CouponCookieData {
  code: string;
  bannerText?: string;
}

export const couponCookieName = "coupon_data";

export const devMode = !!process.env.REACT_APP_DEV_MODE;

export function removeCookie(name: string) {
  Cookies.remove(name, { domain: devMode ? undefined : ".tryfi.com" });
}

export function validateEmail(email: string) {
  return validator.isEmail(email) && !email.endsWith(".con");
}

export function getRefereeRewardItemIfApplicable(
  cart: types.ICart,
  refereeRewardProductIdFromState: string | undefined
): types.ILineItem | undefined {
  return cart.referralDetails
    ? Object.values(cart.lineItems).find(
        li => li.productID === refereeRewardProductIdFromState
      )
    : undefined;
}

export function getLoadingOrErrorElement(
  loading: boolean,
  error: Error | undefined | null
) {
  if (loading) {
    return (
      <div className="column">
        <Loading />
      </div>
    );
  } else if (error) {
    return (
      <div className="error-page-container">
        <ErrorComponent error={error.message} />
      </div>
    );
  }

  return undefined;
}

export function fullName(user: {
  firstName?: string | null;
  lastName?: string | null;
}) {
  return [user.firstName, user.lastName].filter(x => x).join(" ");
}

export class UserFacingError extends Error {}

export function partition<T>(arr: T[], pred: (arg: T) => boolean): [T[], T[]] {
  const satisfiesPredicate: T[] = [];
  const doesNotSatisfyPredicate: T[] = [];
  for (const elem of arr) {
    if (pred(elem)) {
      satisfiesPredicate.push(elem);
    } else {
      doesNotSatisfyPredicate.push(elem);
    }
  }
  return [satisfiesPredicate, doesNotSatisfyPredicate];
}

// Via https://github.com/recurly/recurly-js/blob/c1356a928ab2e6ccd10bf6246ea7f1ae17f0687d/lib/util/decimalize.js
export function decimalize(num: number) {
  return (Math.round(num * 100) / 100).toFixed(2);
}

export function useMountedRef() {
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}
