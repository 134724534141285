import React from "react";

import * as events from "../../../lib/analytics/events";
import { Link } from "react-router-dom";

export default function GiftCardCTA() {
  return (
    <div className="gift-card-cta cart-callout">
      <div className="callout-image">
        <img src="/bag_gift_card_callout.png" alt="A gift card" />
      </div>
      <Link
        to={{ pathname: "/gift", state: { from: "cart" } }}
        className="cart-callouts-button buy-as-gift"
        onClick={() => events.cartPage.addGiftCard()}
      >
        Buy an eGift Card
      </Link>
    </div>
  );
}
