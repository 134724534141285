import gql from "graphql-tag";

export const queryBillingAccount = gql`
  query billingAccount {
    currentUser {
      id
      firstName
      billingAccount {
        billingInfo {
          firstName
          lastName
          address {
            line1
            line2
            city
            state
            zip
            country
          }
          cardInfo {
            type
            lastFour
            month
            year
          }
        }
        address {
          line1
          line2
          city
          state
          zip
          country
          phone
        }
      }
    }
  }
`;

export const updateBillingInfo = gql`
  mutation updateBillingInfo($input: BillingAccountInput!) {
    updateBillingAccount(input: $input) {
      billingInfo {
        firstName
        lastName
        address {
          line1
          line2
          city
          state
          zip
          country
        }
        cardInfo {
          lastFour
          type
          month
          year
        }
      }
    }
  }
`;
