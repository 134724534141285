import React, { useContext } from "react";

import CheckoutContext from "../CheckoutContext";

export default function ShippingPeek() {
  const { checkoutState } = useContext(CheckoutContext);

  const { customerName: name, shippingAddress: address } = checkoutState;

  return (
    <div className="address peek">
      {name}
      {name && <br />}
      {address && (
        <>
          {address.line1}
          <br />
          {address.line2}
          {address.line2 && <br />}
          {address.city}, {address.state} {address.zip}
        </>
      )}
    </div>
  );
}
