import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IECommerceConfig, IAppState } from "../types";
import { Dispatch } from "../lib/util";
import { getRemoteConfig } from "../lib/fi-api/configApi";

const initialState: IECommerceConfig = {
  products: [],
  siteConfig: {},
  shippingOptions: [],
  loading: true,
  configLoadError: null
};

const name = "config" as const;

const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    set(state: IECommerceConfig, { payload }: PayloadAction<IECommerceConfig>) {
      return payload;
    }
  }
});

export const configActions = {
  ...actions,

  reloadConfig: () => async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(actions.set(initialState));
    const state = getState();
    const exchange = !!(state.session && state.session.impersonating);
    try {
      const remoteConfig = await getRemoteConfig(exchange);
      dispatch(
        actions.set({
          products: remoteConfig.products,
          siteConfig: remoteConfig.config,
          shippingOptions: remoteConfig.shippingOptions,
          loading: false,
          configLoadError: null
        })
      );
    } catch (err) {
      dispatch(
        actions.set({
          ...initialState,
          loading: false,
          configLoadError: err.message
        })
      );
    }
  }
};

export const configReducer = reducer;
export const configInitialState = { [name]: initialState };
