import { useState, useEffect } from "react";

import * as events from "../../lib/analytics/events";
import * as types from "../../types";
import {
  ProductType,
  productIsComplex,
  variantOptionsEqual
} from "./ProductProps";

/**
 * A react hook to add tracking to a product page.
 * Will log a single PageLoad at first, and then multiple VariantChanged
 * events as it is called further times without clearing the state.
 *
 * @param product The product being displayed
 * @param variant The variant of the product currently being displayed
 */
export default function useTracking(
  product: ProductType,
  variant?: types.IVariant
) {
  const [hasTrackedPageLoad, setTrackedPageLoad] = useState<boolean>(false);
  const [lastTrackedOptions, setLastTrackedOptions] = useState<
    types.IVariantOptions | undefined
  >(undefined);

  useEffect(() => {
    let reason: events.ProductViewReason | undefined;

    if (
      lastTrackedOptions &&
      productIsComplex(product) &&
      variant &&
      !variantOptionsEqual(lastTrackedOptions, variant.options)
    ) {
      const previousVariant = product.variants.find(v =>
        variantOptionsEqual(v.options, lastTrackedOptions)
      );
      if (previousVariant) {
        reason = { kind: "VariantChanged", previousVariant };
      }
    } else if (!hasTrackedPageLoad) {
      // Initial page load
      reason = { kind: "PageLoad" };
      setTrackedPageLoad(true);
    }

    if (reason) {
      events.productViewed({
        product: product,
        variant,
        reason
      });
    }
    if (variant) {
      setLastTrackedOptions(variant.options);
    }
  }, [variant, lastTrackedOptions, hasTrackedPageLoad, product]);
}
