import React, { useEffect, useState } from "react";
import * as types from "../../../../types";

// Have to rename this import here so the Image from React isn't hidden
// so that we can use it for preloading
import { Image as FiImage } from "../../../../components/Image";
import { productIsComplex } from "../../ProductProps";

export interface IProductImageGalleryProps {
  selectedVariant?: types.IVariant;
  product: types.IComplexProduct | types.ISimpleProduct;
}

const ProductImageGallery = ({
  selectedVariant,
  product
}: IProductImageGalleryProps) => {
  useEffect(() => {
    // Preload the images for the product variants so that switching between them is faster
    if (productIsComplex(product)) {
      product.variants.forEach(variant => {
        variant.productImages?.forEach(picture => {
          const img = new Image();
          img.src = picture.url;
        });
      });
    }
  });

  let images: types.IImage[];
  let selectedImage: types.IImage;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  if (productIsComplex(product)) {
    images =
      selectedVariant?.productImages ?? product.variants[0].productImages ?? [];
  } else {
    images = product.productImages ?? [];
  }

  if (selectedImageIndex < images.length) {
    selectedImage = images[selectedImageIndex];
  } else {
    setSelectedImageIndex(0);
    selectedImage = images[0];
  }

  return (
    <>
      <div className="featured-image-container">
        <div>
          <FiImage image={selectedImage} />
        </div>
      </div>
      {images.length > 1 && (
        <div className="more-photos">
          {images.map((image, index) => (
            <div
              onClick={() => {
                setSelectedImageIndex(index);
              }}
              key={index}
              className="gallery-photo"
            >
              <FiImage image={image} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductImageGallery;
