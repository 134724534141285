import React, { useReducer, useMemo } from "react";
import { IGiftCardProduct, ILineItem } from "../../types";
import { expectUnreachable } from "../../lib/util";
import GiftCardContext, {
  IGiftCardState,
  GiftCardAction,
  setErrors,
  emptyGiftCardState
} from "./GiftCardContext";
import { Redirect } from "react-router-dom";
import classNames from "classnames";

import {
  GiftCardPackage,
  GiftCardDetails,
  GiftCardPicker,
  GiftCardPageHeader
} from "./components";
import useTracking from "./useTracking";
import useCheckout from "./useCheckout";

interface IGiftCardProps {
  giftCardProducts: IGiftCardProduct[];
  addLineItem(lineItem: ILineItem): void;
}

export default function GiftCard({
  giftCardProducts,
  addLineItem
}: IGiftCardProps) {
  const initialState: IGiftCardState = useMemo((): IGiftCardState => {
    return {
      ...emptyGiftCardState,
      giftCardProducts
    };
  }, [giftCardProducts]);

  const [giftCardState, giftCardDispatch] = useReducer(
    (state: IGiftCardState, action: GiftCardAction) => {
      if (action.type === "SetSelectedId") {
        return { ...state, selectedId: action.newSelectedId };
      } else if (action.type === "UpdateGiftCardDelivery") {
        return {
          ...state,
          delivery: { ...state.delivery, ...action.newValues }
        };
      } else if (action.type === "SetErrors") {
        return { ...state, errors: action.errors };
      } else if (action.type === "ClearError") {
        const { [action.errorName]: ignoredValue, ...newErrors } = state.errors;
        return { ...state, errors: newErrors };
      } else if (action.type === "SetDeliverAtIsSet") {
        return { ...state, deliverAtIsSet: action.newValue };
      } else if (action.type === "SetSelectedCategory") {
        return { ...state, selectedCategory: action.newValue };
      } else {
        expectUnreachable(action);
        return state;
      }
    },
    initialState
  );

  const canContinue =
    giftCardState.selectedId !== null &&
    giftCardState.selectedCategory !== null;

  const product = useMemo(
    () => giftCardProducts.find(p => giftCardState.selectedId === p.id),
    [giftCardProducts, giftCardState.selectedId]
  );

  // Analytics
  useTracking(product);

  const { addedToBag, addToBag } = useCheckout(addLineItem, e => {
    giftCardDispatch(setErrors(e));
  });

  if (addedToBag) {
    return <Redirect to="/bag" />;
  }

  const checkoutClasses = classNames("flow-button--black", {
    "flow-button--disabled": !canContinue
  });

  const cardPickerEnabled = !!giftCardState.selectedCategory;
  const cardDetailsEnabled = !!giftCardState.selectedId;

  return (
    <GiftCardContext.Provider value={{ giftCardState, giftCardDispatch }}>
      <div className="gift-card-page">
        <GiftCardPageHeader />
        <div>
          <GiftCardPackage />
          <GiftCardPicker enabled={cardPickerEnabled} />
          <GiftCardDetails enabled={cardDetailsEnabled} />
        </div>
        <div className="single-column-container gift-card-actions">
          <div className="single-column">
            <div
              className={checkoutClasses}
              onClick={() => {
                if (canContinue && product) {
                  addToBag(product, giftCardState);
                }
              }}
            >
              Continue
            </div>
          </div>
        </div>
      </div>
    </GiftCardContext.Provider>
  );
}
