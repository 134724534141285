import React from "react";

export default function OtherFeatures() {
  return (
    <div className="extra-info">
      <div className="warranty">
        <img alt="A Fi base" src="/base.svg" />
        <p>1 year warranty</p>
      </div>
      <div className="free-ship">
        <img alt="A shipping box" src="/free_shipping.svg" />
        <p>Free ground shipping</p>
      </div>
      <div className="xp-ship">
        <img alt="A shipping box" src="/xp_shipping.svg" />
        <p>1-2 day shipping available</p>
      </div>
    </div>
  );
}
