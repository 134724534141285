import {
  ProductType,
  ILineItem,
  ICart,
  ICouponDetails,
  IGiftCardDetails,
  IReferralDetails,
  fakeRefereeRewardForPricingCouponCode
} from "../types";
import RecurlyProvider from "./RecurlyProvider";
import {
  expectUnreachable,
  centsToDollars,
  getProductsBySku,
  Result,
  getRefereeRewardItemIfApplicable,
  logInternalError,
  decimalize
} from "./util";
import store from "./store";
import { isAxiosError, webApiClient } from "./fi-api/apiUtils";
import ApolloClient from "apollo-client";
import { mapCartToVariables, previewPurchaseQuery } from "./purchase";
import { gqlTypes } from "../types/gql";

interface IItemForRepricing {
  type: ProductType;
  price: string;
  sku: string;
  quantity: number;
  taxExempt?: boolean;
}

/**
 * This function takes duplciated Addon items in a line item list and coalesces them into a
 * single addon with a potentially >1 quantity. The Recurly repricing API does not appear
 * to correctly price duplicate items unless this is performed.
 *
 * @param subscriptionsAsAddons Convert subscriptions into addons which will price in
 *   their full amount immediately.
 */
export function coalesceAddons(
  lineItems: ILineItem[],
  subscriptionsAsAddons: boolean
): IItemForRepricing[] {
  const addonsBySkuMap = new Map<string, IItemForRepricing>();
  const finalItems: IItemForRepricing[] = [];
  for (const lineItem of lineItems) {
    const itemForRepricing = {
      type: lineItem.type,
      price: lineItem.price,
      sku: lineItem.sku,
      quantity: lineItem.quantity,
      taxExempt: lineItem.type === ProductType.GiftCard
    };
    if (
      lineItem.type === ProductType.Addon ||
      lineItem.type === ProductType.GiftCard ||
      subscriptionsAsAddons
    ) {
      const coalescedItem = addonsBySkuMap.get(lineItem.sku);
      if (coalescedItem) {
        coalescedItem.quantity += itemForRepricing.quantity;
      } else {
        addonsBySkuMap.set(itemForRepricing.sku, {
          ...itemForRepricing,
          ...(subscriptionsAsAddons &&
          lineItem.type === ProductType.Subscription
            ? { type: ProductType.Addon, taxExempt: true }
            : {})
        });
      }
    } else if (lineItem.type === ProductType.Subscription) {
      finalItems.push(lineItem);
    } else {
      expectUnreachable(lineItem.type);
    }
  }
  finalItems.push(...Array.from(addonsBySkuMap.values()));
  return finalItems;
}

async function applyLineItemsToPricing(
  lineItems: ILineItem[],
  pricing: Recurly.CheckoutPricing,
  subscriptionsAsAddons: boolean
) {
  const provider = new RecurlyProvider();
  const coalesced = coalesceAddons(lineItems, subscriptionsAsAddons);
  for (const lineItem of coalesced) {
    if (lineItem.type === ProductType.Subscription) {
      const lineItemPrice = await provider.value.Pricing.Subscription();
      lineItemPrice.id = lineItem.sku;

      await lineItemPrice.plan(lineItem.sku, { quantity: lineItem.quantity });
      await lineItemPrice.reprice();

      await pricing.subscription(lineItemPrice);
    } else if (
      lineItem.type === ProductType.Addon ||
      lineItem.type === ProductType.GiftCard
    ) {
      await pricing.adjustment({
        amount: lineItem.price,
        id: lineItem.sku,
        quantity: lineItem.quantity,
        taxExempt: lineItem.taxExempt
      });
    } else {
      expectUnreachable(lineItem.type);
    }
  }
}

export interface ICartPricingOptions {
  /**
   * Price in the selected shipping code.
   */
  shippingCode?: string;
  /**
   * Apply taxes according to this address.
   */
  address?: {
    country: string;
    postal_code: string;
  };
  /**
   * Try to apply the specified coupon.
   */
  applyCoupon?: string;
  /**
   * Try to apply the specified gift card redemption code.
   */
  applyGiftCard?: string;
}

type CouponErrorType = "not-found" | "unknown" | "ineligible";

export class CouponError extends Error {
  readonly type: CouponErrorType;
  /**
   * The underlying Error from Recurly.
   */
  readonly underlying: any;

  constructor(message: string, type: CouponErrorType, underlying: any) {
    super(message);
    this.type = type;
    this.underlying = underlying;
  }

  static notFound(underlying: any) {
    return new CouponError(`Invalid promo code`, "not-found", underlying);
  }

  static unknown(underlying: any) {
    return new CouponError(
      `An unknown error occurred while applying your coupon`,
      "unknown",
      underlying
    );
  }

  static ineligible(message: string) {
    return new CouponError(message, "ineligible", null);
  }
}

type GiftCardErrorType = "not-found" | "unknown" | "purchasing-gift-cards";

export class GiftCardError extends Error {
  readonly type: GiftCardErrorType;
  /**
   * The underlying Error from Recurly.
   */
  readonly underlying: any;

  constructor(message: string, type: GiftCardErrorType, underlying: any) {
    super(message);
    this.type = type;
    this.underlying = underlying;
  }

  static notFound(underlying: any) {
    return new GiftCardError(`Invalid gift card code`, "not-found", underlying);
  }

  static unknown(underlying: any) {
    return new GiftCardError(
      `An error occurred while applying that gift code`,
      "unknown",
      underlying
    );
  }

  static purchasingGiftCards() {
    return new GiftCardError(
      `You cannot use a gift card to purchase another gift card.`,
      "purchasing-gift-cards",
      null
    );
  }
}

type ReferralCodeErrorType = "invalid" | "unknown" | "code_suspended";

export class ReferralCodeError extends Error {
  readonly type: ReferralCodeErrorType;
  readonly underlying?: any;

  constructor(message: string, type: ReferralCodeErrorType, underlying?: any) {
    super(message);
    this.type = type;
    this.underlying = underlying;
  }

  static invalid() {
    return new ReferralCodeError(`Invalid referral code`, "invalid");
  }

  static unknown(underlying: any) {
    return new ReferralCodeError(
      `An unknown error occurred`,
      "unknown",
      underlying
    );
  }

  static codeSuspended() {
    return new ReferralCodeError(`Referral code suspended`, "code_suspended");
  }
}

function isCartEligibleForCoupon(
  cart: ICart,
  couponCode: string
): Result<{}, string> {
  const state = store.getState();
  const productsBySku = getProductsBySku(state.config.products);
  const lineItemArray = Object.values(cart.lineItems);
  const impersonating = !!(state.session && state.session.impersonating);
  // Note: have to allow coupons if the cart is empty so that coupons can be applied from the banner/cookie flow.
  // While the cart is empty the user will be unable to view their bag anyway, and after they add items
  // this other validation will apply.
  if (impersonating || lineItemArray.length === 0) {
    return { kind: "success", value: {} };
  } else {
    let ineligible = true;
    let ineligibleDueToCollar = true;
    for (const { sku } of lineItemArray) {
      const product = productsBySku.get(sku);
      if (
        product &&
        product.couponEligible &&
        (typeof product.couponEligible === "string"
          ? couponCode.startsWith(product.couponEligible)
          : !!product.couponEligible)
      ) {
        ineligible = false;
        ineligibleDueToCollar = typeof product.couponEligible === "boolean";
        break;
      }
    }
    return ineligible
      ? {
          kind: "failure",
          err: ineligibleDueToCollar
            ? `That coupon only applies to Fi collars`
            : `That coupon cannot be applied to this cart`
        }
      : {
          kind: "success",
          value: {}
        };
  }
}

export interface ApplyCartToPricingResult {
  couponDetails: ICouponDetails | undefined;
  giftCardDetails: IGiftCardDetails | undefined;
}

/**
 * Applies all the necessary attributes from `cart` to the `pricing` object.
 *
 * NOTE: It's necessary to call `pricing.reprice()` after this method!
 *
 * @throws {CouponError} If the coupon failed to apply.
 */
export async function applyCartToPricing(
  cart: ICart,
  pricing: Recurly.CheckoutPricing,
  options: ICartPricingOptions = {}
): Promise<ApplyCartToPricingResult> {
  const state = store.getState();

  // Shipping
  if (options.shippingCode) {
    const shippingOption = state.config.shippingOptions.find(
      so => so.code === options.shippingCode
    );
    if (shippingOption && shippingOption.priceInCents > 0) {
      await pricing.adjustment({
        amount: centsToDollars(shippingOption.priceInCents),
        id: shippingOption.code,
        quantity: 1
      });
    }
  }

  // Coupon
  let newCouponDetails: ICouponDetails | undefined;
  let couponCode: string | undefined;
  if (options.applyCoupon) {
    couponCode = options.applyCoupon;
  } else {
    const existingCouponDetails = cart.couponDetails;
    if (existingCouponDetails) {
      couponCode = existingCouponDetails.code;
    }
  }
  if (couponCode) {
    const couponEligibilityResult = isCartEligibleForCoupon(cart, couponCode);
    if (couponEligibilityResult.kind === "failure") {
      throw CouponError.ineligible(couponEligibilityResult.err);
    }
    try {
      newCouponDetails = await pricing.coupon(couponCode);
    } catch (err) {
      throw err.code === "not-found"
        ? CouponError.notFound(err)
        : CouponError.unknown(err);
    }
  }

  // Referral
  let refereeDiscountDollars = 0;
  const refereeRewardItem = getRefereeRewardItemIfApplicable(
    cart,
    state.config.siteConfig.refereeRewardProductId
  );
  if (refereeRewardItem) {
    refereeDiscountDollars = parseInt(refereeRewardItem.price);
  }
  if (refereeDiscountDollars > 0) {
    // Have to do some hacks since the Recurly pricing API doesn't support multiple coupons.
    // Have logged a feature request for that here: https://github.com/recurly/recurly-js/issues/565
    if (pricing.items.coupon) {
      // Add to coupon
      if (
        pricing.items.coupon.discount &&
        pricing.items.coupon.discount.type === "dollars" &&
        pricing.items.coupon.discount.amount &&
        typeof pricing.items.coupon.discount.amount.USD === "number"
      ) {
        pricing.items.coupon.discount.amount.USD += refereeDiscountDollars;
      }
    } else {
      try {
        await pricing.coupon(fakeRefereeRewardForPricingCouponCode);
      } catch (err) {}
    }
  }

  // Gift card
  const giftCardRedemptionCode: string | undefined = options.applyGiftCard
    ? options.applyGiftCard
    : cart.giftCardDetails && cart.giftCardDetails.redemptionCode;
  let newGiftCardDetails: IGiftCardDetails | undefined;
  if (giftCardRedemptionCode) {
    const anyGiftCardsInCart = Object.values(cart.lineItems).some(
      li => li.type === ProductType.GiftCard
    );
    if (anyGiftCardsInCart) {
      throw GiftCardError.purchasingGiftCards();
    }
    try {
      const giftCardData = await pricing.giftCard(giftCardRedemptionCode);
      newGiftCardDetails = {
        redemptionCode: giftCardRedemptionCode,
        unitAmount: giftCardData.unit_amount
      };
    } catch (err) {
      throw err.code === "not-found"
        ? GiftCardError.notFound(err)
        : GiftCardError.unknown(err);
    }
  }

  // Line items
  await applyLineItemsToPricing(
    Object.values(cart.lineItems),
    pricing,
    !!newGiftCardDetails
  );

  // Address (for calculating taxes)
  if (options.address) {
    await pricing.address({
      country: options.address.country,
      postal_code: options.address.postal_code
    });
  }

  await pricing.currency("USD");

  return {
    couponDetails: newCouponDetails,
    giftCardDetails: newGiftCardDetails
  };
}

export interface PriceCartResult extends ApplyCartToPricingResult {
  pricing: Recurly.CheckoutPricingResult;
}

export async function applyTaxesForCheckout(
  client: ApolloClient<any>,
  pricing: Recurly.CheckoutPricing,
  cart: ICart,
  shippingCode: string | null
) {
  const variables = mapCartToVariables(cart, shippingCode);
  let data: gqlTypes.previewPurchase;
  try {
    const result = await client.query<
      gqlTypes.previewPurchase,
      gqlTypes.previewPurchaseVariables
    >({
      query: previewPurchaseQuery,
      variables
    });
    data = result.data;
  } catch (err) {
    logInternalError(err);
    return pricing.price;
  }

  pricing.price.now.taxes = decimalize(data.previewPurchase.taxInCents / 100);
  pricing.price.now.total = decimalize(data.previewPurchase.totalInCents / 100);

  // https://github.com/recurly/recurly-js/blob/42c06f62ddc86ce2bc9e5c5827ff1907ef4f441c/lib/recurly/pricing/index.js#L85
  pricing.emit("change", pricing.price);
  pricing.emit("change:external", pricing.price);

  return pricing.price;
}

export async function priceCartForCheckout(
  client: ApolloClient<any>,
  cart: ICart,
  shippingCode: string | null
): Promise<Recurly.CheckoutPricingResult> {
  const provider = new RecurlyProvider();
  const pricing = provider.value.Pricing.Checkout();
  await applyCartToPricing(cart, pricing, {
    shippingCode: shippingCode || undefined
  });
  await pricing.reprice();
  return await applyTaxesForCheckout(client, pricing, cart, shippingCode);
}

/**
 * Price a cart and return the result.
 */
export async function priceCart(
  cart: ICart,
  options: ICartPricingOptions = {}
): Promise<PriceCartResult> {
  const provider = new RecurlyProvider();
  const pricing = provider.value.Pricing.Checkout();
  const props = await applyCartToPricing(cart, pricing, options);
  return {
    ...props,
    pricing: await pricing.reprice()
  };
}

type CouponApplicationResult = Result<ICouponDetails, CouponError>;
type GiftCardApplicationResult = Result<IGiftCardDetails, GiftCardError>;
type ReferralCodeApplicationResult = Result<
  IReferralDetails,
  ReferralCodeError
>;

/**
 * Attempt to apply a coupon to the cart.
 */
export async function applyCouponToCart(
  originalCart: ICart,
  couponCode: string
): Promise<CouponApplicationResult> {
  try {
    const result = await priceCart(originalCart, { applyCoupon: couponCode });
    if (!result.couponDetails) {
      throw CouponError.unknown(new Error(`Missing coupon details!`));
    }
    return {
      kind: "success",
      value: result.couponDetails
    };
  } catch (err) {
    if (err instanceof CouponError) {
      return { kind: "failure", err };
    }
    throw err;
  }
}

/**
 * Attempt to apply a gift card to the cart.
 */
export async function applyGiftCardToCart(
  originalCart: ICart,
  redemptionCode: string
): Promise<GiftCardApplicationResult> {
  try {
    const result = await priceCart(originalCart, {
      applyGiftCard: redemptionCode
    });
    if (!result.giftCardDetails) {
      throw GiftCardError.unknown(new Error(`Missing gift card details!`));
    }
    return {
      kind: "success",
      value: result.giftCardDetails
    };
  } catch (err) {
    if (err instanceof GiftCardError) {
      return { kind: "failure", err };
    }
    throw err;
  }
}

export async function checkReferralCode(
  referralCode: string
): Promise<ReferralCodeApplicationResult> {
  try {
    await webApiClient.get("/api/ecommerce/checkreferralcode", {
      params: { code: referralCode }
    });
    return {
      kind: "success",
      value: { referralCode }
    };
  } catch (err) {
    if (isAxiosError(err) && err.response) {
      if (err.response.status === 404) {
        return { kind: "failure", err: ReferralCodeError.invalid() };
      }
      if (
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.error &&
        err.response.data.error.code === "referral_code_blacklisted"
      ) {
        return { kind: "failure", err: ReferralCodeError.codeSuspended() };
      }
    }
    return { kind: "failure", err: ReferralCodeError.unknown(err) };
  }
}
