import { useEffect, useState } from "react";

import * as events from "../../lib/analytics/events";
import { ICart } from "../../types";

const useTracking = ({ lineItems }: ICart) => {
  const [hasLoggedPageLoad, setHasLoggedPageLoad] = useState(false);
  useEffect(() => {
    if (!hasLoggedPageLoad && Object.keys(lineItems).length > 0) {
      events.cartPage.viewed(lineItems);
      setHasLoggedPageLoad(true);
    }
  }, [lineItems, hasLoggedPageLoad]);
};

export default useTracking;
