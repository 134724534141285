import { connect } from "react-redux";

import { IAppState } from "../types";
import Accessories from "../views/Accessories";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (
  state: IAppState,
  ownProps: RouteComponentProps<{}>
) => ({
  products: state.config.products,
  linkFrom: (ownProps.location.state || {}).from
});

export default connect(mapStateToProps)(Accessories);
