import React, { useState, useCallback, useMemo } from "react";
import { Redirect, Link } from "react-router-dom";

import * as events from "../../lib/analytics/events";
import * as types from "../../types";
import { generateID, createSubscriptionLineItem } from "../../lib/util";

import PaidSubscriptionTile from "./components/PaidSubscriptionTile";
import { ProductBackButton, ProductLinkLoc } from "../../lib/productLinks";
import {
  SubscriptionIntro,
  SubscriptionFeatures
} from "./components/SubscriptionIntro";
import useSubscriptionCartInfo from "../../hooks/useSubscriptionCartInfo";

interface ISubscriptionPickerProps {
  subscriptions: types.ISubscriptionProduct[];
  fromUpsell: boolean;
  addLineItem(lineItem: types.ILineItem): void;
}

function SubscriptionPicker({
  subscriptions,
  fromUpsell,
  addLineItem
}: ISubscriptionPickerProps) {
  const [redirectToBag, setRedirectToBag] = useState(false);

  const {
    subscriptionsInCart,
    subscriptionProductsInCart
  } = useSubscriptionCartInfo();

  // If the user hits continue, add from 1 subscription up to
  // N where N is how many short we are.
  const subscriptionsToAdd = useMemo(
    () =>
      subscriptionsInCart === 0 ? Math.max(1, subscriptionProductsInCart) : 1,
    [subscriptionProductsInCart, subscriptionsInCart]
  );

  const selectSubscription = useCallback(
    (id: string) => {
      const subscription = subscriptions.find(s => s.id === id);

      if (!subscription) {
        return;
      }

      for (let i = 0; i < subscriptionsToAdd; i++) {
        addLineItem(createSubscriptionLineItem(generateID(), subscription));
        events.addToBag({ product: subscription });
      }

      setRedirectToBag(true);
    },
    [subscriptionsToAdd, addLineItem, subscriptions]
  );

  if (redirectToBag) {
    return <Redirect to="/bag" />;
  }

  return (
    <>
      {fromUpsell ? (
        <ProductBackButton from={ProductLinkLoc.Cart} />
      ) : (
        <ProductBackButton from={ProductLinkLoc.Collar} />
      )}
      <div className="subscription-container">
        <SubscriptionIntro />
        <div>
          <div className="subscription-paid subscription-group-container">
            {subscriptions.map((subscription, i) => (
              <PaidSubscriptionTile
                subscription={subscription}
                index={i}
                key={i}
                onClick={() => selectSubscription(subscription.id)}
              />
            ))}
          </div>

          <SubscriptionFeatures />

          <div className="subscription-free">
            <Link to="/bag">I don&rsquo;t need a plan</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPicker;
