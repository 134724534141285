import React from "react";
import { useDispatch } from "react-redux";
import { cartActions } from "../reducers/cart";

export default function ReferralBanner() {
  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(cartActions.clearReferral());
  };

  return (
    <div className="coupon-banner">
      <div className="coupon-banner--content">
        You got a free band of your choice because you used a referral code!
      </div>
      <div className="coupon-banner--dismiss-button" onClick={onDismiss}>
        <span className="coupon-banner--dismiss-text">Remove code</span>
        <span className="coupon-banner--dismiss-icon" />
      </div>
    </div>
  );
}
