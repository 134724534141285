import * as React from "react";

import { IEventDetails } from "../lib/analytics/events";
import { IWindow } from "../lib/Window";

export interface ITrackedExternalLinkProps
  extends React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    IEventDetails {}

export default class TrackedExternalLink extends React.Component<
  ITrackedExternalLinkProps
> {
  public linkRef: React.RefObject<any>;

  constructor(props: ITrackedExternalLinkProps) {
    super(props);
    this.linkRef = React.createRef();
  }

  public componentDidMount() {
    const extendedWindow: IWindow = window;
    if (this.linkRef.current && extendedWindow.analytics) {
      extendedWindow.analytics.trackLink(
        this.linkRef.current,
        this.props.eventName,
        this.props.eventProps || {}
      );
    }
  }

  public render() {
    const { children, eventName, eventProps, ...extraProps } = this.props;
    return (
      <a {...extraProps} ref={this.linkRef}>
        {children}
      </a>
    );
  }
}
