import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IExperimentState } from "../types";

const initialState: IExperimentState = {
  experiments: []
};

interface IAddToExperiments {
  experiments: string[];
}

const name = "experiment" as const;

const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    addToExperiments(
      state: IExperimentState,
      { payload }: PayloadAction<IAddToExperiments>
    ) {
      const newExperiments = new Set<string>();
      for (const experimentList of [
        state && state.experiments,
        payload.experiments
      ]) {
        if (experimentList) {
          for (const experiment of experimentList) {
            newExperiments.add(experiment);
          }
        }
      }
      return {
        experiments: Array.from(newExperiments)
      };
    }
  }
});

export const experimentActions = actions;
export const experimentReducer = reducer;
export const experimentInitialState = { [name]: initialState };
