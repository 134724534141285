import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import Popup from "reactjs-popup";
import * as events from "../../../../lib/analytics/events";
import SmallSizeWarning from "./SmallSizeWarning";

interface IAddToBagProps {
  needsSmallSizeWarning?: boolean;
  onAddToBag: () => void;
  className: string;
  disabled?: boolean;
}

const AddToBagButton = ({
  needsSmallSizeWarning,
  onAddToBag,
  className,
  disabled
}: IAddToBagProps): React.ReactElement => {
  let defaultButtonOnClick: React.MouseEventHandler | undefined;

  const buttonDiv = React.createRef<HTMLButtonElement>();

  const [isBuying, setIsBuying] = useState(false);

  const handleAnimationEnd = useCallback(() => {
    setIsBuying(false);
  }, []);

  // Attach a listener to the shopping bag icon to handle animation ends
  useEffect(() => {
    if (buttonDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = buttonDiv.current;
      element.addEventListener("animationend", eventListener);
      return () => {
        element.removeEventListener("animationend", eventListener);
      };
    }
  }, [buttonDiv, handleAnimationEnd]);

  const doBuy = useCallback(() => {
    if (disabled) {
      return;
    }

    if (!isBuying) {
      setIsBuying(true);
      onAddToBag();
    }
  }, [disabled, isBuying, onAddToBag]);

  if (needsSmallSizeWarning !== true) {
    defaultButtonOnClick = e => {
      e.preventDefault();
      doBuy();
    };
  }

  const buttonClasses = classNames(className, {
    "is-buying": isBuying
  });

  const addButton = (
    <button
      className={buttonClasses}
      onClick={defaultButtonOnClick}
      ref={buttonDiv}
      disabled={disabled}
    >
      <div className="add-to-bag-contents">
        <div className="add-to-bag-added">Added!</div>
        <div>Add to Bag</div>
      </div>
    </button>
  );

  return needsSmallSizeWarning ? (
    <Popup
      trigger={addButton}
      modal
      disabled={disabled}
      className="small-size-warning-popup"
      contentStyle={{ width: undefined }}
      onOpen={() => events.collarPage.smallSizeWarningShown()}
    >
      {close => (
        <SmallSizeWarning
          onConfirm={() => {
            close();
            doBuy();
          }}
          onClose={close}
        />
      )}
    </Popup>
  ) : (
    addButton
  );
};

export default AddToBagButton;
