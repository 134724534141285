import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import ExpanderTriangle from "../../../assets/images/accordion-triangle.svg";

export interface IAccordionSectionProps {
  title: string;
  expanded: boolean;
  disabled?: boolean;
  onUserSelectedHeader: VoidFunction;
}

/**
 * The <AccordionPeek> element is used to give the AccordionSection a
 * small "result view" to render only if the view is enabled and collapsed.
 */
export function AccordionPeek({ children }: PropsWithChildren<{}>) {
  return <div className="accordion-peek">{children}</div>;
}

const AccordionSection = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<IAccordionSectionProps>
>(
  (
    {
      title,
      children,
      expanded,
      disabled,
      onUserSelectedHeader
    }: PropsWithChildren<IAccordionSectionProps>,
    ref?: React.Ref<HTMLDivElement>
  ) => {
    const sectionClasses = classNames("accordion-section", {
      expanded,
      disabled,
      collapsed: !expanded
    });

    return (
      <div className={sectionClasses} ref={ref}>
        <div className="accordion-header" onClick={onUserSelectedHeader}>
          <h2>{title}</h2>
          <div className="expander-button">
            <img src={ExpanderTriangle} alt="Button to expand this section" />
          </div>
        </div>
        <div className="accordion-contents">{!disabled && children}</div>
      </div>
    );
  }
);

export default AccordionSection;
