import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import * as types from "../../types";

import {
  findGiftCardProductFromDetails,
  generateID,
  createSubscriptionLineItem,
  getProductsBySku,
  isSubscriptionProduct
} from "../../lib/util";

import useSubscriptionCartInfo, {
  ISubscriptionCartInfo
} from "../../hooks/useSubscriptionCartInfo";

export interface ISubscriptionAwareResult extends ISubscriptionCartInfo {
  /**
   * A function for you to call to possibly add the subscription that was gifted
   * along with this product, if any.
   */
  maybeAddSubscriptionFromGiftCard: () => void;
}

/**
 * Gives a product page awareness of subscriptions - how many are in the cart vs.
 * subscription-requiring product and a hook for automatically adding one that
 * was gifted to the user via a Gift Card.
 * @param product The product that was being added.
 * @param addLineItem The function used to add a line item to the user's bag.
 */
function useSubscriptionAware(
  product: types.IProduct,
  addLineItem: (lineItem: types.ILineItem) => void
): ISubscriptionAwareResult {
  // Load the available products, the cart, and the product map
  const products = useSelector(
    (state: types.IAppState) => state.config.products
  );
  const cart = useSelector((state: types.IAppState) => state.cart);
  const productMap = useMemo(() => getProductsBySku(products), [products]);

  const {
    subscriptionsInCart,
    subscriptionProductsInCart
  } = useSubscriptionCartInfo();

  const maybeAddSubscriptionFromGiftCard = useCallback(
    /**
     * @returns If the user is purchasing an item given to them on a gift card,
     *   returns the subscription from the gift.
     */
    (): types.ILineItem | undefined => {
      if (!product.subscriptionRequired || !cart.giftCardDetails) {
        return undefined;
      }

      const giftCardProduct = findGiftCardProductFromDetails(
        cart.giftCardDetails,
        products
      );

      if (!giftCardProduct || !giftCardProduct.addSubscriptionSku) {
        return;
      }

      if (subscriptionsInCart > 0) {
        // If any subscriptions have already been added to the cart, bail on this flow.
        return;
      }

      const subscriptionProduct = productMap.get(
        giftCardProduct.addSubscriptionSku
      );

      if (!subscriptionProduct || !isSubscriptionProduct(subscriptionProduct)) {
        return;
      }

      const itemID = generateID();
      addLineItem(createSubscriptionLineItem(itemID, subscriptionProduct));
    },
    [product, products, cart, addLineItem, productMap, subscriptionsInCart]
  );

  return {
    maybeAddSubscriptionFromGiftCard,
    subscriptionsInCart,
    subscriptionProductsInCart
  };
}

export default useSubscriptionAware;
