import React from "react";

import * as events from "../../lib/analytics/events";
import { ICouponDetails } from "../../types";
import RemoveButton from "../RemoveButton";

const CouponLineItem = ({
  coupon,
  removeCoupon
}: {
  coupon: ICouponDetails;
  removeCoupon?: () => void;
}) => {
  let priceDescription: string = "";
  const discount = coupon.discount;
  if (discount.type === "dollars") {
    priceDescription = `-$${discount.amount.USD}`;
  } else if (discount.type === "percent") {
    priceDescription = `-${discount.rate * 100}%`;
  }

  return (
    <div className="split-container line-item coupon-line-item">
      <div className="line-item-body">
        <div className="line-item-details">
          <div className="product-name">
            <h4>Discount</h4>
          </div>
          <div className="line-item-details">
            <span className="coupon-line-item--description">
              <img alt="Gift Box" src="/gift_box.svg" />
              {coupon.code} applied!
            </span>
          </div>
        </div>
        <div className="product-pricing">
          <div className="line-item-price">
            <h4>{priceDescription}</h4>
          </div>
          {removeCoupon && (
            <RemoveButton
              onClick={() => {
                removeCoupon();
                events.cartPage.couponRemoved(coupon.code);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponLineItem;
