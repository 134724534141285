import React from "react";

import { ModuleItem } from "../AccessoryTypes";
import ItemTile from "./ItemTile";

interface IAccessoryGroupProps {
  title: string;
  subtitle: string;
  items: ModuleItem[];
}

const AccessoryGroup = ({ title, subtitle, items }: IAccessoryGroupProps) => (
  <div className="accessories-module">
    <div className="accessories-module-header">
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
    <div className="accessories-module-items">
      {items.map((item, i) => (
        <ItemTile key={i} item={item} />
      ))}
    </div>
  </div>
);

export default AccessoryGroup;
