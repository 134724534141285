import React from "react";
import { Mutation } from "react-apollo";

import { createPurchaseMutation } from "../../../../lib/purchase";

import * as types from "../../../../types";
import { gqlTypes } from "../../../../types/gql";

import Confirm from "./Confirm";

interface IPurchaseContainerProps {
  shippingOptions: types.IShippingOption[];
}

export default function PurchaseContainer({
  shippingOptions
}: IPurchaseContainerProps) {
  return (
    <Mutation<gqlTypes.createPurchase, gqlTypes.createPurchaseVariables>
      mutation={createPurchaseMutation}
    >
      {(mutation, { loading }) => {
        return (
          <Confirm
            shippingOptions={shippingOptions}
            purchase={mutation}
            loading={loading}
          />
        );
      }}
    </Mutation>
  );
}
