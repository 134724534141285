import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  MouseEvent
} from "react";
import classNames from "classnames";

import { useDispatch } from "react-redux";
import * as events from "../../lib/analytics/events";
import * as types from "../../types";
import CJEventIFrame from "./CJEventIFrame";
import SaveAccount from "../../components/SaveAccount";
import FloodlightIFrame from "../../lib/analytics/FloodlightIFrame";
import { clearGlobalVariablesForConversionTracking } from "../../lib/analytics/analyticsUtils";
import ThankYouPageReferralsSection from "./ReferralsSection";
import { cartActions } from "../../reducers/cart";
import { isKiosk, logInternalError } from "../../lib/util";
import { logout } from "../../lib/fi-api/authentication";
import { Redirect } from "react-router-dom";

interface IThankYouProps {
  cart: types.ICart;
  orderId: string | undefined;
  noPasswordInSession: boolean;
}

export default function ThankYou({
  cart,
  orderId,
  noPasswordInSession
}: IThankYouProps) {
  const dispatch = useDispatch();

  const [hasTrackedPageLoad, setHasTrackedPageLoad] = useState(false);
  const [allDone, setAllDone] = useState(false);

  useEffect(() => {
    if (!hasTrackedPageLoad) {
      // On mount: Track view, clear cart
      events.thankYou.viewed();
      dispatch(cartActions.resetCart({ resetCoupon: true }));
      setHasTrackedPageLoad(true);
    }

    // On unmount: clear tracking variables
    return () => {
      clearGlobalVariablesForConversionTracking();
    };
  }, [dispatch, hasTrackedPageLoad, setHasTrackedPageLoad]);

  const _trackingTags = useMemo(() => {
    // NOTE: These tags are noops in staging mode.
    if (orderId) {
      return (
        <>
          <FloodlightIFrame orderId={orderId} cart={cart} />
          <CJEventIFrame orderId={orderId} orderedCart={cart} />
        </>
      );
    } else {
      return null;
    }
  }, [cart, orderId]);

  const handleResetState = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      try {
        await logout();
        setAllDone(true);
      } catch (e) {
        logInternalError(e);
      }
    },
    [setAllDone]
  );

  const renderAsKiosk = isKiosk();

  if (allDone) {
    return <Redirect to="/" />;
  }

  const pageClasses = classNames("overlay", "thank-you-page", {
    kiosk: renderAsKiosk
  });

  return (
    <div className="split-container">
      {_trackingTags}
      <div className={pageClasses}>
        <div className="overlay__content single-column-container">
          <div className="header">
            <h1>Thank you</h1>
            <p>
              Keep an eye out for an email containing more info about your
              order.
            </p>
            {renderAsKiosk && (
              <button
                className="flow-button--yellow all-done"
                onClick={handleResetState}
              >
                Great - All done!
              </button>
            )}
          </div>
          {!renderAsKiosk && noPasswordInSession && (
            <div className="split-container thank-you-main-container">
              <SaveAccount />
            </div>
          )}
          {!renderAsKiosk && <ThankYouPageReferralsSection />}
        </div>
      </div>
    </div>
  );
}
