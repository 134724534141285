import React, { useEffect, useCallback, useState } from "react";
import * as qs from "query-string";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import { ReferralRewardType } from "../types/gql-op-types";
import { SimpleDismissableModal } from "./modals";
import * as events from "../lib/analytics/events";

function Header() {
  return (
    <div className="referrals-header full-width-breakout">
      <div className="single-column-container referrals-header-inner">
        <h4>Refer a friend</h4>
        <div>
          <img src="/referrals/referral_dog.svg" alt="A dog" />
        </div>
        <div>
          Know someone with a dog?
          <br />
          Refer a friend to Fi → you both get free stuff.
        </div>
      </div>
    </div>
  );
}

function ToastController({
  children
}: {
  children: (triggerToast: () => void) => JSX.Element | JSX.Element[];
}) {
  const [shown, setShown] = useState(false);
  const contentDiv = React.createRef<HTMLDivElement>();

  const handleAnimationEnd = useCallback(() => {
    setShown(false);
  }, []);

  useEffect(() => {
    if (contentDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = contentDiv.current;
      element.addEventListener("animationend", eventListener);
      return () => {
        element.removeEventListener("animationend", eventListener);
      };
    }
  }, [contentDiv, handleAnimationEnd]);

  const triggerToast = () => {
    if (shown) {
      return;
    }
    setShown(true);
  };

  return (
    <>
      <div className="referral-code-toast">
        <div
          className={classNames("referral-code-toast-content", { shown })}
          ref={contentDiv}
        >
          Code copied to clipboard!
        </div>
      </div>
      {children(triggerToast)}
    </>
  );
}

const getShareInformation = (code: string, source: string) => ({
  title: `Check out the Fi Smart Dog Collar!`,
  text: `Use my referral code ${code} when buying a Fi collar and get a free additional band with your purchase!`,
  url: `https://shop.tryfi.com/r/${code}/?utm_source=${source}`
});

interface ReferralCodeProps {
  code: string;
  source: string;
}

function WebShareButton({ code, source }: ReferralCodeProps) {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    // Web share API: https://alligator.io/js/web-share-api/
    (navigator as any).share(getShareInformation(code, source));
  };

  return (
    <div className="referral-share-button" onClick={handleShare}>
      Share
    </div>
  );
}

function AndroidShareButton({ code, source }: ReferralCodeProps) {
  const handleShare = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    events.referrals.shareClicked(true);
    window.android.share(JSON.stringify(getShareInformation(code, source)));
  };

  return (
    <div className="referral-share-button" onClick={handleShare}>
      Share
    </div>
  );
}

function FallbackShareButton({ code, source }: ReferralCodeProps) {
  const shareInformation = getShareInformation(code, source);
  const mailtoLink = `mailto:?${qs.stringify({
    subject: shareInformation.title,
    body: `${shareInformation.text}\n\n${shareInformation.url}`
  })}`;

  return (
    <SimpleDismissableModal
      trigger={<div className="referral-share-button">Share</div>}
      onOpen={() => events.referrals.shareClicked(false)}
    >
      <div>
        <a
          className="link link--underlined"
          href={mailtoLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          ✉ Share via email
        </a>
      </div>
    </SimpleDismissableModal>
  );
}

function ShareButton({ code, source }: ReferralCodeProps) {
  return (navigator as any).share ? (
    <WebShareButton code={code} source={source} />
  ) : window.android && window.android.share ? (
    <AndroidShareButton code={code} source={source} />
  ) : (
    <FallbackShareButton code={code} source={source} />
  );
}

export function ReferralCode({ code, source }: ReferralCodeProps) {
  return (
    <div className="referral-code-container">
      <ToastController>
        {triggerToast => {
          const handleCodeClick = () => {
            events.referrals.codeCopied();
            copy(code);
            triggerToast();
          };

          return (
            <div className="referral-code-inner">
              <div className="referral-code-code" onClick={handleCodeClick}>
                {code}
              </div>
              <ShareButton code={code} source={source} />
            </div>
          );
        }}
      </ToastController>
    </div>
  );
}

function Main({ referralCode }: { referralCode: string }) {
  return (
    <div className="referrals-main full-width-breakout">
      <div className="single-column-container">
        <div className="single-column referrals-main-inner">
          <h4>Your invite code</h4>
          <ReferralCode code={referralCode} source="referrals" />
          <div className="referrals-main-information">
            Anytime someone uses your code they receive a free band with their
            order.
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * SVG for a series of circles indicating referral unlocks
 */
function ReferralUnlocks({
  numTotal,
  numUnlocked
}: {
  numTotal: number;
  numUnlocked: number;
}) {
  const radius = 5;
  const lineLength = 13;
  const totalWidth = radius * 2 * numTotal + lineLength * (numTotal - 1);
  const totalHeight = radius * 2;
  const unlockedColor = "#2CB355";
  const lockedColor = "#B2B2B2";
  const elems: JSX.Element[] = [];
  for (let i = 0; i < numTotal; i++) {
    const unlocked = i < numUnlocked;
    const startX = i * (radius * 2 + lineLength);
    elems.push(
      <circle
        key={`circle${i}`}
        cx={radius + startX}
        cy={radius}
        r={radius}
        style={
          unlocked
            ? {
                fill: unlockedColor
              }
            : {
                fill: "rgba(0,0,0,0)",
                stroke: lockedColor,
                strokeWidth: 2
              }
        }
      />
    );
    if (i !== numTotal - 1) {
      elems.push(
        <line
          key={`line${i}`}
          x1={startX + radius * 2}
          y1={radius}
          x2={startX + radius * 2 + lineLength}
          y2={radius}
          style={{
            stroke: unlocked ? unlockedColor : lockedColor,
            strokeWidth: 2
          }}
        />
      );
    }
  }
  return (
    <svg
      width={`${totalWidth}px`}
      height={`${totalHeight}px`}
      viewBox={`0 -1 ${totalWidth} ${totalHeight + 2}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {elems}
    </svg>
  );
}

interface ReferralRewardProps {
  unlockedRewards: ReferralRewardType[];
  lockedRewards: ReferralRewardType[];
  countDescription: string;
  title: string;
  subtitle?: string;
  className: string;
}

function ReferralReward({
  countDescription,
  unlockedRewards,
  lockedRewards,
  title,
  subtitle,
  className
}: ReferralRewardProps) {
  const numTotal = lockedRewards.length;
  const numUnlocked = unlockedRewards.filter(r => lockedRewards.includes(r))
    .length;
  return (
    <div className={classNames("referral-reward", className)}>
      <div className="referral-reward-unlocks">
        <div className="referral-reward-unlocks-progress">
          <ReferralUnlocks numTotal={numTotal} numUnlocked={numUnlocked} />
        </div>
        <div>{countDescription}</div>
      </div>
      <div className="referral-reward-details">
        <h5>{title}</h5>
        {subtitle && <div>{subtitle}</div>}
      </div>
    </div>
  );
}

function RewardSeparator() {
  return <div className="referral-reward-separator" />;
}

export function Tiers({
  unlockedRewards,
  className
}: {
  unlockedRewards: ReferralRewardType[];
  className?: string;
}) {
  return (
    <div className={classNames("referrals-tiers", className)}>
      <div className="single-column-container">
        <div className="single-column referrals-tiers-inner">
          <h4>Reward Tiers</h4>
          <div className="referral-reward-list">
            <ReferralReward
              unlockedRewards={unlockedRewards}
              lockedRewards={[
                ReferralRewardType.FIRST_FREE_BAND,
                ReferralRewardType.SECOND_FREE_BAND
              ]}
              countDescription="1-2 referrals"
              title="Free band of your choice"
              subtitle="For every referral in this tier"
              className="free-band"
            />
            <RewardSeparator />
            <ReferralReward
              unlockedRewards={unlockedRewards}
              lockedRewards={[ReferralRewardType.ONE_YEAR_EXTENSION]}
              countDescription="3 referrals"
              title="1 year subscription"
              className="one-year"
            />
            <RewardSeparator />
            <ReferralReward
              unlockedRewards={unlockedRewards}
              lockedRewards={[ReferralRewardType.TWO_YEAR_EXTENSION]}
              countDescription="5 referrals"
              title="2 year subscription"
              className="two-years"
            />
            <RewardSeparator />
            <ReferralReward
              unlockedRewards={unlockedRewards}
              lockedRewards={[ReferralRewardType.THREE_YEAR_EXTENSION]}
              countDescription="10 referrals"
              title="3 year subscription"
              className="three-years"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export interface ReferralsData {
  referralCode: string;
  unlockedRewardTypes: ReferralRewardType[];
}

export interface ReferralsProps {
  referralsData: ReferralsData;
}

export default function Referrals({ referralsData }: ReferralsProps) {
  return (
    <div className="referrals-page">
      <Header />
      <Main referralCode={referralsData.referralCode} />
      <Tiers
        unlockedRewards={referralsData.unlockedRewardTypes}
        className="full-width-breakout"
      />
    </div>
  );
}
