import classNames from "classnames";
import React from "react";

import SmallDog from "../../../assets/images/dog-small.svg";
import LargeDog from "../../../assets/images/dog-large.svg";
import XlargeDog from "../../../assets/images/dog-xlarge.svg";

import { centsToDollars } from "../../../lib/util";
import * as types from "../../../types";

const paidGpsGraphics = [SmallDog, LargeDog, XlargeDog];

const nameOverrideMap: { [key: string]: string } = {
  "1-year-subscription": "1 Year",
  "2-year-subscription": "2 Years",
  "3-year-subscription": "3 Years"
};

function billingDescription(dollars: string, numYears: number) {
  return numYears === 1
    ? `$${dollars} billed annually`
    : `$${dollars} billed every ${numYears} years`;
}

interface ISubscriptionTileProps {
  subscription: types.ISubscriptionProduct;
  index: number;
  onClick: () => void;
}

const PaidSubscriptionTile = ({
  subscription,
  index,
  onClick
}: ISubscriptionTileProps) => (
  <div
    className={classNames({
      "subscription-tile": true,
      "most-popular": subscription.mostPopular
    })}
  >
    <img
      alt="A dog"
      src={paidGpsGraphics[Math.min(index, paidGpsGraphics.length - 1)]}
      className="dog-sizer"
    />
    <div className="content">
      {subscription.mostPopular && (
        <div className="most-popular-callout">Most Popular</div>
      )}
      <div className="main-content">
        <h3>{nameOverrideMap[subscription.id] || subscription.name}</h3>
        <div className="subscription-price">
          <span className="price-per-unit">{subscription.displayedRate}</span>
          &nbsp;/&nbsp;
          <span className="unit">{subscription.displayedRateUnit}</span>
        </div>
        {subscription.savings && (
          <div className="savings">{subscription.savings}</div>
        )}
      </div>
    </div>
    <div className="price-disclaimer">
      {billingDescription(
        centsToDollars(subscription.priceInCents),
        subscription.renewalYears || 1
      )}
    </div>
    <div className="action">
      <button className="flow-button--yellow" onClick={onClick}>
        Select
      </button>
    </div>
  </div>
);

export default PaidSubscriptionTile;
