import classNames from "classnames";
import * as React from "react";

import * as events from "../lib/analytics/events";
import { setPassword } from "../lib/fi-api/authentication";
import TrackedExternalLink from "./TrackedExternalLink";

interface ISaveAccountEntryProps {
  onSuccess(): void;
}

interface ISaveAccountEntryState {
  password: string;
  loading: boolean;
  error: boolean;
}

class SaveAccountEntry extends React.Component<
  ISaveAccountEntryProps,
  ISaveAccountEntryState
> {
  constructor(props: ISaveAccountEntryProps) {
    super(props);
    this.state = {
      password: "",
      loading: false,
      error: false
    };
  }

  public render() {
    return (
      <div className="save-account-inner save-account-entry">
        <div className="top">
          <div className="icon-container">
            <img alt="A running dog" src="/running_dog.svg" />
            <img alt="Squiggle" src="/squiggle.svg" className="squiggle" />
          </div>
        </div>
        <div>
          <h4>Save your account</h4>
          <div>Choose a password</div>
        </div>
        <div>
          <input
            type="password"
            name="password"
            placeholder={
              this.state.error ? "Oops! An error occurred." : "Password"
            }
            className={classNames({ wide: true, error: this.state.error })}
            value={this.state.password}
            onChange={evt =>
              this.setState({ password: evt.target.value, error: false })
            }
            onKeyDown={evt => {
              if (evt.key === "Enter") {
                evt.preventDefault();
                this.onSubmit();
              }
            }}
          />
        </div>
        <div>
          <div
            className={classNames({
              button: true,
              "button--primary": true,
              "button--disabled": this.state.loading
            })}
            onClick={this.onSubmit}
          >
            Continue
          </div>
        </div>
      </div>
    );
  }

  private onSubmit = async () => {
    if (this.state.loading || !this.state.password) {
      return;
    }
    this.setState({ loading: true });
    try {
      await setPassword(this.state.password);
      events.thankYou.setPasswordSuccess();
      this.props.onSuccess();
    } catch (e) {
      events.thankYou.setPasswordFailure();
      this.setState({ error: true, password: "" });
    } finally {
      this.setState({ loading: false });
    }
  };
}

class SaveAccountComplete extends React.Component {
  public render() {
    return (
      <div className="save-account-inner save-account-complete">
        <div className="top">
          <div className="icon-container">
            <img alt="Green check mark" src="/green_check.svg" />
          </div>
        </div>
        <div className="password-set-content">
          <h4>Password set!</h4>
          <div className="password-set-body">
            Thanks for creating an account.
            <br />
            <TrackedExternalLink
              href="https://tryfi.com/app"
              className="text--highlight"
              target="_blank"
              {...events.thankYou.downloadLinkDetails()}
            >
              Download
            </TrackedExternalLink>{" "}
            and log in to the Fi mobile app for iOS &amp; Android!
          </div>
          <img alt="Screenshot of the Fi app" src="/feature-gps-1.png" />
        </div>
      </div>
    );
  }
}

interface ISaveAccountState {
  finished: boolean;
}

export default class SaveAccount extends React.Component<
  {},
  ISaveAccountState
> {
  constructor(props: {}) {
    super(props);
    this.state = { finished: false };
  }

  public render() {
    return (
      <div id="save-account">
        {this.state.finished ? (
          <SaveAccountComplete />
        ) : (
          <SaveAccountEntry
            onSuccess={() => this.setState({ finished: true })}
          />
        )}
      </div>
    );
  }
}
