import { useState, useEffect } from "react";
import { IGiftCardProduct } from "../../types";
import * as events from "../../lib/analytics/events";

export default function useTracking(product: IGiftCardProduct | undefined) {
  const [lastTrackedId, setLastTrackedId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (product && product.id !== lastTrackedId) {
      events.productViewed({
        product,
        reason: !lastTrackedId
          ? { kind: "PageLoad" }
          : { kind: "ProductChanged" }
      });
      setLastTrackedId(product.id);
    }
  }, [product, lastTrackedId]);
}
