import React from "react";

export function SubscriptionIntro() {
  return (
    <>
      <h2 className="subscription-header">Choose a GPS Plan</h2>
      <div className="subscription-subheader">
        <h4>
          Enable GPS/LTE service to track your dog when they are not with you.
        </h4>
        <div>
          <span className="star">&#9733;</span>&nbsp;Try it free for 30 days
        </div>
      </div>
    </>
  );
}

export function SubscriptionFeatures() {
  return (
    <div className="properties">
      <ul>
        <li>Daily, weekly &amp; monthly activity history.</li>
        <li>Track your dog anytime, from anywhere.</li>
        <li>Safe zones, escape monitoring &amp; alerts.</li>
        <li>24/7 GPS-LTE tracking &amp; Lost Dog Mode.</li>
      </ul>
    </div>
  );
}
