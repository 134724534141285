import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import * as types from "../../../../types";

import { ProductType, productIsComplex } from "../../ProductProps";
import AddToBagButton from "../AddToBagButton";
import ProductOptions from "./ProductOptions";
import { nl2br } from "../../../../lib/util";

export interface IProductDetailsColumnProps {
  product: ProductType;
  variant?: types.IVariant;
  allowPurchase: boolean;
  blockedSkus: string[];
  collarWarnSmallSize?: boolean;
  onOptionChange?: (changed: Partial<types.IVariantOptions>) => void;
  onAddToBag: (variant?: types.IVariant) => void;
  continueTitle?: string;
  continueClassName?: string;
  continueLocation?: string;
  showContinueButton?: boolean;
}

const ProductDetailsColumn = ({
  product,
  variant,
  onOptionChange,
  collarWarnSmallSize,
  blockedSkus,
  allowPurchase,
  onAddToBag,
  continueTitle,
  continueClassName,
  continueLocation,
  showContinueButton
}: IProductDetailsColumnProps) => {
  const addToBagClasses = classNames("flow-button", "buy-button", {
    "flow-button--disabled": !allowPurchase
  });

  const continueClasses = classNames(continueClassName);

  const continueWrapperClass = classNames("action-button", {
    "hide-flow": !showContinueButton
  });

  return (
    <div className="product-details">
      <p>{nl2br(product.description)}</p>
      {productIsComplex(product) && variant && onOptionChange && (
        <ProductOptions
          product={product}
          selectedVariant={variant}
          onOptionChange={onOptionChange}
          blockedSKUs={blockedSkus}
        />
      )}

      {!allowPurchase && (
        <div>
          <strong>Sold out! New stock coming soon.</strong>
        </div>
      )}

      <div className="product-action">
        <div className="action-button">
          <AddToBagButton
            className={addToBagClasses}
            needsSmallSizeWarning={collarWarnSmallSize}
            disabled={!allowPurchase}
            onAddToBag={() => {
              if (allowPurchase) {
                onAddToBag(variant);
              }
            }}
          />
        </div>
        {continueLocation && (
          <div className={continueWrapperClass}>
            <Link to={continueLocation} className={continueClasses}>
              {continueTitle}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsColumn;
