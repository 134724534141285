import React, { useCallback } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { IAppState } from "../../types";

import { Link } from "react-router-dom";

import { logout } from "../../lib/fi-api/authentication";
import { logInternalError } from "../../lib/util";

interface ILogInOrOutProps {
  loggedIn: boolean;
  extraClasses?: string[];
  onClick?: () => void;
}

function LogInOrOut({ loggedIn, extraClasses, onClick }: ILogInOrOutProps) {
  const handleClick = useCallback(
    async (e: any) => {
      if (loggedIn) {
        // Log the user out
        e.preventDefault();
        try {
          await logout();
        } catch (e) {
          logInternalError(e);
        }
      }

      if (onClick) {
        onClick();
      }
    },
    [loggedIn, onClick]
  );

  return (
    // eslint-disable-next-line
    <Link
      to={loggedIn ? "#" : "/login"}
      className={classNames(extraClasses || [])}
      onClick={handleClick}
    >
      {loggedIn ? "Log Out" : "Log In"}
    </Link>
  );
}

export default connect((state: IAppState) => ({
  loggedIn: !!state.session
}))(LogInOrOut);
