import classNames from "classnames";
import React from "react";

export default function PromoCodeToggle({
  toGiftCode,
  onClick
}: {
  toGiftCode: boolean;
  onClick: VoidFunction;
}) {
  return (
    <div className="promo-code-gift-card-toggle">
      {toGiftCode && (
        <>
          <img src="/gift_box_gray.svg" alt="A gift box" />{" "}
        </>
      )}
      <span
        className={classNames("link", "link--underlined")}
        onClick={onClick}
      >
        {toGiftCode ? "I have a gift card" : "I have a coupon or referral code"}
      </span>
    </div>
  );
}
