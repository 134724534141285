import { connect } from "react-redux";

import PasswordReset, {
  IPasswordResetProps
} from "../components/PasswordReset";
import { IAppState } from "../types";

const mapStateToProps = (state: IAppState): IPasswordResetProps => {
  return {
    haveItemsInCart: Object.entries(state.cart.lineItems).length > 0
  };
};

export default connect(mapStateToProps)(PasswordReset);
