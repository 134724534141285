import React from "react";
import * as types from "../../types";
import { expectUnreachable } from "../../lib/util";
import { CheckoutSection } from "../../models/CheckoutSections";
import ICheckoutState from "../../models/CheckoutState";

export interface SetShippingAddressAction {
  type: "SetShippingAddress";
  newShippingAddress: types.IAddress | undefined;
}
export interface SetShippingCodeAction {
  type: "SetShippingCode";
  newShippingCode: string | undefined;
}
export interface SetBillingInfoAction {
  type: "SetBillingInfo";
  newBillingInfo: types.IBillingInfo | undefined;
}
export interface SetOrderIDAction {
  type: "SetOrderID";
  newOrderID: string | undefined;
}

export interface SetCustomerNameAction {
  type: "SetCustomerName";
  newCustomerName: string | undefined;
}

export interface SetRevisitSectionAction {
  type: "SetRevisitSection";
  newRevisitSection: CheckoutSection | undefined;
}

export interface UpdateCartAction {
  type: "UpdateCart";
  newCart: types.ICart;
}

export type CheckoutAction =
  | SetShippingAddressAction
  | SetShippingCodeAction
  | SetBillingInfoAction
  | SetOrderIDAction
  | SetRevisitSectionAction
  | SetCustomerNameAction
  | UpdateCartAction;

export const updateCart = (newCart: types.ICart): UpdateCartAction => ({
  type: "UpdateCart",
  newCart
});

export const setCustomerName = (
  newCustomerName: string | undefined
): SetCustomerNameAction => ({
  type: "SetCustomerName",
  newCustomerName
});

export const setShippingAddress = (
  newShippingAddress: types.IAddress | undefined
): SetShippingAddressAction => ({
  type: "SetShippingAddress",
  newShippingAddress
});

export const setShippingCode = (
  newShippingCode: string | undefined
): SetShippingCodeAction => ({
  type: "SetShippingCode",
  newShippingCode
});

export const setBillingInfo = (
  newBillingInfo: types.IBillingInfo | undefined
): SetBillingInfoAction => ({
  type: "SetBillingInfo",
  newBillingInfo
});

export const setOrderID = (
  newOrderID: string | undefined
): SetOrderIDAction => ({
  type: "SetOrderID",
  newOrderID
});

export const setRevisitSection = (
  newRevisitSection: CheckoutSection | undefined
): SetRevisitSectionAction => ({
  type: "SetRevisitSection",
  newRevisitSection
});

export const defaultReducer = (
  state: ICheckoutState,
  action: CheckoutAction
) => {
  if (action.type === "SetShippingAddress") {
    return {
      ...state,
      shippingAddress: action.newShippingAddress,
      userOverridingViewToSection: undefined
    };
  } else if (action.type === "SetShippingCode") {
    return {
      ...state,
      shippingCode: action.newShippingCode,
      userOverridingViewToSection: undefined
    };
  } else if (action.type === "SetBillingInfo") {
    return {
      ...state,
      billingInfo: action.newBillingInfo,
      userOverridingViewToSection: undefined
    };
  } else if (action.type === "SetOrderID") {
    return {
      ...state,
      orderID: action.newOrderID,
      userOverridingViewToSection: undefined
    };
  } else if (action.type === "SetRevisitSection") {
    return {
      ...state,
      userOverridingViewToSection: action.newRevisitSection
    };
  } else if (action.type === "SetCustomerName") {
    return {
      ...state,
      customerName: action.newCustomerName
    };
  } else if (action.type === "UpdateCart") {
    return {
      ...state,
      orderCart: action.newCart
    };
  } else {
    expectUnreachable(action);
    return state;
  }
};

const CheckoutContext = React.createContext<{
  checkoutState: ICheckoutState;
  checkoutDispatch: React.Dispatch<CheckoutAction>;
}>(undefined as any);

export default CheckoutContext;
