import React from "react";
import { IGiftCardProduct, GiftCardCategory } from "../../../types";
import { centsToDollars } from "../../../lib/util";
import classNames from "classnames";

interface IButtonProps {
  disabled?: boolean;
  selected?: boolean;
  onClick: VoidFunction;
}

function GiftCardButton({
  disabled,
  selected,
  onClick,
  className,
  mostPopular,
  children
}: React.PropsWithChildren<IButtonProps> & {
  className?: string;
  mostPopular?: boolean;
}) {
  return (
    <div
      onClick={onClick}
      className={classNames("gift-product", className, { selected, disabled })}
    >
      {mostPopular && <div className="gift-product-popular">Most Popular</div>}
      <div className="gift-product-main">{children}</div>
    </div>
  );
}

export function PackageButton(
  props: { category: GiftCardCategory } & IButtonProps
) {
  const { category } = props;

  let title: string;
  if (category === GiftCardCategory.fullKit) {
    title = "Fi Collar kit";
  } else if (category === GiftCardCategory.subscription) {
    title = "GPS Subscription only";
  } else {
    title = "";
  }

  return <GiftCardButton {...props}>{title}</GiftCardButton>;
}

export function ProductButton(
  props: { product: IGiftCardProduct } & IButtonProps
) {
  const { product } = props;

  return (
    <GiftCardButton
      {...props}
      mostPopular={product.mostPopular}
      className="end-product"
    >
      <ul className="list--unstyled gift-sub-products">
        {product.subProducts.map((subProduct, i) => (
          <li
            key={i}
            className={classNames({
              "sub-product-deemphasized":
                product.subProducts.length > 1 && i === 0
            })}
          >
            <span className="sub-product-name">{subProduct.name}</span>{" "}
            {subProduct.savings && (
              <span className="sub-product-savings"> {subProduct.savings}</span>
            )}
            <span className="sub-product-price">
              ${centsToDollars(subProduct.priceInCents)}
            </span>
          </li>
        ))}
      </ul>
    </GiftCardButton>
  );
}
