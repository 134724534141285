import React, { useState } from "react";

import * as events from "../../../../lib/analytics/events";

function WarningDenial({ onClose }: { onClose(): void }) {
  return (
    <div className="small-size-warning small-size-warning-denial">
      <h2>Sorry!</h2>
      <p>
        The Fi Collar is not a good fit for very small dogs. The minimum size of
        the collar is 11 inch neck circumference and the dog’s weight should be
        more than 10–11lbs
      </p>
      <div>
        <button className="button button--outline" onClick={() => onClose()}>
          OK
        </button>
      </div>
    </div>
  );
}

function ChoiceButton({
  title,
  image,
  breeds,
  onClick
}: {
  title: string;
  image: string;
  breeds: string;
  onClick(): void;
}) {
  return (
    <div onClick={() => onClick()} className="small-size-warning-button">
      <h4>{title}</h4>
      <img src={image} alt="A dog" width="140" />
      <div>
        <strong>Breeds</strong>
        <p>{breeds}</p>
      </div>
    </div>
  );
}

function WarningChoice({
  onConfirm,
  onDeny
}: {
  onConfirm(): void;
  onDeny(): void;
}) {
  return (
    <div className="small-size-warning small-size-warning-choice">
      <h2>What size is your dog?</h2>
      <div className="small-size-warning-choice-subtitle">
        Select the closest option
      </div>
      <div className="small-size-warning-buttons">
        <ChoiceButton
          onClick={() => {
            events.collarPage.smallSizeWarningLessSelected();
            onDeny();
          }}
          title="Less than 10 lbs"
          image="/chihuahua.svg"
          breeds="Chihuahua, Bichon Frise, Yorkie, Pomeranian, Mini Poodle"
        />
        <ChoiceButton
          onClick={() => {
            events.collarPage.smallSizeWarningMoreSelected();
            onConfirm();
          }}
          title="More than 10 lbs"
          image="/french_bulldog.svg"
          breeds="French Bulldog, Corgi, Cairn Terrier, Cavalier King Charles"
        />
      </div>
    </div>
  );
}

export default function SmallSizeWarning({
  onConfirm,
  onClose
}: {
  onConfirm(): void;
  onClose(): void;
}) {
  const [denied, setDenied] = useState(false);
  return denied ? (
    <WarningDenial onClose={onClose} />
  ) : (
    <WarningChoice onConfirm={onConfirm} onDeny={() => setDenied(true)} />
  );
}
