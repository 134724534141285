import React from "react";
import { IAppState, ProductKind } from "../types";
import { useSelector } from "react-redux";
import NotFound from "../components/NotFound";
import { Rescue } from "./Rescue";

const RESCUE_PRODUCT_ID = "gift-card-sub-1y";

export default function RescueContainer() {
  const products = useSelector((state: IAppState) => state.config.products);

  const giftCardProducts = products.flatMap(p =>
    p.id === RESCUE_PRODUCT_ID && p.kind === ProductKind.GiftCard ? [p] : []
  );

  if (giftCardProducts.length === 0) {
    return <NotFound />;
  }

  return <Rescue product={giftCardProducts[0]} />;
}
