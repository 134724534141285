import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";

import fragmentTypes from "../fragmentTypes";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes
});
const cache = new InMemoryCache({ fragmentMatcher });

export default cache;
