import React, { useMemo } from "react";
import { useStore } from "react-redux";
import { Link } from "react-router-dom";

import * as events from "../../../lib/analytics/events";
import { shouldShowApplePay, isKiosk } from "../../../lib/util";
import { IGiftCardDetails } from "../../../types";
import ApplePayCheckout from "../../../components/ApplePayCheckout";
import GiftCardApplied from "../../../components/GiftCardApplied";

interface ICartSummaryProps {
  total: string;
  giftCardDetails: IGiftCardDetails | undefined;
  showMonthlyOption: boolean;
  onCheckOut(): void;
}

export default function CartSummary({
  total,
  giftCardDetails,
  showMonthlyOption,
  onCheckOut
}: ICartSummaryProps) {
  const state = useStore().getState();
  const showApplePay = useMemo(() => !isKiosk() && shouldShowApplePay(state), [
    state
  ]);

  const monthlyPrice = useMemo(() => {
    let price = parseFloat(total);
    if (price) {
      return Math.ceil(price / 6);
    } else {
      return undefined;
    }
  }, [total]);

  return (
    <div className="cart-summary">
      <div className="price-data">
        <div className="row">
          <h4>Total</h4>
          <h4 className="price-value">${total}</h4>
        </div>
        {!!monthlyPrice && showMonthlyOption && (
          <div className="monthly-option">
            or ${monthlyPrice}/mo for 6 months with PayPal Credit
          </div>
        )}
      </div>
      {giftCardDetails && <GiftCardApplied showRemoveButton />}
      <div className="cart-summary__actions">
        <Link
          to="/checkout"
          className="flow-button--yellow"
          onClick={() => onCheckOut()}
        >
          Pay with Card
        </Link>
        {showApplePay && <ApplePayCheckout events={events.cartPage} />}
        {/* TODO(zack): Paypal button if !isKiosk() */}
      </div>
    </div>
  );
}
