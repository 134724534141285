import React, { useContext } from "react";
import GiftCardContext, { setSelectedId } from "../GiftCardContext";
import { IGiftCardProduct, GiftCardCategory } from "../../../types";
import { partition } from "../../../lib/util";
import classNames from "classnames";
import { ProductButton } from "./Buttons";

export default function GiftCardPicker({
  enabled: stageEnabled
}: {
  enabled: boolean;
}) {
  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);
  const {
    giftCardProducts: products,
    selectedCategory,
    selectedId
  } = giftCardState;

  const category = selectedCategory ?? GiftCardCategory.fullKit;

  const productsInCategory = products.filter(
    product =>
      !product.hidden &&
      (!product.giftCardCategory || product.giftCardCategory === category)
  );

  const renderProducts = (products: IGiftCardProduct[]) =>
    products.map((product, i) => (
      <ProductButton
        key={i}
        product={product}
        onClick={() => {
          if (stageEnabled) {
            giftCardDispatch(setSelectedId(product.id));
          }
        }}
        disabled={!stageEnabled}
        selected={selectedId === product.id}
      />
    ));

  let selectorBody: JSX.Element;
  if (category === GiftCardCategory.fullKit) {
    // Special treatment for full kits: divide into "The Basics" and "The Basics + GPS Plan"
    const [withPlan, withoutPlan] = partition(
      productsInCategory,
      product => !!product.hasPlan
    );
    selectorBody = (
      <>
        <p className="section-title">The Basics</p>
        {renderProducts(withoutPlan)}
        <p className="section-title">The Basics + Prepaid GPS Plan</p>
        {renderProducts(withPlan)}
      </>
    );
  } else {
    // Simply render gift card products.
    selectorBody = <>{renderProducts(productsInCategory)}</>;
  }

  return (
    <div
      className={classNames("split-container gift-card-page-section", {
        "disabled-section": !stageEnabled
      })}
    >
      <div className="gift-card-picker-left">
        <div>
          <h2>2. Pick a gift card</h2>
        </div>
        <div className="gift-card-image-container">
          <div className="gift-card-image-wrapper">
            <img
              alt="A Fi gift card"
              src="/gift_card.png"
              width="319"
              height="202"
            />
          </div>
        </div>
      </div>
      <div className="gift-product-list-container">{selectorBody}</div>
    </div>
  );
}
