import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IAppState, ProductKind, ILineItem } from "../types";
import NotFound from "../components/NotFound";
import GiftCard from "../views/GiftCard";
import { cartActions } from "../reducers/cart";

export default function GiftCardContainer() {
  const products = useSelector((state: IAppState) => state.config.products);
  const dispatch = useDispatch();

  const giftCardProducts = products.flatMap(p =>
    p.kind === ProductKind.GiftCard ? [p] : []
  );

  if (giftCardProducts.length === 0) {
    return <NotFound />;
  }

  return (
    <GiftCard
      giftCardProducts={giftCardProducts}
      addLineItem={(lineItem: ILineItem) =>
        dispatch(cartActions.addLineItem(lineItem))
      }
    />
  );
}
