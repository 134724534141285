import React from "react";
import { centsToDollars } from "../../../../lib/util";
import Checkbox from "../../../../components/Checkbox";

interface IShippingOptionItemProps {
  selected: boolean;
  name: string;
  priceInCents?: number;
  onClick(): void;
}

export default function ShippingOptionItem({
  selected,
  name,
  priceInCents,
  onClick
}: IShippingOptionItemProps) {
  return (
    <div className="split-container line-item shipping-option">
      <div className="row">
        <div>
          <h4>
            <Checkbox
              className="header-4"
              checked={selected}
              onChange={(newChecked: boolean) => {
                if (newChecked) {
                  onClick();
                }
              }}
              label={name}
            />
          </h4>
        </div>
        <div className="line-item-price">
          {!!priceInCents ? (
            <h4>${centsToDollars(priceInCents)}</h4>
          ) : (
            <h4>Free</h4>
          )}
        </div>
      </div>
    </div>
  );
}
