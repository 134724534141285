import React, { useState } from "react";
import { Mutation, Query } from "react-apollo";

import ErrorMessages from "../../../../lib/errors";
import { generateID, getCustomerMessageOrFallback } from "../../../../lib/util";
import {
  queryBillingAccount,
  updateBillingInfo
} from "../../../../lib/BillingAccount.graphql";

import * as gqlTypes from "../../../../types/gql-op-types";

import Loading from "../../../../components/Loading";

import BillingInfo from "./BillingInfo";

export default function BillingInfoContainer() {
  const [errorID, setErrorID] = useState<string | undefined>(undefined);

  return (
    // TODO: I think we could allow use of cache data if we had an ID associated with these records
    <Query<gqlTypes.billingAccount>
      query={queryBillingAccount}
      fetchPolicy="network-only"
      onError={_ => setTimeout(() => setErrorID(generateID()), 0)}
    >
      {({ data, loading: queryLoading, error: queryError }) => {
        return (
          <Mutation<
            gqlTypes.updateBillingInfo,
            gqlTypes.updateBillingInfoVariables
          >
            mutation={updateBillingInfo}
          >
            {(mutation, { loading: mutationLoading, error: mutationError }) => {
              let error: string | undefined;
              // TODO: Log error
              if (queryError) {
                error = ErrorMessages.DEFAULT;
              } else if (mutationError) {
                error = getCustomerMessageOrFallback(
                  mutationError,
                  ErrorMessages.INPUT_ERROR
                );
              }

              const loading = queryLoading || mutationLoading;

              if (loading) {
                return <Loading />;
              }

              return (
                <BillingInfo
                  mutation={async ({ input }) => {
                    try {
                      const result = await mutation({
                        variables: { input }
                      });
                      return result.data!.updateBillingAccount;
                    } finally {
                      setErrorID(generateID());
                    }
                  }}
                  billingInfo={
                    data?.currentUser?.billingAccount?.billingInfo ?? null
                  }
                  error={error}
                  errorID={errorID}
                />
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
}
