import React from "react";
import { Redirect } from "react-router";

import { queryBillingAccount } from "../../lib/BillingAccount.graphql";
import * as events from "../../lib/analytics/events";
import * as types from "../../types";
import { gqlTypes } from "../../types/gql";
import { Query } from "react-apollo";
import CheckoutPaths from "../../lib/CheckoutPaths";
import ICheckoutState, {
  defaultCheckoutState
} from "../../models/CheckoutState";

interface IFinishedRedirectProps {
  orderedCart: types.ICart;
  shippingCode: string | undefined;
  orderID: string | undefined;
}

export default function FinishedRedirect({
  orderedCart,
  orderID,
  shippingCode
}: IFinishedRedirectProps) {
  const createRedirect = (state: ICheckoutState) => {
    return (
      <Redirect
        to={{
          pathname: CheckoutPaths.ThankYou,
          state
        }}
      />
    );
  };

  // This is a bit janky of a way to handle errors. Create empty information to display on the thank you page.
  // Technically the user has completed their purchase at this point - there was no error there, so displaying
  // the thank you page seems less confusing than displaying an error where they might not be sure if the
  // purchase went through.
  // This should be an extreme edge case, since at this point we should definitely have a billing account
  // as we just made a purchase.
  const createErrorRedirect = () =>
    createRedirect({
      ...defaultCheckoutState(orderedCart),
      orderID,
      shippingCode: shippingCode
    });

  return (
    <Query<gqlTypes.billingAccount>
      query={queryBillingAccount}
      fetchPolicy="network-only"
      onCompleted={data => {
        const billingAccount = (data as gqlTypes.billingAccount).currentUser
          .billingAccount;
        if (billingAccount?.billingInfo && orderID) {
          events.orderCompleted(
            orderedCart,
            orderID,
            billingAccount.address ?? undefined,
            billingAccount.billingInfo
          );
        }
      }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return null;
        }
        if (error || !data) {
          return createErrorRedirect();
        }

        const currentUser = data.currentUser;
        if (!currentUser.billingAccount?.billingInfo) {
          return createErrorRedirect();
        }

        const address: types.IAddress | undefined =
          currentUser.billingAccount.address || undefined;
        const billingInfo: types.IBillingInfo =
          currentUser.billingAccount.billingInfo;

        const info: ICheckoutState = {
          customerName: data.currentUser?.firstName,
          orderCart: orderedCart,
          shippingAddress: address,
          billingInfo,
          orderID,
          shippingCode: shippingCode
        };

        return createRedirect(info);
      }}
    </Query>
  );
}
