import { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import * as types from "../../types";
import * as events from "../../lib/analytics/events";

import { productIsComplex } from "./ProductProps";

import { centsToDollars, generateID } from "../../lib/util";

export interface IAddToBagResult {
  /**
   * A function for you to call to add the product to the bag
   * @param order The variant of the product to add. Required for complex product, omit for simple.
   */
  addToBag: (variant?: types.IVariant) => void;

  // Whether the product has been added to bag by this page.
  addedToBag: boolean;

  // Whether the product is in the bag, whether or not added by this page and irrespective of variant.
  productInBag: boolean;
}

/**
 * Hook to wrap the "add line item" function in the business logic
 * to add an arbitrary product/variant purchase and to, if applicable,
 * automatically add any accompanying gifted items.
 * @param addLineItem The function used to add a line item to the user's bag.
 */
function useAddToBag(
  product: types.IProduct,
  addLineItem: (lineItem: types.ILineItem) => void
): IAddToBagResult {
  // Whether the product has been added to the bag since the page loaded
  const [addedToBag, setAddedToBag] = useState(false);

  // Load the cart
  const cart = useSelector((state: types.IAppState) => state.cart);

  const productInBag = useMemo(() => {
    return !!Object.values(cart.lineItems).find(
      (item: types.ILineItem): boolean => {
        return item.productID === product.id;
      }
    );
  }, [cart, product.id]);

  const addToBag = useCallback(
    (variant?: types.IVariant) => {
      if (productIsComplex(product) && !variant) {
        // We need a variant picked for complex products
        return;
      }

      events.addToBag({ product, variant });

      const itemID = generateID();

      let lineItemDetails: {
        sku: string;
        details?: string;
        type?: string;
        description?: string;
      };

      if (productIsComplex(product)) {
        lineItemDetails = {
          sku: variant!.sku,
          details: [variant!.options.color, variant!.options.size].join(", ")
        };
      } else {
        lineItemDetails = {
          sku: product.sku,
          type: product.type,
          description: product.name
        };
      }

      addLineItem({
        ...lineItemDetails,
        id: itemID,
        productID: product.id,
        type: product.type,
        description: product.name,
        price: centsToDollars(product.priceInCents),
        quantity: 1
      });

      setAddedToBag(true);
    },
    [product, addLineItem]
  );

  return {
    addToBag,
    addedToBag,
    productInBag
  };
}

export default useAddToBag;
