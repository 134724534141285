import React from "react";

import * as types from "../../../../types";
import { ProductType } from "../../ProductProps";
import { centsToDollars } from "../../../../lib/util";
import ProductImageGallery from "./ProductImageGallery";

interface IProductNameImageProps {
  variant?: types.IVariant;
  product: ProductType;
  includeMonthlyPricing?: boolean;
}

const ProductNameImage = ({
  product,
  variant,
  includeMonthlyPricing
}: IProductNameImageProps) => {
  const monthlyPriceInCents = Math.round(product.priceInCents / 6 / 100) * 100;

  return (
    <div className="product-name-image">
      <h2>
        {product.name}
        <span className="detail-price">
          ${centsToDollars(product.priceInCents)}
        </span>
      </h2>
      {includeMonthlyPricing === true && (
        <div className="monthly-option">
          or pay ${centsToDollars(monthlyPriceInCents)}/month
        </div>
      )}
      <ProductImageGallery product={product} selectedVariant={variant} />
    </div>
  );
};

export default ProductNameImage;
