import React, { useState, useMemo } from "react";
import { IAppState } from "../../types";
import { useSelector } from "react-redux";
import RichLineItem from "../../models/RichLineItem";
import PromoCodeCouponApplied from "./PromoCodeCouponApplied";
import PromoCodeCouponEntry from "./PromoCodeCouponEntry";
import PromoCodeGiftCardEntry from "./PromoCodeGiftCardEntry";
import PromoCodeToggle from "./PromoCodeToggle";

const useEnableGiftCards = () =>
  !!useSelector((state: IAppState) => state.config.siteConfig.enableGiftCards);

interface PromoCodeProps {
  haveAnyGiftCardsInCart: boolean;
  showCoupons: boolean;
}

export default function PromoCode({
  haveAnyGiftCardsInCart,
  showCoupons
}: PromoCodeProps) {
  const cart = useSelector((state: IAppState) => state.cart);
  const session = useSelector((state: IAppState) => state.session);
  const lineItems = useSelector((state: IAppState) =>
    RichLineItem.arrayForState(state)
  );
  const enableGiftCards = useEnableGiftCards();
  const [giftCardShown, setGiftCardShown] = useState(false);

  const couponDetails = cart.couponDetails;
  const referralDetails = cart.referralDetails;

  const anyCouponEligible =
    !!(session && session.impersonating) ||
    lineItems.some(li => !!li.product.couponEligible);

  const canApplyGiftCard = useMemo(
    () => enableGiftCards && !(cart.giftCardDetails || haveAnyGiftCardsInCart),
    [enableGiftCards, cart.giftCardDetails, haveAnyGiftCardsInCart]
  );

  const canApplyCoupon = useMemo(
    () => showCoupons && !(couponDetails || referralDetails),
    [couponDetails, referralDetails, showCoupons]
  );

  const showToggle = useMemo(
    () =>
      (giftCardShown && canApplyCoupon) || (!giftCardShown && canApplyGiftCard),
    [giftCardShown, canApplyCoupon, canApplyGiftCard]
  );

  console.debug(
    anyCouponEligible,
    cart.giftCardDetails,
    canApplyCoupon,
    canApplyGiftCard
  );

  if (!anyCouponEligible && cart.giftCardDetails) {
    // None of the items in the cart are eligible for a coupon, and a gift card has been applied
    // to the cart. There's no action the user can take with the PromoCode entry, so don't display it.
    return null;
  }

  // If you can't apply either, just abort
  if (!canApplyCoupon && !canApplyGiftCard) {
    return null;
  }

  return (
    <div className="promo-code-container">
      {couponDetails || referralDetails ? (
        <PromoCodeCouponApplied showGiftCard={() => setGiftCardShown(true)} />
      ) : (
        <>
          {giftCardShown ? (
            <PromoCodeGiftCardEntry />
          ) : (
            <PromoCodeCouponEntry />
          )}
          {showToggle && (
            <PromoCodeToggle
              toGiftCode={!giftCardShown}
              onClick={() => setGiftCardShown(!giftCardShown)}
            />
          )}
        </>
      )}
    </div>
  );
}
