import classNames from "classnames";
import Cookies from "js-cookie";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useStore, useDispatch, useSelector } from "react-redux";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  Redirect
} from "react-router-dom";

import "./App.scss";
import AppBar from "./components/AppBar";
import CJEventComponent from "./components/CJEventComponent";
import CouponBanner from "./components/CouponBanner";
import ErrorComponent from "./components/ErrorComponent";
import Loading from "./components/Loading";
import NotFound from "./components/NotFound";
import RescuePlacement from "./views/RescuePlacement/RescuePlacement";
import CartContainer from "./containers/CartContainer";
import ReferralBanner from "./components/ReferralBanner";
import HomeContainer from "./containers/HomeContainer";
import PasswordResetContainer from "./containers/PasswordResetContainer";
import ProductDetailsContainer from "./containers/ProductDetailsContainer";
import SessionContainer from "./containers/SessionContainer";
import SubscriptionListContainer from "./containers/SubscriptionListContainer";
import analytics from "./lib/analytics";
import { analyticsCoordinator } from "./lib/analytics/coordinator";
import { cartActions } from "./reducers/cart";
import { configActions } from "./reducers/config";
import * as types from "./types";
import * as qs from "query-string";
import AccessoriesContainer from "./containers/AccessoriesContainer";
import Constants from "./lib/constants";
import Impersonate from "./components/Impersonate";
import ImpersonationWarning from "./components/ImpersonationWarning";
import GiftCardContainer from "./containers/GiftCardContainer";
import { applyCouponToCart, applyGiftCardToCart } from "./lib/pricing";
import * as events from "./lib/analytics/events";
import { Dispatch } from "redux";
import {
  logInternalError,
  findGiftCardProductFromDetails,
  CouponCookieData,
  couponCookieName,
  removeCookie
} from "./lib/util";
import { giftCardCookieName, ProductType } from "./types";
import GiftCardBanner from "./components/GiftCardBanner";
import ReferralsContainer from "./containers/ReferralsContainer";
import ReferralRedirect from "./components/ReferralRedirect";
import RescueContainer from "./containers/RescueContainer";
import { createSessionFromExistingCredentials } from "./lib/fi-api/authentication";
import CheckoutPaths from "./lib/CheckoutPaths";
import CheckoutContainer from "./containers/CheckoutContainer";
import ThankYouContainer from "./containers/ThankYouContainer";
import useProductSKUMap from "./hooks/useProductSKUMap";

function useApplyPromotionFromCookie<T>(options: {
  getCookieValue: () => [T, () => void] | undefined;
  applyPromotion: (args: {
    value: T;
    dispatch: Dispatch<any>;
    cart: types.ICart;
  }) => Promise<void>;
}) {
  const dispatch = useDispatch();
  const cart = useSelector((state: types.IAppState) => state.cart);
  const config = useSelector((state: types.IAppState) => state.config);
  const [initialCart] = useState(cart);
  const [attempted, setAttempted] = useState(false);

  useEffect(() => {
    if (config.loading || config.configLoadError || attempted) {
      return;
    }

    async function tryToApplyPromotion() {
      try {
        const result = options.getCookieValue();
        if (result) {
          const [value, removeFn] = result;
          await options.applyPromotion({
            value,
            dispatch,
            cart: initialCart
          });
          removeFn();
        }
      } catch (err) {
        logInternalError(err);
      }
    }

    setAttempted(true);
    tryToApplyPromotion();
  }, [dispatch, initialCart, config, options, attempted]);
}

function useApplyCouponFromCookie() {
  const [bannerText, setBannerText] = useState<string | undefined>(undefined);

  useApplyPromotionFromCookie<CouponCookieData>(
    useMemo(
      () => ({
        getCookieValue: () => {
          const dataValue = Cookies.getJSON(couponCookieName);
          if (dataValue) {
            return [
              dataValue,
              () => {
                /* Noop */
              }
            ];
          }

          return undefined;
        },

        applyPromotion: async ({ value, dispatch, cart }) => {
          const result = await applyCouponToCart(cart, value.code);
          if (result.kind === "success") {
            dispatch(cartActions.addCoupon(result.value));
            if (value.bannerText) {
              setBannerText(value.bannerText);
            }
          }
        }
      }),
      []
    )
  );

  return { bannerText };
}

function useApplyGiftCardFromCookie() {
  return useApplyPromotionFromCookie<string>(
    useMemo(
      () => ({
        getCookieValue: () => {
          const value = Cookies.get(giftCardCookieName);
          if (value) {
            return [value, () => removeCookie(giftCardCookieName)];
          }
          return undefined;
        },

        applyPromotion: async ({ value, dispatch, cart }) => {
          const result = await applyGiftCardToCart(cart, value);
          if (result.kind === "success") {
            dispatch(cartActions.addGiftCard(result.value));
            events.cartPage.giftCardApplied(value);
          }
        }
      }),
      []
    )
  );
}

/**
 * If a fi_session_id cookie is present, we're already authenticated with the Fi
 * API server so create a session in the Redux store.
 */
function useCreateSessionFromCookie() {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    async function tryCreateSession() {
      try {
        await createSessionFromExistingCredentials();
      } catch (err) {
        console.error(err);
      }
    }
    let donePromise: Promise<void>;
    if (Cookies.get("fi_session_id")) {
      donePromise = tryCreateSession();
    } else {
      donePromise = Promise.resolve();
    }
    donePromise.then(() => setInitialized(true));
  }, []);
  return initialized;
}

/**
 * - loading: Segment's analytics.ready() has not been called yet
 * - waiting: Waiting for Optimize to potentially do its magic in the background.
 *   At this stage, we show the loading indicator but also render the page in the
 *   background, behind the async-hide style.
 * - done: Done.
 */
type AnalyticsState = "loading" | "waiting" | "done";

function useAnalytics(
  routeProps: RouteComponentProps,
  sessionInitialized: boolean
) {
  const [analyticsState, setAnalyticsState] = useState<AnalyticsState>(
    "loading"
  );
  const session = useSelector((state: types.IAppState) => state.session);
  const userId = session && session.userId;
  const email = session && session.email;

  // Identify users on login
  useEffect(() => {
    if (userId) {
      analytics.identify(userId, email || undefined);
    }
  }, [userId, email]);

  // Kick off analytics on mount
  useEffect(() => {
    if (sessionInitialized) {
      analyticsCoordinator
        .on("ready", () => setAnalyticsState("waiting"))
        .on("finished", () => setAnalyticsState("done"))
        .start();
    }
  }, [sessionInitialized]);

  // Navigation hooks (before page change, after page change)
  const [handledLocation, setHandledLocation] = useState(routeProps.location);
  if (routeProps.location !== handledLocation) {
    analyticsCoordinator.beforePageChange();
    setHandledLocation(routeProps.location);
  }
  // Only fire pageChange() *after* component is mounted.
  // https://stackoverflow.com/a/53351556/1480571
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      analyticsCoordinator.pageChange();
    } else {
      mounted.current = true;
    }
  }, [routeProps.location]);

  return { analyticsState };
}

function requireLogin<P>(Component: React.ComponentType<P>, returnTo: string) {
  return function RequireLogin(props: P) {
    const session = useSelector((state: types.IAppState) => state.session);
    return session ? (
      <Component {...props} />
    ) : (
      <Redirect to={`/login?returnTo=${encodeURIComponent(returnTo)}`} />
    );
  };
}

const App = withRouter((props: RouteComponentProps) => {
  const state: types.IAppState = useStore().getState();
  const dispatch = useDispatch();

  // Load config on mount
  useEffect(() => {
    dispatch(configActions.reloadConfig());
  }, [dispatch]);

  // Scroll to top of page on nav.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  const maybeLoggedIn = useCreateSessionFromCookie();
  const { analyticsState } = useAnalytics(props, maybeLoggedIn);

  const [couponBannerText, setCouponBannerText] = useState<string | undefined>(
    undefined
  );
  const dismissCouponBanner = () => setCouponBannerText(undefined);

  const bannerTextFromCookie = useApplyCouponFromCookie().bannerText;
  useEffect(() => {
    setCouponBannerText(bannerTextFromCookie);
  }, [bannerTextFromCookie]);
  // Clear coupon banner on nav.
  useEffect(() => dismissCouponBanner(), [props.location]);

  useApplyGiftCardFromCookie();

  const giftCardDetails = state.cart.giftCardDetails;
  const giftCardProduct = giftCardDetails
    ? findGiftCardProductFromDetails(giftCardDetails, state.config.products)
    : undefined;

  const queryParams = qs.parse(
    props.location.search[0] === "?"
      ? props.location.search.substr(1)
      : props.location.search
  );
  const [hideNav] = useState<boolean>(queryParams.nav === "false");

  const productsLoading = state.config.loading;
  const error = state.config.configLoadError;

  let productMap = useProductSKUMap();

  const shoppingCartItemCount = useMemo(() => {
    if (productsLoading) {
      return 0;
    }

    return Object.values(state.cart.lineItems).reduce((sum, lineItem) => {
      // Don't count subscriptions towards the total in the bag
      if (
        productMap.get(lineItem.sku)?.product.type === ProductType.Subscription
      ) {
        return sum;
      }
      return sum + lineItem.quantity;
    }, 0);
  }, [state.cart.lineItems, productsLoading, productMap]);

  const loadingIndicator = () => (
    <div className="column">
      <Loading />
    </div>
  );

  let body: JSX.Element;

  if (productsLoading || analyticsState === "loading") {
    body = loadingIndicator();
  } else if (error) {
    body = (
      <div className="error-page-container">
        <ErrorComponent error={error} />
      </div>
    );
  } else {
    body = (
      <>
        {analyticsState === "waiting" && loadingIndicator()}
        <div
          className={classNames({
            "async-hide": analyticsState === "waiting"
          })}
        >
          <Switch>
            <Route path="/login" component={SessionContainer} />
            <Route path="/passwordReset" component={PasswordResetContainer} />
            <Route
              path={CheckoutPaths.ThankYou}
              component={ThankYouContainer}
            />
            <Route path="/checkout" component={CheckoutContainer} />
            <Route exact path="/gift" component={GiftCardContainer} />
            <Route exact path="/referrals" component={ReferralsContainer} />
            <Route exact path="/rescue" component={RescueContainer} />
            <Route
              exact
              path="/rescue/placement"
              component={requireLogin(RescuePlacement, "/rescue/placement")}
            />
            <Route path="/r/:code" component={ReferralRedirect} />
            <Route path="/bag" component={CartContainer} />
            <Route
              exact
              path={Constants.ImpersonatePath}
              component={Impersonate}
            />
            <Route path="/subscription" component={SubscriptionListContainer} />
            <Route path="/products/:id" component={ProductDetailsContainer} />
            <Route exact path="/" component={HomeContainer} />
            <Route exact path="/accessories" component={AccessoriesContainer} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </>
    );
  }

  const noBottomPadding =
    props.location.pathname === "/" ||
    props.location.pathname.startsWith("/products/");
  const greyBg = props.location.pathname.startsWith("/referrals");
  const thankYouPage = props.location.pathname.startsWith(
    CheckoutPaths.ThankYou
  );

  let banner: JSX.Element | null = null;
  if (giftCardProduct) {
    banner = <GiftCardBanner product={giftCardProduct} />;
  } else if (couponBannerText) {
    banner = (
      <CouponBanner
        bannerText={couponBannerText}
        onDismiss={() => dismissCouponBanner()}
      />
    );
  } else if (state.cart.referralDetails) {
    banner = <ReferralBanner />;
  }

  let appBar: JSX.Element | null = null;
  if (!hideNav) {
    appBar = <AppBar shoppingCartItemCount={shoppingCartItemCount} />;
  }

  return (
    <div className={classNames({ "grey-bg": greyBg })}>
      {appBar}
      <ImpersonationWarning />
      {banner}
      <section
        className={classNames({
          "app-body": true,
          "no-bottom-padding": noBottomPadding,
          "thank-you-page-body": thankYouPage
        })}
      >
        {body}
      </section>
      <CJEventComponent />
    </div>
  );
});

export default App;
