// Copied from barkinglabs/web project @ src/lib/ecommerce/models/Product.ts
import {
  IECommerceSiteConfig,
  IProduct,
  ProductType,
  IShippingOption
} from "./Product";
export * from "./Product";

//
// General Types
//
export type Money = string;

export type ProductID = string;
export type LineItemID = string;

export interface IECommerceConfig {
  siteConfig: IECommerceSiteConfig;
  products: IProduct[];
  shippingOptions: IShippingOption[];
  loading: boolean;
  configLoadError: string | null;
}

export interface IAppState {
  cart: ICart;
  session: ISession | null;
  config: IECommerceConfig;
  experiment: IExperimentState;
  affiliateData: IAffiliateData;
}

export interface IAffiliateData {
  cjevent?: string;
}

//
// Cart Interfaces
//

export interface IReferralDetails {
  referralCode: string;
}

export interface ICart {
  lineItems: { [id: string]: ILineItem };
  // TODO: Fully remove this and replace with couponCode. See comment for `getCouponFromCart`.
  summary: ICartSummary;
  couponDetails?: ICouponDetails;
  giftCardDetails?: IGiftCardDetails;
  referralDetails?: IReferralDetails;
}

export interface IGiftCardDelivery {
  deliverAt: string | null;
  emailAddress: string;
  firstName: string | null;
  lastName: string | null;
  gifterName: string | null;
  personalMessage: string | null;
}

export interface ILineItem {
  id: LineItemID;
  productID: ProductID;
  sku: string;
  type: ProductType;
  description: string;
  details?: string;
  price: Money;
  quantity: number;
  subtotal?: Money;
  /**
   * Additional data about a gift card to be delivered.
   */
  giftCardDelivery?: IGiftCardDelivery;
}

export interface ICouponAmountDiscount {
  type: "dollars";
  amount: {
    USD: number;
  };
}

export interface ICouponPercentDiscount {
  type: "percent";
  rate: number;
}

export type CouponDiscount = ICouponAmountDiscount | ICouponPercentDiscount;

export interface ICouponDetails {
  code: string;
  name: string;
  discount: CouponDiscount;
  single_use: boolean;
  applies_to_non_plan_charges: boolean;
  applies_to_plans: boolean;
  plans: string[];
  applies_to_all_plans: boolean;
}

export interface IGiftCardDetails {
  redemptionCode: string;
  /**
   * In dollars.
   */
  unitAmount: number;
}

export interface ICartSummary {
  discount: Money;
  subtotal: Money;
  tax: Money;
  total: Money;
}

export interface IPriceState {
  now: IPrice;
  next: IPrice;
}

export interface IPrice {
  subtotal: string;
  discount: string;
  taxes: string;
  total: string;
}

//
// Session Interface
//

export interface ISession {
  userId: string;
  noPassword?: boolean;
  email: string | undefined;
  impersonating?: boolean;
}

//
// Checkout Interfaces
//

export enum CheckoutStep {
  AddShippingAddress,
  AddPaymentDetails,
  ConfirmPurchase,
  PurchaseComplete
}

export interface IAddress {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string | null;
}

export interface ICardInfo {
  type: string;
  lastFour: number;
  month: number;
  year: number;
}

export interface IBillingInfo {
  firstName: string;
  lastName: string;
  address: IAddress;
  cardInfo: ICardInfo;
}

export interface IInvoice {
  id: string;
}

export interface IExperimentState {
  experiments: string[];
}

export interface IApplePaymentCompletedCallback {
  onApplePaymentCompleted(info: {
    address?: IAddress;
    billingInfo: IBillingInfo;
    shippingCode?: string;
  }): void;
}
