import React, { useState, useCallback, useEffect } from "react";
import classnames from "classnames";

import XSmallDog from "../../../../assets/images/dog-xsmall.svg";
import SmallDog from "../../../../assets/images/dog-small.svg";
import MediumDog from "../../../../assets/images/dog-medium.svg";
import LargeDog from "../../../../assets/images/dog-large.svg";
import XlargeDog from "../../../../assets/images/dog-xlarge.svg";

import SizingBlock from "./SizingBlock";
import { SizeOption } from "../../../../types";

interface ISizingGuideProps {
  onClose: VoidFunction;
  onSelect: (newValue: SizeOption) => void;
}

export default function SizingGuide({ onClose, onSelect }: ISizingGuideProps) {
  const [hidingSizing, setHidingSizing] = useState(false);

  const contentDiv = React.createRef<HTMLDivElement>();

  const contentClasses = classnames("sizing-guide-contents", {
    "fade-out": hidingSizing
  });

  const handleAnimationEnd = useCallback(() => {
    if (hidingSizing) {
      onClose();
      setHidingSizing(false);
    }
  }, [onClose, hidingSizing]);

  useEffect(() => {
    if (contentDiv.current) {
      const eventListener = () => handleAnimationEnd();
      const element = contentDiv.current;
      element.addEventListener("animationend", eventListener);
      return () => {
        element.removeEventListener("animationend", eventListener);
      };
    }
  }, [contentDiv, hidingSizing, handleAnimationEnd]);

  const startClose = useCallback(() => {
    setHidingSizing(true);
  }, [setHidingSizing]);

  const handleSelect = useCallback(
    (value: SizeOption) => {
      onSelect(value);
      startClose();
    },
    [startClose, onSelect]
  );

  return (
    <div className="product-sizing-guide">
      <div className="click-capture" onClick={startClose}></div>
      <div className={contentClasses} ref={contentDiv}>
        <div className="guide-heading">
          <h4>Collar Sizing</h4>
          <button className="close-button" onClick={startClose}>
            <img alt="Close Guide" src="/close_x.svg" />
          </button>
        </div>
        <div className="guide-sizes">
          <SizingBlock
            icon={XSmallDog}
            dogList="Chihuahua, Bichon Frise, Yorkie, Pomeranian, Mini Poodle, etc."
            sizeName="X-Small"
            collarLength="Not Available"
            className="unavailable"
          />
          <SizingBlock
            icon={SmallDog}
            dogList="French Bulldog, Corgi, Cairn Terrier, Cavalier King Charles, Pit Bull, Border Collie, Aussie, etc."
            sizeName="Small"
            collarLength='11.5"-13.5"'
            onClick={() => handleSelect(SizeOption.Small)}
          />
          <SizingBlock
            icon={MediumDog}
            dogList="Beagle, Boston Terrier, French Bulldog, Corgie, Pug, Standard Poodle, etc."
            sizeName="Medium"
            collarLength='13" - 16.5"'
            onClick={() => handleSelect(SizeOption.Medium)}
          />
          <SizingBlock
            icon={LargeDog}
            dogList="Labrador Retriever, Siberian Husky, German Shepherd, Boxer, Doberman, Golden Retriever, etc."
            sizeName="Large"
            collarLength='16"-22.5"'
            onClick={() => handleSelect(SizeOption.Large)}
          />
          <SizingBlock
            icon={XlargeDog}
            dogList="Great Dane, Mastiff, Newfoundland, Great Pyrenees, Bloodhound, etc."
            sizeName="X-large"
            collarLength='22"-34.5"'
            onClick={() => handleSelect(SizeOption.XLarge)}
          />
        </div>
        <div className="still-unsure">
          Still unsure?{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.tryfi.com/hc/en-us/articles/360018860034-Sizing-and-Fit"
          >
            Check out this article &rarr;
          </a>
        </div>
      </div>
    </div>
  );
}
