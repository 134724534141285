import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import SubscriptionPicker from "../views/SubscriptionPicker";
import { Dispatch, isSubscriptionProduct } from "../lib/util";
import { cartActions } from "../reducers/cart";
import * as types from "../types";

interface ISubscriptionPickerContainerProps extends RouteComponentProps<{}> {}

const mapStateToProps = (
  state: types.IAppState,
  ownProps: ISubscriptionPickerContainerProps
) => {
  return {
    subscriptions: state.config.products.flatMap(product =>
      isSubscriptionProduct(product) ? [product] : []
    ),
    fromUpsell: !!(ownProps.location.state || {}).fromUpsell
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addLineItem: (lineItem: types.ILineItem) => {
      dispatch(cartActions.addLineItem(lineItem));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPicker);
