import { connect } from "react-redux";

import Cart from "../views/Cart";
import { Dispatch } from "../lib/util";
import { cartActions } from "../reducers/cart";
import { IAppState, LineItemID } from "../types";

const mapStateToProps = (state: IAppState) => {
  return {
    cart: state.cart,
    products: state.config.products,
    showCoupons: !!state.config.siteConfig.showCoupons,
    showShippingOptions: !!state.config.siteConfig.showShippingOptions,
    refereeRewardProductId: state.config.siteConfig.refereeRewardProductId
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    removeLineItem: (id: LineItemID) => {
      dispatch(cartActions.removeLineItem(id));
    },

    resetCart: () => {
      dispatch(cartActions.resetCart());
    },

    clearCoupon: () => {
      dispatch(cartActions.clearCoupon());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
