import * as React from "react";
import classNames from "classnames";

export interface ICheckboxProps {
  checked: boolean;
  onChange(newChecked: boolean): void;
  label: string;
  className?: string;
  disabled?: boolean;
}

/**
 * A custom styled checkbox.
 */
const Checkbox = ({
  checked,
  onChange,
  label,
  className,
  disabled
}: ICheckboxProps) => (
  <label className={classNames("checkbox-custom", className)}>
    <input
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={e => onChange(e.target.checked)}
    />
    <span className="checkbox-icon" />
    {label}
  </label>
);

export default Checkbox;
