import { useState } from "react";
import { IGiftCardProduct, ILineItem } from "../../types";
import { generateID, centsToDollars, validateEmail } from "../../lib/util";
import * as events from "../../lib/analytics/events";
import { IGiftCardState, FormErrors } from "./GiftCardContext";
import { DateTime } from "luxon";

interface ICheckoutHookResponse {
  addToBag: (product: IGiftCardProduct, state: IGiftCardState) => void;
  addedToBag: boolean;
}

export default function useCheckout(
  addLineItem: (lineItem: ILineItem) => void,
  setErrors: (errors: FormErrors) => void
): ICheckoutHookResponse {
  const [addedToBag, setAddedToBag] = useState(false);

  const addToBag = (product: IGiftCardProduct, state: IGiftCardState) => {
    const errors: FormErrors = {};
    const { delivery, deliverAtIsSet } = state;

    if (!delivery.firstName) {
      errors.gifteeName = "Please enter a name for the giftee.";
    }
    if (!validateEmail(delivery.emailAddress)) {
      errors.emailAddress = "Please enter a valid email address.";
    }
    if (!delivery.gifterName) {
      errors.gifterName = "Please enter your name.";
    }
    const genericDeliverAtError = `Please enter a date in the future, or select "Send now"`;
    if (!deliverAtIsSet) {
      errors.deliverAt = genericDeliverAtError;
    } else if (delivery.deliverAt) {
      const deliverAtDate = DateTime.fromISO(delivery.deliverAt);
      if (deliverAtDate < DateTime.local().endOf("day")) {
        errors.deliverAt = genericDeliverAtError;
      } else if (deliverAtDate > DateTime.local().plus({ years: 1 })) {
        errors.deliverAt =
          "Delivery date must be less than a year in the future.";
      }
    }

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      setErrors(errors);
    } else {
      const itemID = generateID();
      if (product) {
        events.addToBag({ product });
        addLineItem({
          id: itemID,
          productID: product.id,
          sku: product.sku,
          type: product.type,
          description: product.name,
          price: centsToDollars(product.priceInCents),
          quantity: 1,
          giftCardDelivery: delivery
        });
      }
      setAddedToBag(true);
    }
  };

  return { addToBag, addedToBag };
}
