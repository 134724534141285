import { IWindow } from "./Window";

const PUBLIC_KEY = process.env.REACT_APP_RECURLY_PUBLIC_KEY || "";

export interface IError {
  name: string;
  code: string;
  message: string;
  fields: string[];
}

export interface IToken {
  id: string;
}

export interface IConfiguration {
  publicKey?: string;
  required?: string[];
}

// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/2216121-requiredshippingcontactfields
export type ShippingContactField = "email" | "name" | "postalAddress" | "phone";

interface IRecurlyApplePayOptions {
  country: "US";
  currency: "USD";
  label: string;
  total?: string;
  pricing?: any;
  /**
   * Undocumented as of yet, but looks stable.
   * https://github.com/recurly/recurly-js/pull/474
   */
  requiredShippingContactFields?: ShippingContactField[];
}

export interface IRecurlyApplePay {
  ready(cb: () => void): void;
  begin(): void;
  on(
    eventName: "error",
    cb: (err: { code: any; message: string }) => void
  ): void;
  on(eventName: "token", cb: (token: { id: string }) => void): void;
  on(eventName: "cancel", cb: () => void): void;
  on(
    eventName: "shippingContactSelected",
    cb: (evt: ApplePayJS.ApplePayShippingContactSelectedEvent) => void
  ): void;
  off(eventName?: string, cb?: any): void;

  // Private undocumented Recurly methods that we need to override.
  onPaymentAuthorized?(evt: ApplePayJS.ApplePayPaymentAuthorizedEvent): void;
  onShippingContactSelected?(
    evt: ApplePayJS.ApplePayShippingContactSelectedEvent
  ): void;
  onShippingMethodSelected?(
    evt: ApplePayJS.ApplePayShippingMethodSelectedEvent
  ): void;

  session: ApplePaySession;
  finalTotalLineItem: any;
  lineItems: any;
}

export interface IRecurly {
  Pricing: {
    Checkout: () => Recurly.CheckoutPricing;
    Subscription: () => Recurly.SubscriptionPricing;
  };
  ApplePay: (options: IRecurlyApplePayOptions) => IRecurlyApplePay;
  configure(configuration: IConfiguration): void;
  token(form: any, callback: (err?: IError, token?: IToken) => void): void;
}

export class Provider {
  private static instance: Provider;

  public value: IRecurly;

  constructor(source: IWindow = window) {
    if (Provider.instance) {
      this.value = Provider.instance.value;
      return Provider.instance;
    }

    if (!source.recurly) {
      throw new Error(
        "Please load Recurly.js (https://js.recurly.com/v4/recurly.js) on this page"
      );
    } else {
      this.value = source.recurly;
      this.value.configure({ publicKey: PUBLIC_KEY, required: ["cvv"] });
    }
  }
}

export default Provider;
