import { applyMiddleware, compose, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";

import rootReducer, { initialAppState } from "../reducers";
import { affiliateDataInitialState } from "../reducers/affiliateData";
import { configInitialState } from "../reducers/config";
import { IAppState } from "../types";

/**
 * This dictionary overrides things in the app state before it is persisted and
 * when it is loaded from persistence.
 *
 * These items are not meant to be persisted -- they should be loaded every time.
 */
const overwriteStateItemsForPersistence = {
  ...configInitialState,
  ...affiliateDataInitialState
};

const storedState = localStorage.getItem("reduxState");
const persistedState: IAppState = storedState
  ? {
      ...initialAppState,
      ...JSON.parse(storedState),
      ...overwriteStateItemsForPersistence
    }
  : initialAppState;

const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers: typeof compose =
  process.env.NODE_ENV !== "production" && devToolsCompose
    ? devToolsCompose
    : compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<IAppState>))
);

store.subscribe(() => {
  // Persist the redux state except for the products, we want to pull those every time
  // the page reloads
  const state = store.getState();
  // Remove couponDetails from cart, the source-of-truth between page loads for coupon data
  // is the cookie.
  const { couponDetails, ...newCart } = state.cart;
  const newPersistedState: IAppState = {
    ...store.getState(),
    ...overwriteStateItemsForPersistence,
    cart: newCart
  };
  localStorage.setItem("reduxState", JSON.stringify(newPersistedState));
});

export default store;
