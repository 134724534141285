import { useMemo } from "react";
import { useSelector } from "react-redux";

import * as types from "../types";

import useProductSKUMap from "./useProductSKUMap";

export interface ISubscriptionCartInfo {
  // The number of subscriptions in the cart
  subscriptionsInCart: number;
  // The number of products in the cart that require a subscription
  subscriptionProductsInCart: number;
}

/**
 * A lightweight window into the cart and its subscriptions - how many are in the cart
 * vs. subscription-requiring products.
 */
export default function useSubscriptionCartInfo(): ISubscriptionCartInfo {
  const cart = useSelector((state: types.IAppState) => state.cart);
  const productMap = useProductSKUMap();

  return useMemo(() => {
    const cartItems = Object.values(cart.lineItems);

    const subscriptionsInCart = cartItems.filter(
      (item: types.ILineItem): boolean => {
        return item.type === types.ProductType.Subscription;
      }
    ).length;

    const subscriptionProductsInCart = cartItems.filter(
      (item: types.ILineItem): boolean => {
        const p = productMap.get(item.sku)?.product;
        return p?.subscriptionRequired ?? false;
      }
    ).length;

    return {
      subscriptionsInCart,
      subscriptionProductsInCart
    };
  }, [cart, productMap]);
}
