import React, { useState, useCallback, useMemo, useContext } from "react";
import classNames from "classnames";
import * as types from "../../../../types";
import ordinal from "ordinal";
import { DateTime } from "luxon";
import ShippingOptionItem from "./ShippingOptionsItem";
import CheckoutContext, { setShippingCode } from "../../CheckoutContext";
import { isExpeditedShippingAvailable } from "../../../../lib/util";
import { isPreorderSku } from "../../../../lib/frontendProductConfig";

interface IShippingOptionListProps {
  shippingOptions: types.IShippingOption[];
}

export default function ShippingOptionList({
  shippingOptions
}: IShippingOptionListProps) {
  const { checkoutState, checkoutDispatch } = useContext(CheckoutContext);

  const anyPreorder = useMemo(
    () =>
      Object.values(checkoutState.orderCart.lineItems).some(li =>
        isPreorderSku(li.sku)
      ),
    [checkoutState.orderCart.lineItems]
  );

  const canExpedite = useMemo(
    () =>
      checkoutState.shippingAddress
        ? isExpeditedShippingAvailable(checkoutState.shippingAddress)
        : false,
    [checkoutState.shippingAddress]
  );

  const [currentlySelected, setCurrentlySelected] = useState(
    checkoutState.shippingCode
  );

  const disclaimer: string | undefined = useMemo(() => {
    const shippingOption = currentlySelected
      ? shippingOptions.find(so => so.code === currentlySelected)
      : shippingOptions[0];

    if (!canExpedite) {
      return "* Expedited shipping not available for P.O. Box addresses";
    } else if (shippingOption?.arrivesOn && !anyPreorder) {
      const arrivesOnDate = DateTime.fromISO(
        shippingOption.arrivesOn
      ).toLocal();
      const ordinalDay = ordinal(arrivesOnDate.day);
      const formattedDate = arrivesOnDate.toFormat(
        `cccc, LLLL '${ordinalDay}'`
      ); // Friday, August 6th

      return `* This order will arrive on ${formattedDate}`;
    }
  }, [currentlySelected, anyPreorder, shippingOptions, canExpedite]);

  const onComplete = useCallback(() => {
    if (currentlySelected) {
      checkoutDispatch(setShippingCode(currentlySelected));
    }
  }, [currentlySelected, checkoutDispatch]);

  return (
    <>
      <div className="shipping-option-list">
        {shippingOptions.map(({ code, name, priceInCents: price_in_cents }) => (
          <ShippingOptionItem
            key={code}
            selected={currentlySelected === code}
            name={name}
            onClick={() => setCurrentlySelected(code)}
            priceInCents={price_in_cents}
          />
        ))}
        {disclaimer && <div className="shipping-disclaimer">{disclaimer}</div>}
      </div>
      <div className="action-container">
        <button
          className={classNames("flow-button--yellow", {
            "flow-button--disabled": currentlySelected === undefined
          })}
          onClick={onComplete}
        >
          Next
        </button>
      </div>
    </>
  );
}
