import React from "react";

import { IBillingInfo } from "../../../../types";

interface IViewBillingInfo {
  billingInfo: IBillingInfo;
  onEdit(): void;
  onComplete(billingInfo: IBillingInfo): void;
}

export default class ViewBillingInfo extends React.Component<IViewBillingInfo> {
  public render() {
    return (
      <>
        <p>
          {this.props.billingInfo.firstName} {this.props.billingInfo.lastName}
        </p>
        <p>
          {this.props.billingInfo.cardInfo.type} **** **** ****{" "}
          {this.props.billingInfo.cardInfo.lastFour}
        </p>
        <p>
          {this.props.billingInfo.cardInfo.month} /{" "}
          {this.props.billingInfo.cardInfo.year}
        </p>
        <div className="action-container">
          <button className="flow-button" onClick={this.handleEdit}>
            Edit
          </button>
          <button className="flow-button--yellow" onClick={this.handleContinue}>
            Next
          </button>
        </div>
      </>
    );
  }

  private handleEdit: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    this.props.onEdit();
  };

  private handleContinue: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault();
    this.props.onComplete(this.props.billingInfo);
  };
}
