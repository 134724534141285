import { wrapArguments } from "../util";
import { IWindow } from "../Window";

function withDataLayer(
  thunk: (dataLayer: Exclude<IWindow["dataLayer"], undefined>) => void
) {
  const extendedWindow: IWindow = window;
  if (extendedWindow.dataLayer) {
    thunk(extendedWindow.dataLayer);
  }
}

/**
 * Trigger an event that activates Google Optimize and causes it
 * to issue necessary DOM modifications. Should be called on page load,
 * etc.
 */
export function activate() {
  withDataLayer(dataLayer => {
    dataLayer.push({ event: "optimize.activate" });
  });
}

/**
 * Register an Optimize callback that will attach Optimize experiment
 * identifiers to our Redux store.
 */
export function registerCallback(
  addToExperiments: (experiments: string[]) => void
) {
  // https://support.google.com/optimize/answer/9059383
  withDataLayer(dataLayer => {
    dataLayer.push(
      wrapArguments("event", "optimize.callback", {
        callback: (value: string, name: string) => {
          if (name && value) {
            const experimentName = `${name}-${value}`;
            addToExperiments([experimentName]);
          }
        }
      })
    );
  });
}
