import React, { useMemo, useCallback } from "react";
import {
  IProduct,
  SizeOption,
  ProductKind,
  IVariantOptions
} from "../../types";
import { ProductLinkLoc, ProductBackButton } from "../../lib/productLinks";

import { ModuleItem } from "./AccessoryTypes";
import AccessoryGroup from "./components/AccessoryGroup";

interface IAccessoriesProps {
  products: IProduct[];
  linkFrom?: ProductLinkLoc;
}

function Accessories({ products, linkFrom }: IAccessoriesProps) {
  const findProducts = useCallback(
    (
      descriptors: Array<{
        id: string;
        options?: Partial<IVariantOptions>;
        nameOverride?: string;
      }>
    ): ModuleItem[] => {
      const items: ModuleItem[] = [];
      // Make sure to return the products in the same order as the `names` array was passed in.
      for (const { id, options, nameOverride } of descriptors) {
        const product = products.find(p => p.id === id);
        if (product) {
          if (options && product.kind === ProductKind.Complex) {
            const variants = product.variants.filter(
              v =>
                (options.size === undefined ||
                  options.size === v.options.size) &&
                (options.color === undefined ||
                  options.color === v.options.color) &&
                (options.collarType === undefined ||
                  options.collarType === v.options.collarType)
            );
            variants.forEach(variant => {
              items.push({
                kind: ProductKind.Complex,
                product,
                variant,
                nameOverride
              });
            });
          } else if (product.kind === ProductKind.Simple) {
            items.push({
              kind: ProductKind.Simple,
              product,
              nameOverride
            });
          }
        }
      }

      return items;
    },
    [products]
  );

  const accessoryItems = useMemo((): ModuleItem[] => {
    return findProducts([
      { id: "additional-band", options: { size: SizeOption.Medium } },
      { id: "additional-base" },
      // Note: this is for impersonators only, but if we're not impersonating
      // then it won't be in the products array so it shouldn't show up anyway.
      { id: "module-only" }
    ]);
  }, [findProducts]);

  return (
    <>
      {linkFrom && <ProductBackButton from={linkFrom} />}
      <div className="accessories-page">
        <AccessoryGroup
          title="Accessories"
          subtitle="Spare bands and bases"
          items={accessoryItems}
        />
      </div>
    </>
  );
}

export default Accessories;
