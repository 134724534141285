import * as React from "react";

import * as types from "../types";
import { ComplexProductDetails } from "../views/ProductDetails";

interface IHomeProps {
  featuredProduct?: types.IComplexProduct;
  addLineItem(lineItem: types.ILineItem): void;
}

export default class Home extends React.Component<IHomeProps> {
  public render() {
    return (
      <ComplexProductDetails
        product={this.props.featuredProduct as types.IComplexProduct}
        addLineItem={this.props.addLineItem}
      />
    );
  }
}
