import {
  defaultRescuePlacementNavigationState,
  rescuePlacementPath,
  RescuePlacementNavigationState
} from "./rescuePlacementUtils";
import React from "react";
import { Link } from "react-router-dom";

export default function GoBackLink({
  state
}: {
  state?: RescuePlacementNavigationState;
}) {
  return (
    <Link
      to={{
        pathname: rescuePlacementPath,
        state: state || defaultRescuePlacementNavigationState
      }}
    >
      &lt; Go back
    </Link>
  );
}
