import * as React from "react";
import { Link, Redirect } from "react-router-dom";

import { login } from "../lib/fi-api/authentication";
import ErrorComponent from "./ErrorComponent";

interface ILoggedOutSessionProps {
  returnTo?: string;
  applePayError?: boolean;
}

interface ILoggedOutSessionState {
  loginError: string | null;
}

class LoggedOutSession extends React.Component<
  ILoggedOutSessionProps,
  ILoggedOutSessionState
> {
  constructor(props: ILoggedOutSessionProps) {
    super(props);
    this.state = { loginError: null };
  }

  public render() {
    return (
      <div className="single-column-container login-form">
        <div className="single-column">
          <div className="login-body">
            <h3>Existing Users</h3>
            {this.props.applePayError && (
              <div>
                It looks like you already have a Fi account. Please log in and
                try your purchase again.{" "}
                <Link to="/passwordReset" className="text--highlight">
                  Forgot your password?
                </Link>
              </div>
            )}
            <ErrorComponent error={this.state.loginError} />
            <form onSubmit={this.handleLogin} className="basic-form wide">
              <input type="text" name="email" placeholder="Email" />
              <input type="password" name="password" placeholder="Password" />
              <input
                type="submit"
                className="button button--epic"
                value="Login"
              />
              <div className="single-column-container wide login-sub-actions">
                <Link
                  to={this.props.returnTo || "/"}
                  className="button button--plain"
                >
                  Nevermind, take me back
                </Link>
                <Link to={"/passwordReset"} className="button button--plain">
                  Forgot your password?
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  private handleLogin = async (event: any) => {
    event.preventDefault();
    const email = event.target.email.value.trim();
    const password = event.target.password.value.trim();

    try {
      await login(email, password);
    } catch (error) {
      if (error) {
        this.setState({ loginError: error.message });
      }
    }
  };
}

interface ISessionProps extends ILoggedOutSessionProps {
  loggedIn: boolean;
  returnTo?: string;
  applePayError?: boolean;
}

export default class Session extends React.Component<ISessionProps> {
  public render() {
    if (this.props.loggedIn) {
      return <Redirect to={this.props.returnTo || "/"} />;
    } else {
      return (
        <LoggedOutSession
          returnTo={this.props.returnTo}
          applePayError={this.props.applePayError}
        />
      );
    }
  }
}
