import Cookies from "js-cookie";
import { CouponCookieData, couponCookieName, devMode } from "../../lib/util";
import { DateTime } from "luxon";

export default function setCouponCookie(code: string) {
  const data: CouponCookieData = { code };
  Cookies.set(couponCookieName, JSON.stringify(data), {
    expires: DateTime.utc()
      .plus({ hours: 72 })
      .toJSDate(),
    domain: devMode ? undefined : "tryfi.com",
    secure: !devMode
  });
}
