import React, { useState, useContext, useEffect } from "react";

import * as gqlTypes from "../../../../types/gql-op-types";

import ErrorMessages from "../../../../lib/errors";
import { generateID, logInternalError } from "../../../../lib/util";
import { queryBillingAccount } from "../../../../lib/BillingAccount.graphql";
import { updateShippingAddress } from "../../../../lib/ShippingInfo.graphql";

import ShippingAddressSection from "./ShippingAddressSection";
import CheckoutContext from "../../CheckoutContext";
import { useQuery, useMutation } from "react-apollo-hooks";

interface IShippingAddressContainerProps {
  loggedIn: boolean;
  nonPhysicalCheckout: boolean;
}

export default function ShippingAddressContainer({
  loggedIn,
  nonPhysicalCheckout
}: IShippingAddressContainerProps) {
  const [errorID, setErrorID] = useState<string | undefined>(undefined);
  const { checkoutState } = useContext(CheckoutContext);

  const { data, loading: queryLoading, error: queryError } = useQuery<
    gqlTypes.billingAccount
  >(queryBillingAccount, {
    fetchPolicy: "network-only",
    skip: !loggedIn
  });

  useEffect(() => {
    if (queryError) {
      logInternalError(queryError);
      setTimeout(() => setErrorID(generateID()), 0);
    }
  }, [queryError]);

  const [
    mutation,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation<
    gqlTypes.updateShippingAddress,
    gqlTypes.updateShippingAddressVariables
  >(updateShippingAddress);

  let error: string | undefined;
  // TODO: Log error
  if (queryError) {
    error = ErrorMessages.DEFAULT;
  } else if (mutationError) {
    error = ErrorMessages.INPUT_ERROR;
  }

  const loading = queryLoading || mutationLoading;

  const address =
    checkoutState.shippingAddress ??
    data?.currentUser?.billingAccount?.address ??
    null;

  return (
    <ShippingAddressSection
      loggedInUserName={data?.currentUser?.firstName}
      loggedIn={loggedIn}
      mutation={(...args) => {
        try {
          return mutation(...args);
        } finally {
          setErrorID(generateID());
        }
      }}
      loading={loading}
      error={error}
      errorID={errorID}
      address={address}
      nonPhysicalCheckout={nonPhysicalCheckout}
    />
  );
}
