import React from "react";

import { BaseOptionButton } from "./BaseOptionButton";
import * as types from "../../../../types";
import classNames from "classnames";

const ColorOptionButton = BaseOptionButton<types.ColorOption>({
  classForProps: props => {
    return classNames(
      "round-button",
      `round-button--color-${props.value}`,
      {
        "round-button--selected": props.available && props.selected
      },
      { "round-button--unavailable": !props.available }
    );
  },
  contentsForProps: _ => {
    return <div className="round-button--contents"></div>;
  }
});

export default ColorOptionButton;
