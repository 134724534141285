import * as React from "react";

const RemoveButton = ({ onClick }: { onClick: () => void }) => (
  <div>
    {/* eslint-disable-next-line */}
    <a
      href="#"
      className="remove-button"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      <span className="hidden-sm-up">X</span>
      <span className="hidden-sm-down">Remove</span>
    </a>
  </div>
);

export default RemoveButton;
