import * as React from "react";

interface ICouponBannerProps {
  bannerText: string;
  onDismiss(): void;
}

class CouponBanner extends React.Component<ICouponBannerProps> {
  public render() {
    return (
      <div className="coupon-banner">
        <div className="coupon-banner--content">
          <span className="coupon-banner--gift-box" />
          {this.props.bannerText}
        </div>
        <div
          className="coupon-banner--dismiss-button"
          onClick={this.props.onDismiss}
        >
          <span className="coupon-banner--dismiss-icon" />
        </div>
      </div>
    );
  }
}

export default CouponBanner;
