import gql from "graphql-tag";

export const referralsQuery = gql`
  query referrals {
    currentUser {
      id
      ...referralsFragment
    }
  }

  fragment referralsFragment on User {
    referrals {
      referralCode
      numConfirmedReferrals
      numPendingReferrals
      unlockedRewardTypes
      codeSuspended
    }
  }
`;
