import React from "react";
import { useDispatch } from "react-redux";
import RemoveButton from "./RemoveButton";
import { cartActions } from "../reducers/cart";

interface IGiftCardAppliedProps {
  showRemoveButton?: boolean;
}

export default function GiftCardApplied({
  showRemoveButton
}: IGiftCardAppliedProps) {
  const dispatch = useDispatch();
  return (
    <div className="gift-card-applied">
      <div>
        <img
          src="/gift_card_icon.png"
          alt="Icon of a Fi gift card"
          width="31"
          height="21"
        />
        <strong>Gift card applied!</strong>
      </div>
      {showRemoveButton && (
        <RemoveButton onClick={() => dispatch(cartActions.clearGiftCard())} />
      )}
    </div>
  );
}
