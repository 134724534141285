import React, { useContext, useState, useEffect, useMemo } from "react";
import GiftCardContext, {
  clearError,
  updateGiftCardDelivery,
  setDeliverAtIsSet
} from "../../GiftCardContext";
import { DateTime, Info } from "luxon";
import Checkbox from "../../../../components/Checkbox";
import SimpleError from "./SimpleError";

const DELIVERY_HOUR = 11;

/**
 * Allow the user to select a delivery date, or to deliver immediately.
 */
export default function DeliveryDateInput({
  disabled
}: {
  disabled?: boolean;
}) {
  const enabled = !disabled;

  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);

  const [month, setMonth] = useState<number | null>(null);
  const [day, setDay] = useState<number | null>(null);
  const [year, setYear] = useState(9999); // Note: this is set to the current date by a useEffect below.
  const [deliverNow, setDeliverNow] = useState(false);

  // Set initial values.
  useEffect(() => {
    const date = DateTime.local();
    setYear(date.year);
  }, []);

  // Set the `deliverAt` date in the gift card store.
  useEffect(() => {
    if (deliverNow) {
      giftCardDispatch(updateGiftCardDelivery({ deliverAt: null }));
      giftCardDispatch(setDeliverAtIsSet(true));
    } else {
      if (day !== null && month !== null) {
        const date = DateTime.fromObject({
          day,
          month,
          year,
          hour: DELIVERY_HOUR
        });
        giftCardDispatch(updateGiftCardDelivery({ deliverAt: date.toISO() }));
        giftCardDispatch(setDeliverAtIsSet(true));
      } else {
        giftCardDispatch(setDeliverAtIsSet(false));
      }
    }
  }, [day, month, year, deliverNow, giftCardDispatch]);

  const dayOptions = useMemo(() => {
    const result: JSX.Element[] = [];
    let daysInMonth = 31;
    if (month !== null) {
      daysInMonth = DateTime.fromObject({ month, day: 1, year }).daysInMonth;
    }
    for (let i = 1; i <= daysInMonth; i++) {
      result.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return result;
  }, [month, year]);

  const yearOptions = useMemo(() => {
    const result: JSX.Element[] = [];
    const now = DateTime.local();
    const currentYear = now.year;
    for (
      let year = currentYear;
      DateTime.fromObject({ year, month: 1, day: 1 }).diff(now, "years").years <
      1;
      year++
    ) {
      result.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return result;
  }, []);

  /**
   * Ensure the day is valid within a newly set month/year.
   */
  const ensureValidDay = (newValues: { month?: number; year?: number }) => {
    if (month !== null && day !== null) {
      const daysInNewMonth = DateTime.fromObject({
        month,
        year,
        ...newValues,
        day: 1
      }).daysInMonth;
      if (day > daysInNewMonth) {
        setDay(daysInNewMonth);
      }
    }
  };

  const error = giftCardState.errors.deliverAt;
  const clearDeliverAtError = () => giftCardDispatch(clearError("deliverAt"));

  return (
    <>
      <div className="subheader-with-message">
        <h3>Delivery date</h3>
        <span className="text--muted">Up to a year from today</span>
      </div>
      <div className="delivery-date-input-container">
        <div className="delivery-date-date">
          <select
            name="month"
            disabled={deliverNow || !enabled}
            value={month || "unselected"}
            onChange={e => {
              const value = e.currentTarget.value;
              const newMonth =
                value === "unselected" ? null : parseInt(value, 10);
              if (newMonth !== null) {
                ensureValidDay({ month: newMonth });
              }
              setMonth(newMonth);
              clearDeliverAtError();
            }}
          >
            <option value="unselected">Month</option>
            {Info.months().map((month, i) => (
              <option key={i} value={i + 1}>
                {month}
              </option>
            ))}
          </select>
          <select
            name="day"
            disabled={deliverNow || !enabled}
            value={day || "unselected"}
            onChange={e => {
              const value = e.currentTarget.value;
              const newDay =
                value === "unselected" ? null : parseInt(value, 10);
              setDay(newDay);
              clearDeliverAtError();
            }}
          >
            <option value="unselected">Day</option>
            {dayOptions}
          </select>
          <select
            name="year"
            disabled={deliverNow || !enabled}
            value={year}
            onChange={e => {
              const newYear = parseInt(e.currentTarget.value, 10);
              ensureValidDay({ year: newYear });
              setYear(newYear);
              clearDeliverAtError();
            }}
          >
            {yearOptions}
          </select>
        </div>
        <div className="delivery-date-now">
          <Checkbox
            disabled={!enabled}
            checked={deliverNow}
            onChange={value => {
              setDeliverNow(value);
              clearDeliverAtError();
            }}
            label="Send now"
          />
        </div>
        <SimpleError error={error} />
      </div>
    </>
  );
}
