import React from "react";
import {
  RescuePlacementModel,
  useRescuePlacementNavigator,
  RedirectToRescuePlacementHome,
  transferringOwnershipTitle
} from "./rescuePlacementUtils";
import GoBackLink from "./GoBackLink";
import PetView from "./PetView";

export default function PetPlacement({
  model,
  petId
}: {
  model: RescuePlacementModel;
  petId: string;
}) {
  const navigator = useRescuePlacementNavigator();

  const pet = model.getPetById(petId);

  if (!pet) {
    return <RedirectToRescuePlacementHome />;
  }

  return (
    <div>
      <GoBackLink />
      <PetView pet={pet} title={transferringOwnershipTitle(pet)} />
      <div>
        <div
          className="button button--outline"
          onClick={() =>
            navigator.go({ placementView: "petTemporaryPlacement", petId })
          }
        >
          Transfer to a foster parent
        </div>
      </div>
      <div>
        <div
          className="button button--outline"
          onClick={() =>
            navigator.go({ placementView: "petPermanentPlacement", petId })
          }
        >
          Transfer to a permanent parent
        </div>
      </div>
    </div>
  );
}
