import { BaseOptionButton } from "./BaseOptionButton";
import * as types from "../../../../types";
import classNames from "classnames";

const SizeOptionButton = BaseOptionButton<types.SizeOption>({
  classForProps: props => {
    return classNames("square-button", {
      selected: props.selected,
      unavailable: !props.available,
      blocked: props.blocked
    });
  },
  contentsForProps: props => {
    switch (props.value) {
      case types.SizeOption.Small:
        return "S";
      case types.SizeOption.Medium:
        return "M";
      case types.SizeOption.Large:
        return "L";
      case types.SizeOption.XLarge:
        return "XL";
    }
  }
});

export default SizeOptionButton;
