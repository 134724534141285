import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Dispatch,
  getProductsBySku,
  couponCookieName,
  removeCookie
} from "../lib/util";
import * as types from "../types";
import { priceCart, CouponError, PriceCartResult } from "../lib/pricing";

const initialState: types.ICart = {
  lineItems: {},
  summary: {
    discount: "0.00",
    subtotal: "0.00",
    tax: "0.00",
    total: "0.00"
  }
};

interface IAddLineItem {
  lineItem: types.ILineItem;
}

interface IRemoveLineItem {
  id: types.LineItemID;
}

interface ISetCoupon {
  details: types.ICouponDetails | undefined;
}

interface ISetGiftCard {
  details: types.IGiftCardDetails | undefined;
}

interface ISetReferralCode {
  details: types.IReferralDetails | undefined;
}

interface ISetUpdatedPricing {
  pricing: types.IPriceState;
}

interface IResetCart {
  resetCoupon?: boolean;
}

function splitIntoSubscriptionsAndCollars(
  products: types.IProduct[],
  lineItems: { [id: string]: types.ILineItem }
): [types.ILineItem[], types.ILineItem[]] {
  const productsBySku = getProductsBySku(products);

  const subscriptions: types.ILineItem[] = [];
  const collars: types.ILineItem[] = [];

  for (const lineItem of Object.values(lineItems)) {
    const product = productsBySku.get(lineItem.sku);
    if (product) {
      if (product.subscriptionRequired) {
        collars.push(lineItem);
      } else if (product.type === types.ProductType.Subscription) {
        subscriptions.push(lineItem);
      }
    }
  }

  return [subscriptions, collars];
}

const setCoupon = createAction<ISetCoupon>("cart/setCoupon");
const setGiftCard = createAction<ISetGiftCard>("cart/setGiftCard");
const setUpdatedPricing = createAction<ISetUpdatedPricing>(
  "cart/setUpdatedPricing"
);
const setReferral = createAction<ISetReferralCode>("cart/setReferral");

const updatePricing = () => async (
  dispatch: Dispatch,
  getState: () => types.IAppState
) => {
  try {
    let result: PriceCartResult;
    try {
      result = await priceCart(getState().cart);
    } catch (err) {
      if (err instanceof CouponError) {
        // Try removing the coupon and re-pricing
        dispatch(setCoupon({ details: undefined }));
        result = await priceCart(getState().cart);
      } else {
        // Other error, bail.
        throw err;
      }
    }
    dispatch(setUpdatedPricing({ pricing: result.pricing }));
  } catch (criticalErr) {
    // Could consider logging here, but this function gets called a ton.
    // If we get here the cart is in a bad state.
  }
};

const name = "cart" as const;

const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    addLineItem(cart: types.ICart, { payload }: PayloadAction<IAddLineItem>) {
      const lineItem = {
        ...payload.lineItem,
        quantity: 1
      };
      const newLineItems = {
        ...cart.lineItems,
        [lineItem.id]: lineItem
      };
      return {
        ...cart,
        lineItems: newLineItems
      };
    },

    removeLineItem(
      cart: types.ICart,
      { payload }: PayloadAction<IRemoveLineItem>
    ) {
      const { [payload.id]: removedLineItem, ...newLineItems } = cart.lineItems;
      return {
        ...cart,
        lineItems: newLineItems
      };
    },

    resetCart(cart: types.ICart, { payload }: PayloadAction<IResetCart>) {
      let newState = { ...initialState };
      if (!payload.resetCoupon) {
        const couponDetails = cart.couponDetails;
        newState = { ...newState, couponDetails };
      }
      return newState;
    }
  },

  extraReducers: {
    [setUpdatedPricing.type](
      cart: types.ICart,
      { payload }: PayloadAction<ISetUpdatedPricing>
    ) {
      return {
        ...cart,
        summary: {
          discount: payload.pricing.now.discount,
          subtotal: payload.pricing.now.subtotal,
          tax: payload.pricing.now.taxes,
          total: payload.pricing.now.total
        }
      };
    },

    [setCoupon.type](
      cart: types.ICart,
      { payload }: PayloadAction<ISetCoupon>
    ): types.ICart {
      return {
        ...cart,
        couponDetails: payload.details
      };
    },

    [setGiftCard.type](
      cart: types.ICart,
      { payload }: PayloadAction<ISetGiftCard>
    ): types.ICart {
      return {
        ...cart,
        giftCardDetails: payload.details
      };
    },

    [setReferral.type](
      cart: types.ICart,
      { payload }: PayloadAction<ISetReferralCode>
    ): types.ICart {
      return {
        ...cart,
        referralDetails: payload.details
      };
    }
  }
});

export const cartActions = {
  ...actions,

  addLineItem: (lineItem: types.ILineItem) => (
    dispatch: Dispatch,
    getState: () => types.IAppState
  ) => {
    const {
      config: { products },
      cart
    } = getState();
    const [subscriptions, collars] = splitIntoSubscriptionsAndCollars(
      products,
      cart.lineItems
    );
    const newProduct = getProductsBySku(products).get(lineItem.sku);
    // Ensure that we're not adding more subscriptions than the number of collars that
    // are in the cart.
    if (
      !newProduct ||
      (newProduct.type === types.ProductType.Subscription &&
        subscriptions.length === collars.length)
    ) {
      return;
    }

    dispatch(actions.addLineItem({ lineItem }));
    dispatch(updatePricing());
  },

  removeLineItem: (id: types.ProductID) => (
    dispatch: Dispatch,
    getState: () => types.IAppState
  ) => {
    const {
      config: { products },
      cart
    } = getState();
    const [subscriptions, collars] = splitIntoSubscriptionsAndCollars(
      products,
      cart.lineItems
    );
    const lineItem = Object.values(cart.lineItems).find(li => li.id === id);
    if (!lineItem) {
      return;
    }
    const removedProduct = getProductsBySku(products).get(lineItem.sku);
    // If we're removing a collar and there would be a subscription in the cart that's not associated with a collar,
    // remove a subscription as well.
    if (
      removedProduct &&
      removedProduct.subscriptionRequired &&
      subscriptions.length === collars.length
    ) {
      // Remove the last subscription
      const subscriptionItem = Object.values(cart.lineItems).find(
        li => li.id === subscriptions[subscriptions.length - 1].id
      );
      if (subscriptionItem) {
        dispatch(actions.removeLineItem({ id: subscriptionItem.id }));
      }
    }

    dispatch(actions.removeLineItem({ id }));
    dispatch(updatePricing());
  },

  addCoupon: (details: types.ICouponDetails) => (dispatch: Dispatch) => {
    dispatch(setGiftCard({ details: undefined })); // Coupon and gift card are mutually exclusive.
    dispatch(setReferral({ details: undefined })); // Cannot use coupon AND referral code

    dispatch(setCoupon({ details }));
    dispatch(updatePricing());
  },

  addGiftCard: (details: types.IGiftCardDetails) => (dispatch: Dispatch) => {
    dispatch(setCoupon({ details: undefined })); // Coupon and gift card are mutually exclusive.
    dispatch(setGiftCard({ details }));
    dispatch(updatePricing());
  },

  addReferral: (details: types.IReferralDetails) => (dispatch: Dispatch) => {
    dispatch(setCoupon({ details: undefined })); // Cannot use coupon AND referral code
    dispatch(setReferral({ details }));
    dispatch(updatePricing());
  },

  clearCoupon: () => (dispatch: Dispatch) => {
    dispatch(setCoupon({ details: undefined }));
    dispatch(updatePricing());
    removeCookie(couponCookieName);
  },

  clearGiftCard: () => (dispatch: Dispatch) => {
    dispatch(setGiftCard({ details: undefined }));
    dispatch(updatePricing());
  },

  clearReferral: () => (dispatch: Dispatch) => {
    dispatch(setReferral({ details: undefined }));
    dispatch(updatePricing());
  },

  resetCart: (payload: { resetCoupon?: boolean } = {}) => (
    dispatch: Dispatch
  ) => {
    dispatch(actions.resetCart(payload));
    dispatch(updatePricing());
  }
};

export const cartReducer = reducer;
export const cartInitialState = { [name]: initialState };
