import React, { useState } from "react";
import { applyGiftCardToCart } from "../../lib/pricing";
import * as events from "../../lib/analytics/events";
import { IAppState } from "../../types";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../reducers/cart";
import PromoCodeForm from "./PromoCodeForm";

/**
 * State for when a gift card code is being entered. Displays a CTA to switch
 * to entering a coupon code.
 */
export default function PromoCodeGiftCardEntry() {
  const cart = useSelector((state: IAppState) => state.cart);
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);

  const handleApply = async (code: string) => {
    const result = await applyGiftCardToCart(cart, code);
    if (result.kind === "failure") {
      setError(result.err.message);
      events.cartPage.giftCardError(code, result.err.message);
    } else {
      dispatch(cartActions.addGiftCard(result.value));
      events.cartPage.giftCardApplied(result.value.redemptionCode);
    }
  };

  return (
    <PromoCodeForm
      placeholder="Enter gift card code"
      error={error}
      clearError={() => setError(null)}
      onApply={handleApply}
      actionLabel={"Redeem"}
      epicButton
    />
  );
}
