import React, { MouseEventHandler } from "react";
import { Link, LinkProps } from "react-router-dom";
import { expectUnreachable } from "./util";
import { IVariantOptions } from "../types";

export enum ProductLinkLoc {
  Cart = "cart",
  Accessories = "accessories",
  Collar = "collar"
}

interface IProductLinkProps extends Omit<LinkProps, "to"> {
  productId: string;
  from?: ProductLinkLoc;
  variantOptions?: IVariantOptions;
}

export const ProductLink = ({
  productId,
  from,
  variantOptions,
  children,
  ...otherProps
}: IProductLinkProps) => {
  let search: string | undefined;
  if (variantOptions) {
    search = `?color=${variantOptions.color}&size=${variantOptions.size}`;
  }

  return (
    <Link
      to={{
        pathname: `/products/${productId}`,
        search,
        state: { from }
      }}
      {...otherProps}
    >
      {children}
    </Link>
  );
};

interface IProductBackButtonProps {
  from: ProductLinkLoc;
  onClick?: MouseEventHandler;
  className?: string;
}

export const ProductBackButton = ({
  from,
  onClick,
  className
}: IProductBackButtonProps) => {
  let to: string;
  let title: string;

  switch (from) {
    case ProductLinkLoc.Accessories:
      to = "/accessories";
      title = "Back to Shop";
      break;

    case ProductLinkLoc.Cart:
      to = "/bag";
      title = "Back to Bag";
      break;

    case ProductLinkLoc.Collar:
      to = "/products/smart-collar";
      title = "Back to Collar";
      break;

    default:
      expectUnreachable(from);
      return null;
  }

  return (
    <div className={className || "breadcrumbs"}>
      <Link to={to} onClick={onClick}>
        &lt; {title}
      </Link>
    </div>
  );
};
