import React, { useContext, useMemo, useEffect } from "react";
import GiftCardContext, {
  setSelectedCategory,
  setSelectedId
} from "../GiftCardContext";
import { GiftCardCategory, IAppState, ProductKind } from "../../../types";
import { useSelector } from "react-redux";
import { PackageButton } from "./Buttons";

export default function GiftCardPackage() {
  const { giftCardState, giftCardDispatch } = useContext(GiftCardContext);

  const products = useSelector((state: IAppState) => state.config.products);

  const availableCategories = useMemo(() => {
    const set = new Set(
      products.flatMap(product =>
        product.kind === ProductKind.GiftCard && product.giftCardCategory
          ? [product.giftCardCategory]
          : []
      )
    );
    set.add(GiftCardCategory.fullKit); // Always available
    return set;
  }, [products]);

  useEffect(() => {
    // If only one category is available, pre-select it.
    if (availableCategories.size === 1) {
      giftCardDispatch(setSelectedCategory(Array.from(availableCategories)[0]));
    }
  }, [availableCategories, giftCardDispatch]);

  return (
    <div className="split-container gift-card-page-section gift-card-package-picker">
      <div>
        <h2>1. Select a package</h2>
      </div>
      <div className="gift-product-list-container">
        {[GiftCardCategory.fullKit, GiftCardCategory.subscription]
          .filter(x => availableCategories.has(x))
          .map((category, i) => {
            const selected = giftCardState.selectedCategory === category;
            return (
              <PackageButton
                key={i}
                category={category}
                selected={selected}
                onClick={() => {
                  if (!selected) {
                    giftCardDispatch(setSelectedCategory(category));
                    giftCardDispatch(setSelectedId(null));
                  }
                }}
              />
            );
          })}
      </div>
    </div>
  );
}
