import React from "react";
import classNames from "classnames";
import DeliveryDateInput from "./DeliveryDateInput";
import { SimpleBoundInput, GifteeNameInput, GiftMessageInput } from "./Inputs";

/**
 * Configure details for the gift card.
 */
export default function GiftCardDetails({
  enabled: stageEnabled
}: {
  enabled: boolean;
}) {
  return (
    <div
      className={classNames("gift-card-page-section", {
        "disabled-section": !stageEnabled
      })}
    >
      <div className="split-container">
        <div>
          <h2>3. Schedule e-gift card</h2>
        </div>
        <div />
      </div>
      <div className="split-container gift-card-form-container">
        <div className="basic-form">
          <h3>To</h3>
          <GifteeNameInput disabled={!stageEnabled} />
          <SimpleBoundInput
            propertyName="emailAddress"
            placeholder="Email"
            inputType="email"
            disabled={!stageEnabled}
            nonNull
          />
          <h3>From</h3>
          <SimpleBoundInput
            propertyName="gifterName"
            placeholder="Name"
            disabled={!stageEnabled}
          />
        </div>
        <div>
          <GiftMessageInput disabled={!stageEnabled} />
          <DeliveryDateInput disabled={!stageEnabled} />
        </div>
      </div>
    </div>
  );
}
