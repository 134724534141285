import * as React from "react";

const NotFound = () => (
  <div className="single-column-container">
    <h1>Not Found</h1>
    <p>
      We weren't able to find the page you're looking for. Please reach out to{" "}
      <a href="mailto:support@tryfi.com">support@tryfi.com</a> for assistance.
    </p>
  </div>
);
export default NotFound;
