import * as qs from "query-string";
import React, { useState, useCallback, useContext } from "react";

import * as events from "../../../../lib/analytics/events";
import { logout } from "../../../../lib/fi-api/authentication";
import { IAddress } from "../../../../types";
import * as gqlTypes from "../../../../types/gql-op-types";
import ErrorComponent from "../../../../components/ErrorComponent";
import Loading from "../../../../components/Loading";

import ShippingAddressForm from "./ShippingAddressForm";
import CheckoutPaths from "../../../../lib/CheckoutPaths";
import { generateID } from "../../../../lib/util";
import LoginButton from "./LoginButton";
import CheckoutContext, {
  setCustomerName,
  setShippingAddress
} from "../../CheckoutContext";
import { MutationFn } from "react-apollo-hooks";

export interface IShippingAddressProps {
  loggedIn: boolean;
  loggedInUserName?: string;
  mutation: MutationFn<
    gqlTypes.updateShippingAddress,
    gqlTypes.updateShippingAddressVariables
  >;
  loading: boolean;
  error?: string;
  address: IAddress | null;
  errorID?: string;
  nonPhysicalCheckout: boolean;
}

interface ILogoutError {
  error: string;
  errorID: string;
}

export default function ShippingAddressSection({
  loading,
  error,
  errorID,
  loggedIn,
  loggedInUserName,
  mutation,
  nonPhysicalCheckout,
  address
}: IShippingAddressProps) {
  const { checkoutState, checkoutDispatch } = useContext(CheckoutContext);

  const handleShippingAddressOnComplete = (
    name: string | undefined,
    address: IAddress | undefined
  ): void => {
    events.shipping.continue(checkoutState.orderCart);
    checkoutDispatch(setCustomerName(name));
    checkoutDispatch(setShippingAddress(address));
  };

  const [logoutError, setLogoutError] = useState<ILogoutError | undefined>(
    undefined
  );

  const handleLogout = useCallback(
    async (event: any) => {
      event.preventDefault();

      try {
        await logout();
      } catch (error) {
        setLogoutError({
          error: error.message,
          errorID: generateID()
        });
      }
    },
    [setLogoutError]
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorComponent
        error={error}
        errorID={errorID}
        trackError={message => events.shipping.continueError(message)}
      />
    );
  }

  const loginPath = `/login?${qs.stringify({
    returnTo: CheckoutPaths.Checkout
  })}`;

  return (
    <div>
      <ErrorComponent
        error={logoutError?.error}
        errorID={logoutError?.errorID}
        trackError={message => events.shipping.continueError(message)}
      />
      <div className="row">
        <LoginButton
          onLogout={handleLogout}
          loginPath={loginPath}
          loggedInUserName={loggedInUserName}
          loggedIn={loggedIn}
        />
      </div>
      <ShippingAddressForm
        loggedIn={loggedIn}
        loggedInUserName={loggedInUserName}
        mutation={mutation}
        address={address}
        onComplete={handleShippingAddressOnComplete}
        nonPhysicalCheckout={nonPhysicalCheckout}
      />
    </div>
  );
}
