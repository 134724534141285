import { useMemo } from "react";
import { useSelector } from "react-redux";

import * as types from "../types";

import { getProductsAndVariantsBySku } from "../lib/util";

export default function useProductSKUMap(): Map<
  string,
  { product: types.IProduct; variant?: types.IVariant }
> {
  const products = useSelector(
    (state: types.IAppState) => state.config.products
  );
  return useMemo(() => getProductsAndVariantsBySku(products), [products]);
}
