import React from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import { Location } from "history";

import ICheckoutState from "../models/CheckoutState";

import * as types from "../types";
import ThankYou from "../views/ThankYou";

interface IThankYouContainerProps {
  session: types.ISession | undefined;
  location?: Location<ICheckoutState>;
}

function ThankYouContainer({ session, location }: IThankYouContainerProps) {
  const state = location?.state;

  if (!state) {
    return <Redirect to="/" />;
  }

  const cart = state.orderCart;

  if (Object.keys(cart.lineItems).length < 1) {
    return <Redirect to="/" />;
  }

  return (
    <ThankYou
      cart={cart}
      orderId={state.orderID!}
      noPasswordInSession={!!session?.noPassword}
    />
  );
}

const mapStateToProps = (state: types.IAppState): IThankYouContainerProps => {
  return {
    session: state.session ?? undefined
  };
};

export default connect(mapStateToProps)(ThankYouContainer);
